import React, { useState, useEffect, useRef, useCallback, useContext, useMemo } from 'react';
import SimpleKeyboard from "react-simple-keyboard";
import DragBox from '../DragBox';
import { LanguageContext, KeyboardContext } from '../../contexts';
import "react-simple-keyboard/build/css/index.css";
import styles from './Keyboard.module.scss';

const Keyboard = ({className = '', ...props}) => {
    const [ startPosition, setStartPosition ] = useState();
    const [ layoutName, setLayoutName ] = useState('he_only');
    const { language } = useContext(LanguageContext);
    const { input, show, setShow } = useContext(KeyboardContext);
    const keyboardRef = useRef();

    useEffect(() => {
        if (!show) {
            setStartPosition(null);
            return () => {};
        } 

        let isActive = true;
        const keyboard_input = (input && document.body.contains(input)) ? input : document.querySelector('.keyboard_input');

        if (keyboard_input) {
            keyboard_input.scrollIntoView({ behavior: "auto", block: "end", inline: "nearest" });
            keyboard_input.focus();

            setTimeout(() => {
                if (isActive) {
                    const inputRect = keyboard_input.getBoundingClientRect();
                    if (!startPosition) {
                        setStartPosition({
                            x: inputRect.left + (inputRect.width / 2) - 300,
                            y: inputRect.top + inputRect.height
                        });
                    }
                }
            }, 100);
            
        }
        else if (!startPosition) {
            setStartPosition({
                x: 0,
                y: 0
            });
        }

        return () => {
            isActive = false;
        };
    }, [input, show, startPosition]);

    useEffect(() => {
        if (input) {
            keyboardRef.current?.setInput(input.value);
            input.oninput = (event) => {
                keyboardRef.current?.setInput(event.target.value);
            };
        }

        return () => {
            if (input) {
                input.oninput = undefined;
            }
        };
    }, [input, keyboardRef]);

    const onCloseHandle = useCallback(() => {
        setShow(false);
    }, [setShow]);

    const setKeyboardRef = useCallback((ref) => {
        if (input) {
            keyboardRef.current = ref;
            keyboardRef.current?.setInput(input.value);
        }
    }, [input]);

    const onChangeHandle = useCallback((value) => {
        if (input) {
            input.value = value;
        }
    }, [input]);

    const onKeyPressHandle = useCallback((button) => {
        switch (button) {
            case '{shift_he_only}':
                setLayoutName('shift_he_only');
                break;
            case '{shift_shift_he_only}':
                setLayoutName('he_only');
                break;
            case '{shift_en}':
            case '{set_shift_en}':
                setLayoutName('shift_en');
                break;
            case '{shift_he}':
            case '{set_shift_he}':
                setLayoutName('shift_he');
                break;
            case '{shift_shift_en}':
            case '{set_en}':
                setLayoutName('en');
                break;
            case '{shift_shift_he}':
            case '{set_he}':
                setLayoutName('he');
                break;
            default:
        };

        if (input && input.onKeyboardPress) {
            input.onKeyboardPress(button, input);
        }
    }, [input]);

    const keyboardLayouts = useMemo(() => {
        return {
            layout: {
                he_only: [
                    " 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                    "/ ' \u05e7 \u05e8 \u05d0 \u05d8 \u05d5 \u05df \u05dd \u05e4 ] [ :",
                    "{tab} \u05e9 \u05d3 \u05d2 \u05db \u05e2 \u05d9 \u05d7 \u05dc \u05da \u05e3 , {enter}",
                    "{shift_he_only} \u05d6 \u05e1 \u05d1 \u05d4 \u05e0 \u05de \u05e6 \u05ea \u05e5 . {shift_he_only}",
                    ".com {space} @",
                ],
                shift_he_only: [
                    "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                    "          { } |",
                    '{tab}          : " {enter}',
                    "{shift_shift_he_only}        < > ? {shift_shift_he_only}",
                    ".com {space} @",
                ],
                en: [
                    "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                    "@ q w e r t y u i o p [ ] \\",
                    "{tab} a s d f g h j k l ; ' {enter}",
                    "{shift_en} z x c v b n m , . / {shift_en}",
                    ".com {space} {set_he}",
                ],
                he: [
                    " 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                    "@ / ' \u05e7 \u05e8 \u05d0 \u05d8 \u05d5 \u05df \u05dd \u05e4 ] [ :",
                    "{tab} \u05e9 \u05d3 \u05d2 \u05db \u05e2 \u05d9 \u05d7 \u05dc \u05da \u05e3 , {enter}",
                    "{shift_he} \u05d6 \u05e1 \u05d1 \u05d4 \u05e0 \u05de \u05e6 \u05ea \u05e5 . {shift_he}",
                    ".com {space} {set_en}",
                ],
                shift_en: [
                    "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                    "@ Q W E R T Y U I O P { } |",
                    '{tab} A S D F G H J K L : " {enter}',
                    "{shift_shift_en} Z X C V B N M < > ? {shift_shift_en}",
                    ".com {space} {set_shift_he}",
                ],
                shift_he: [
                    "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                    "@ Q W E R T Y U I O P { } |",
                    '{tab} A S D F G H J K L : " {enter}',
                    "{shift_shift_he} Z X C V B N M < > ? {shift_shift_he}",
                    ".com {space} {set_shift_en}",
                ],
            },
            mergeDisplay: true,
            display: {
                '{set_en}': 'EN',
                '{set_he}': 'HE',
                '{set_shift_en}': 'EN',
                '{set_shift_he}': 'HE',
                '{shift_en}': 'shift',
                '{shift_he}': 'shift',
                '{shift_shift_en}': 'shift',
                '{shift_shift_he}': 'shift',
                '{shift_he_only}': 'shift',
                '{shift_shift_he_only}': 'shift',
            },
            buttonTheme: [
                {
                    class: styles.language_btn,
                    buttons: "{set_en} {set_he} {set_shift_en} {set_shift_he}"
                },
                {
                    class: styles.pressed_btn,
                    buttons: "{shift_shift_en} {shift_shift_he} {shift_shift_he_only}"
                }
            ]
        };
    }, []);

    return show && <DragBox 
        className={`${styles.container} ${className}`} 
        startPosition={startPosition} 
        onClose={onCloseHandle}
        {...props}
    >
        <SimpleKeyboard 
            keyboardRef={setKeyboardRef} 
            onKeyPress={onKeyPressHandle}
            {...keyboardLayouts}
            layoutName={layoutName || language}
            onChange={onChangeHandle}
        />
    </DragBox>;
};
  
export default Keyboard;