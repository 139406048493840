import React, { useContext, useEffect, useCallback, useRef, useMemo } from 'react';
import Loader from '../../components/Loader';
import WordExplain from '../../components/WordExplain';
import SearchInput from '../../components/SearchInput';
import Slider from '../../components/Slider';
import Href from '../../components/Href';
import Button, { buttonType } from '../../components/Button';
import OuterLayer from '../../containers/OuterLayer';
import SearchResults from './searchResults';
import FullAnalyzeData from './fullAnalyzeData';
import { useLabels } from '../../hooks';
import { useAnalyzeText, useFullAnalyze } from '../../api';
import { PageContext, OutersContainerContext, DeviceContext, UserContext } from '../../contexts';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './Search.module.scss';

export const pageKey = "search";

const Search = () => {
  const searchInputRef = useRef();
  const { searchStr, wordEntry, wordId } = useParams();
  const { loading: searchLoading, error: searchError, data: searchData } = useAnalyzeText(searchStr);
  const { loading: fullAnalyzeLoading, error: fullAnalyzeError, data: fullAnalyzeData } = useFullAnalyze(searchStr, wordEntry, wordId);
  const navigate = useNavigate();
  const { hasPermissions } = useContext(UserContext);
  const { setPageKey } = useContext(PageContext);
  const { isMobileView, isMobile } = useContext(DeviceContext);
  const { 
    appLayoutTitleContainer,
    appLayoutSubTitleContainer,
    appLayoutHeaderSectionContainer,
  } = useContext(OutersContainerContext);
  const { getLabel, getLocalUrlByLanguage } = useLabels();

  useEffect(() => {
    if(!hasPermissions) {
      navigate(getLocalUrlByLanguage(`/`));
    }
  }, [hasPermissions, getLocalUrlByLanguage, navigate]);

  useEffect(() => {
    setPageKey(pageKey);
  }, [setPageKey]);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.setValue(searchStr || '');
    }
  }, [searchStr]);

  const onSearchHandle = useCallback((value) => {
    if (value) {
      navigate(getLocalUrlByLanguage(`/search/${encodeURIComponent(value)}`));
    }
  }, [navigate, getLocalUrlByLanguage]);

  const onSearchButtonClickHandle = useCallback(() => {
    if (searchInputRef.current) {
      onSearchHandle(searchInputRef.current.getValue());
    }
  }, [onSearchHandle]);

  const setSearchInputRef = useCallback((ref) => {
    searchInputRef.current = ref;
    if (searchInputRef.current) {
      searchInputRef.current.setValue(searchStr || '');
    }
  }, [searchStr]);

  const sliderItemBuilder = useCallback((item) => {
    if(!item) {
      return;
    }

    return <Href 
      className={styles.search_results_word} 
      to={getLocalUrlByLanguage(`/search/${encodeURIComponent(searchStr)}/${encodeURIComponent(item.inputWordEntry)}/${item.id}`)}
    >
      <div className={styles.search_results_word_title}>
          <span>{item.inputWordEntry}</span> 
          <span className={styles.search_results_word_partOfSpeech}>{item.partOfSpeech}</span>
          {item.inputWordDiacritics && item.inputWordDiacritics !== item.inputWordEntry ? <span className={styles.search_results_word_diacritics}>{`(${item.inputWordDiacritics})`}</span> : ''}
      </div>
      <div className={styles.search_results_word_explain}><WordExplain explain={item.explain} linksEnable={false} /></div>
    </Href>;
  }, [getLocalUrlByLanguage, searchStr]);

  const selectedWord = useMemo(() => {
    let word = null, otherWords = [];
    if (wordId && searchData && searchData.words) {
      for (let i = 0; i < searchData.words.length; i++) {
        if (searchData.words[i].id + '' === wordId + '') {
          word = searchData.words[i];
        }
        else if (searchData.words[i].id) {
          otherWords.push(searchData.words[i]);
        }
      }
    }
    return {word, otherWords};
  }, [wordId, searchData]);

  const searchInput = useMemo(() => {
    return <SearchInput onSearch={onSearchHandle} placeholder={getLabel("word_search_input_placeholder")} ref={setSearchInputRef} autoFocus={!searchStr && !isMobile} />;
  }, [getLabel, onSearchHandle, searchStr, setSearchInputRef, isMobile]);

  const loader = useMemo(() => {
    return (searchLoading || fullAnalyzeLoading) && <Loader />;
  }, [searchLoading, fullAnalyzeLoading]);

  if (searchStr && wordEntry && wordId) {
    return <>
      {isMobileView && <OuterLayer container={appLayoutHeaderSectionContainer} TagWrapper='div' className={styles.header_search_input}>{searchInput}</OuterLayer>}
      <div className={`${styles.container} ${styles.container_full_analyze}`}>
        <div className={styles.full_analyze_header}>
          <div className={styles.search_results_word} >
            {selectedWord.word && <>
              <div className={styles.search_results_word_title}>
                <span>{selectedWord.word.inputWordEntry}</span> 
                <span className={styles.search_results_word_partOfSpeech}>{selectedWord.word.partOfSpeech}</span>
                {selectedWord.word.inputWordDiacritics && selectedWord.word.inputWordDiacritics !== selectedWord.word.inputWordEntry ? <span className={styles.search_results_word_diacritics}>{`(${selectedWord.word.inputWordDiacritics})`}</span> : ''}
              </div>
              <div className={styles.search_results_word_explain}><WordExplain explain={selectedWord.word.explain} /></div>
              {isMobileView && <div className={styles.search_results_word_translation}>{selectedWord.word.translation}</div>}
            </>}
          </div>
          {!isMobileView && <div className={styles.full_analyze_search_input} data-search_input_theme="full_analyze">{searchInput}</div>}
        </div>
        <FullAnalyzeData data={fullAnalyzeData} searchStr={searchStr} />
        {selectedWord.otherWords.length > 0 && <div className={styles.full_analyze_other_words}>
          <div className={styles.full_analyze_other_words_title}>{getLabel('full_analyze_other_words_title')}</div>
          <Slider items={selectedWord.otherWords} itemBuilder={sliderItemBuilder} itemsInView={isMobileView ? 2 : 3} offset={isMobileView ? -0.5 : 0} />
        </div>}
      </div>
      {loader}
    </>
  }

  if (searchStr) {
    return <>
      {isMobileView && <OuterLayer container={appLayoutHeaderSectionContainer} TagWrapper='div' className={styles.header_search_input}>{searchInput}</OuterLayer>}
      {!isMobileView && <OuterLayer container={appLayoutTitleContainer} TagWrapper='span'>{getLabel("page_search_title")}</OuterLayer>}
      {!isMobileView && <OuterLayer container={appLayoutSubTitleContainer} dangerouslySetInnerHTML={{ __html: getLabel("page_search_sub_title")}} />}
      <div className={`${styles.container} ${styles.container_search_results} ${searchData && searchData.resultType ? styles['container_search_results_' + searchData.resultType] : ''}`} >
        {!isMobileView && <div className={styles.sticky_search_input}>{searchInput}</div>}
        {searchData && <SearchResults {...searchData} />}
      </div>
      {loader}
    </>;
  }

  return <>
    <OuterLayer container={appLayoutTitleContainer} TagWrapper='span'>{getLabel("page_search_title")}</OuterLayer>
    <OuterLayer container={appLayoutSubTitleContainer} dangerouslySetInnerHTML={{ __html: getLabel("page_search_sub_title")}} />
    <div className={styles.container} >
      {searchInput}
      <Button className={styles.search_but} type={buttonType.premium} onClick={onSearchButtonClickHandle} >{getLabel("search")}</Button>
    </div>
    {loader}
  </>;
};

export default Search;
