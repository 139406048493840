import React, { forwardRef, useImperativeHandle, useCallback, useState, useRef, useEffect } from 'react';
import KeyboardInput from '../KeyboardInput';
import { useLabels } from '../../hooks';
import { formatString } from '../../utils/helpers';
import styles from './FormInputField.module.scss';

const FormInputField = forwardRef(({
    label, 
    className = '', 
    onChange = () => {},
    onBlur = () => {},
    value,
    error,
    required = false,
    minLength = 0,
    passwordValidation = false,
    emailValidation = false,
    telValidation = false,
    validationPattern = () => {},
    type = 'text',
    ...props
}, ref) => {
    const [ valueState, setValueState ] = useState('');
    const [ errorState, setErrorState ] = useState('');
    const inputRef = useRef();
    const isValidationRunRef = useRef(false);
    const { getLabel } = useLabels();

    useEffect(() => {
        if (value || value === '') {
            setValueState(value);
        }
    }, [value]);

    useEffect(() => {
        if (error) {
            setErrorState(error);
        }
    }, [error]);

    const validation = useCallback((value) => {
        isValidationRunRef.current = true;
        
        if (error) {
            setErrorState(error);
            return false;
        }

        const data = value || value === '' ? value : valueState;

        if (required && !data) {
            setErrorState(getLabel('form_input_field_required_error'));
            return false;
        }

        if (minLength && minLength > 0 && minLength > data.length) {
            setErrorState(formatString(getLabel('form_input_field_minlength_error'), minLength));
            return false;
        }

        if (emailValidation && data) {
            if(!(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z]{2,4}$/).test(data)) {
                setErrorState(getLabel('form_input_field_email_error'));
                return false;
            }
        }

        if (telValidation && data) {
            if(!(/^(\+[1-9])?[0-9]{0,24}$/).test(data)) {
                setErrorState(getLabel('form_input_field_tel_error'));
                return false;
            }
        }

        if (passwordValidation) {
            if (data.length < 8) {
                setErrorState(formatString(getLabel('form_input_field_password_length_error'), 8));
                return false;
            }
            if ((/[א-ת]/).test(data)) {
                setErrorState(getLabel('form_input_field_password_he_error'));
                return false;
            }
            if (!(/[a-z]/).test(data)) {
                setErrorState(getLabel('form_input_field_password_lowercase_error'));
                return false;
            }
            if (!(/[A-Z]/).test(data)) {
                setErrorState(getLabel('form_input_field_password_uppercase_error'));
                return false;
            }
            if (!(/[0-9]/).test(data)) {
                setErrorState(getLabel('form_input_field_password_numeric_error'));
                return false;
            }
            if (!(/[-_^$*.{}()?"!@#%&/[\],><':;|~`+=\\ ]/).test(data)) {
                setErrorState(getLabel('form_input_field_password_symbol_error'));
                return false;
            }
            if ((/^\s|\s$/).test(data)) {
                setErrorState(getLabel('form_input_field_password_space_error'));
                return false;
            }
        }

        const validationFromPattern = validationPattern(data);
        if (validationFromPattern) {
            setErrorState(validationFromPattern);
            return false;
        }

        setErrorState('');
        return true;
    }, [error, valueState, required, minLength, emailValidation, telValidation, passwordValidation, validationPattern, getLabel]);

    useEffect(() => {
        if (errorState) {
            validation();
        }
    }, [errorState, validation])

    useImperativeHandle(ref, () => {
        return {
            ...(inputRef.current || {}),
            validation: validation
        };
    }, [validation]);

    const onChangeHandle = useCallback((event) => {
        const value = event.target.value;
        setValueState(value);
        onChange(event);
    }, [onChange]);

    const onBlurHandle = useCallback((event) => {
        const value = event.target.value;
        validation(value);
        setValueState(value);
        onBlur(event);
    }, [validation, onBlur]);

    return <div className={`${styles.fild} ${className} ${errorState ? styles.fild_error : ''}`}>
        {label && <div className={styles.label}>{label}</div>}
        <div className={styles.error}>{errorState}</div>
        <div className={styles.input}>
            <KeyboardInput 
                {...props} 
                ref={inputRef} 
                onChange={onChangeHandle} 
                onBlur={onBlurHandle}
                type={type}
                value={valueState} 
                required={required}
                minLength={minLength}
            />
        </div>
    </div>;
});

export default FormInputField;