import React, { useMemo } from 'react';
import { useDataPerDevice } from '../../../hooks';
import styles from './Html.module.scss';

const Html = ({
  html, html_mobile, html_pc, 
}) => {
  const { getDataPerDeviceView } = useDataPerDevice();

  const innerHtml = useMemo(() => {
    return getDataPerDeviceView(html_mobile, html_pc, html) || '';
  }, [getDataPerDeviceView, html_mobile, html_pc, html]);

  return <div className={styles.container} dangerouslySetInnerHTML={{ __html: innerHtml}} />;
};

export default Html;
