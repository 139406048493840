import { useEffect, useCallback } from 'react';
import { BrowserRouter, useLocation  } from 'react-router-dom';
import LanguageRouter from '../../routes/LanguageRouter';
import { ProvidersWrapper } from '../../contexts';
import { scrollTop, getRootDom } from '../../utils/helpers';
import styles from './App.module.scss';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    scrollTop();
  }, [pathname]);

  return;
};

function App() {
  const onScrollHandler = useCallback(({target}) => {
    getRootDom().style.setProperty('--scroll-top', target.scrollTop);
  }, []);

  return (
    <BrowserRouter>
      <ProvidersWrapper>
        <div className={styles.app} id="app" onScroll={onScrollHandler}>
          <ScrollToTop />
          <LanguageRouter />
        </div>
      </ProvidersWrapper>
    </BrowserRouter>
  );
}

export default App;
