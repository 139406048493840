import React from 'react';
import Icon from '../Icon';
import styles from './Selector.module.scss';

const Selector = ({className = '', selectClassName = '', options = [], ...props}) => {
    return <label className={`${styles.container} ${className}`}>
        <div className={styles.arrow}><Icon className={styles.arrow_icon} icon="arrow" /></div>
        <select className={`${styles.selector} ${selectClassName}`} {...props}>
            {options.map(option => <option key={`selector_option-${option.key}`} value={option.key}>{option.text}</option>)}
        </select>
    </label>;
};
  
export default Selector;