import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Icon from '../../components/Icon';
import Button, { buttonType } from '../../components/Button';
import Sidebar from '../../components/Sidebar';
import LoginForm from '../../components/LoginForm';
import OuterLayer from '../../containers/OuterLayer';
import { useLabels } from '../../hooks';
import { PageContext, DeviceContext, OutersContainerContext, UserContext, UserType } from '../../contexts';
import styles from './Home.module.scss';
import { env } from '../../config';

const pageKey = "home";

const Home = () => {
  const { getLabel, getLocalUrlByLanguage, getDataByDirection } = useLabels();
  const { setPageKey } = useContext(PageContext);
  const { isMobileView } = useContext(DeviceContext);
  const { appLayoutHeaderSectionContainer } = useContext(OutersContainerContext);
  const { hasPermissions, userType } = useContext(UserContext);
  const { pathname: locationPathname } = useLocation();
  const navigate = useNavigate();

  const showLogin = useMemo(() => {
    return locationPathname === getLocalUrlByLanguage('/login');
  }, [locationPathname, getLocalUrlByLanguage]);

  useEffect(() => {
    if(showLogin && (hasPermissions || userType !== UserType.guest)) {
      navigate(getLocalUrlByLanguage(`/`));
    }
  }, [hasPermissions, getLocalUrlByLanguage, navigate, userType, showLogin]);

  useEffect(() => {
    setPageKey(showLogin ? 'login' : pageKey);
  }, [setPageKey, showLogin]);

  const onSignUpButtonClickHandle = useCallback(() => {
    if (userType === UserType.guest && env.REACT_APP_ORG_ENABLE()) {
      window.open(`${getLabel('main_site')}purchase`, "_blank");
    }
    else {
      navigate(getLocalUrlByLanguage(`/purchase`));
    }
  }, [getLabel, getLocalUrlByLanguage, navigate, userType]);

  const onLogInButtonClickHandle = useCallback(() => {
    navigate(getLocalUrlByLanguage(`/login`));
  }, [getLocalUrlByLanguage, navigate]);

  const onLiginCloseHandle = useCallback(() => {
    navigate(getLocalUrlByLanguage(`/`));
  }, [getLocalUrlByLanguage, navigate]);

  const topics = useMemo(() => {
    const groups = isMobileView ? [[1, 2, 3, 4], [5, 6, 7, 8]] : [[1, 2, 3, 4, 5, 6, 7, 8]];

    return <div>
      {
        groups.map((group, group_index) => <div className={styles.topics} key={`home_page_topics-${group_index}`}>{
          group.map((index) => {
            const key = `home_page_topic_${index}`;
            return <span key={key}>{getLabel(key)}</span>
          })
        }</div>)
      }
    </div>;
  }, [isMobileView, getLabel]);

  const loginForm = useMemo(() => {
    return <LoginForm />;
  }, []);

  const signUpButton = useMemo(() => {
    return <Button type={buttonType.premium} className={styles.sign_up_button} onClick={onSignUpButtonClickHandle} >{getLabel(userType === UserType.guest ? 'sign_up_button' : 'home_page_subscription_button')}</Button>;
  }, [getLabel, onSignUpButtonClickHandle, userType]);

  const logInButton = useMemo(() => {
    return userType === UserType.guest && <Button type={buttonType.link} className={styles.log_in_button} onClick={onLogInButtonClickHandle} >{getLabel('log_in_button')}</Button>;
  }, [getLabel, onLogInButtonClickHandle, userType]);

  const layout = useMemo(() => {
    if (isMobileView && showLogin) {
      return <div className={`${styles.container} ${styles.container_login}`}>{loginForm}</div>;
    }
  
    if (isMobileView) {
      return <>
        <OuterLayer container={appLayoutHeaderSectionContainer} TagWrapper='div' className={styles.buttons}>
          {signUpButton}
          {logInButton}
        </OuterLayer>
        <div className={styles.container}>
          <div className={styles.scroll_anchor}></div>
          {topics}
          <div className={`${styles.block} ${styles.block_about}`}>
            <div className={styles.about_content}>
              <div className={styles.block_title}>{getLabel('home_page_about_title')}</div>
              <div className={styles.about_text}>
                <div dangerouslySetInnerHTML={{ __html: getLabel('home_page_about_text_1')}} />
                <div className={styles.about_image}></div>
                <br />
                <div dangerouslySetInnerHTML={{ __html: getLabel('home_page_about_text_2')}} />
              </div>
            </div>
          </div>
          <div className={`${styles.block} ${styles.block_customers}`}>
            <div className={styles.block_title}>{getLabel('home_page_customers_title')}</div>
            <div className={styles.customers}><div></div><div></div><div></div></div>
          </div>
        </div>
      </>;
    }
  
    return <>
      <div className={styles.container}>
        <div className={`${styles.block} ${styles.block_log_in}`}>
          <div>
            <div>
              <Icon className={`${styles.logo} ${styles[`logo_${getLabel('lang')}`]}`} icon={`logo_large_${getLabel('lang')}`} />
              <div className={styles.opening_text}>{getLabel('home_page_opening_text')}</div>
              <div className={styles.buttons}>
                {signUpButton}
                {logInButton}
              </div>
            </div>
          </div>
          {topics}
        </div>
        <div className={`${styles.block} ${styles.block_about}`}>
          <div className={styles.about_content}>
            <div className={styles.block_title}>{getLabel('home_page_about_title')}</div>
            <div className={styles.about_text}>
              <div dangerouslySetInnerHTML={{ __html: getLabel('home_page_about_text_1')}} />
              <br />
              <div dangerouslySetInnerHTML={{ __html: getLabel('home_page_about_text_2')}} />
            </div>
          </div>
          <div className={styles.about_image}></div>
        </div>
        <div className={`${styles.block} ${styles.block_customers}`}>
          <div className={styles.block_title}>{getLabel('home_page_customers_title')}</div>
          <div className={styles.customers}><div></div><div></div><div></div></div>
        </div>
      </div>
      <Sidebar isOpen={showLogin} onClose={onLiginCloseHandle} side={getDataByDirection('left', 'right')}>{loginForm}</Sidebar>
    </>;
  }, [appLayoutHeaderSectionContainer, getDataByDirection, getLabel, isMobileView, loginForm, onLiginCloseHandle, onLogInButtonClickHandle, onSignUpButtonClickHandle, showLogin, topics, userType]);

  return layout;
};

export default Home;
