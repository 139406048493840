import React, { useCallback, useContext } from 'react';
import Icon from '../Icon';
import Keyboard from '../Keyboard';
import { KeyboardContext } from '../../contexts';
import { useLabels } from '../../hooks';
import styles from './KeyboardToggle.module.scss';

const KeyboardToggle = ({ className = '' }) => {
    const { show, setShow, inputCount } = useContext(KeyboardContext);
    const { getLabel } = useLabels();

    const toggleKeyboard = useCallback(() => {
        setShow(!show);
    }, [show, setShow]);

    return (
        <>
            <button disabled={inputCount <= 0} className={`${styles.keyboard_toggle} ${className}`} onClick={toggleKeyboard}>
                <Icon className={styles.icon} icon="keyboard" title={getLabel('keyboard_icon_title')}/>
            </button>
            <Keyboard />
        </>
    )
};

export default KeyboardToggle;