import { useCallback, useContext } from 'react';
import { DeviceContext, PageContext, UserContext } from '../contexts';
import ItemLink from '../components/NavigationItem/ItemLink';
import ItemAlert from '../components/NavigationItem/ItemAlert';
import ItemDropdown from '../components/NavigationItem/ItemDropdown';
import { env } from '../config';

export const navigationItemType = {
    link: 'link',
    alert: 'alert',
    dropdown: 'dropdown',
};

export const navigationItemComponent = {
    link: ItemLink,
    alert: ItemAlert,
    dropdown: ItemDropdown,
};

export const showForUserType = {
    guest: 'GUEST',
    privet: 'PRIVET',
    edu: 'EDU',
    org: 'ORG',
    external: 'EXTERNAL',
    has_permissions: 'HAS_PERMISSIONS',
    has_not_permissions: 'HAS_NOT_PERMISSIONS',
    guest_has_permissions: 'GUEST_HAS_PERMISSIONS',
    guest_has_not_permissions: 'GUEST_HAS_NOT_PERMISSIONS',
    privet_has_permissions: 'PRIVET_HAS_PERMISSIONS',
    privet_has_not_permissions: 'PRIVET_HAS_NOT_PERMISSIONS',
    edu_has_permissions: 'EDU_HAS_PERMISSIONS',
    edu_has_not_permissions: 'EDU_HAS_NOT_PERMISSIONS',
    org_has_permissions: 'ORG_HAS_PERMISSIONS',
    org_has_not_permissions: 'ORG_HAS_NOT_PERMISSIONS',
    external_has_permissions: 'EXTERNAL_HAS_PERMISSIONS',
    external_has_not_permissions: 'EXTERNAL_HAS_NOT_PERMISSIONS',
};

export const showForSiteType = {
    main: 'MAIN',
    org: 'ORG',
};

export const showForDeviceType = {
    pc: 'pc',
    mobile: 'mobile',
};

export const useFilterNavigationItem = () => {
    const { isMobileView } = useContext(DeviceContext);
    const { userType, hasPermissions } = useContext(UserContext);
    const { pageKey} = useContext(PageContext);


    const filterNavigationItem = useCallback((items = []) => {

        const filter = ({show_for_pages, show_for_users, show_for_devises, show_for_sites}) => {
            const siteType = env.REACT_APP_ORG_ENABLE() ? showForSiteType.org : showForSiteType.main;
            if (show_for_sites && show_for_sites.indexOf(siteType) < 0) {
                return false;
            }

            if (show_for_pages && show_for_pages.indexOf(pageKey) < 0) {
                return false;
            }
    
            const permissionsKey = hasPermissions ? showForUserType.has_permissions : showForUserType.has_not_permissions;
            if(
                show_for_users && 
                show_for_users.indexOf(userType) < 0 && 
                show_for_users.indexOf(permissionsKey) < 0 && 
                show_for_users.indexOf(`${userType}_${permissionsKey}`) < 0
            ) {
                return false;
            }
    
            const deviceType = isMobileView ? showForDeviceType.mobile : showForDeviceType.pc;
            if (show_for_devises && show_for_devises.indexOf(deviceType) < 0) {
                return false;
            }
    
            return true;
        };

        return items.filter(item => filter(item));
        
    }, [isMobileView, userType, hasPermissions, pageKey]);

    return {
        filterNavigationItem
    }
};