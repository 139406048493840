import { createContext, useState } from 'react';

export const OutersContainerContext = createContext();

export const OutersContainerProvider = (props) => {
    const [outerLayersContainer, setOuterLayersContainer] = useState();
    const [appLayoutTitleContainer, setAppLayoutTitleContainer] = useState();
    const [appLayoutSubTitleContainer, setAppLayoutSubTitleContainer] = useState();
    const [appLayoutHeaderSectionContainer, setAppLayoutHeaderSectionContainer] = useState();
    const [appLayoutStickySectionContainer, setAppLayoutStickySectionContainer] = useState();

    return <OutersContainerContext.Provider value={{
        outerLayersContainer, 
        appLayoutTitleContainer, 
        appLayoutSubTitleContainer,
        appLayoutHeaderSectionContainer,
        appLayoutStickySectionContainer,
        setAppLayoutTitleContainer,
        setAppLayoutSubTitleContainer,
        setAppLayoutHeaderSectionContainer,
        setAppLayoutStickySectionContainer,
    }}>
        {props.children}
        <div ref={setOuterLayersContainer}></div>
    </OutersContainerContext.Provider>;
};