import { createContext, useState, useEffect } from 'react';
import { env } from '../config';

export const ProxyContext = createContext();

export const ProxyProvider = (props) => {
    const [ isReady, setIsReady ] = useState(false);
    const [ proxyData, setProxyData ] = useState(null);

    useEffect(() => {
        const url = `${env.REACT_APP_BASS_RAVMILIM_API_URL()}/Proxy/GetProxyData`;
        const apiDomain = env.REACT_APP_BASS_RAVMILIM_API_URL().split('//')[1].split('/')[0];
        
        // fix university tel aviv OpenAthens proxy
        if (url === '//backend-ravmilim-com.eu1.proxy.openathens.net/api/Proxy/GetProxyData') {
            setIsReady(false);
            setProxyData({
                apiDomain: 'backend-ravmilim-com.eu1.proxy.openathens.net'
            });
        }
        else if (['backend', 'ravmilim', 'com'].join('.') !== apiDomain && 'test-backend.ravmilim.com' !== apiDomain && !apiDomain.startsWith('localhost')) {
            setIsReady(false);
            setProxyData({
                apiDomain: apiDomain
            });
        }
        else {
            window.fetch(url).then(data => {
                return data.json();
            }).then(data => {
                setIsReady(!data.success);
                setProxyData(data && data.success ? data.proxyData : null);
            }).catch(error => {
                setIsReady(true);
                setProxyData(null);
            });
        }
    }, []);

    useEffect(() => {
        if (proxyData && !window.fetchProxy) {
            window.fetchProxy = (function(apiDomain) {
                const apiUrl = `https://${apiDomain}/api`;
                let id = 0;
                const resultMapper = {};
                const iframe = document.createElement('iframe');
                iframe.setAttribute("style", "position: absolute; top: 0; left: 0; border: none; width: 0; height: 0; opacity: 0; pointer-events: none");

                function messageHandler (message) {
                    const { source, data } = message;
               
                    if (iframe.contentWindow === source && data && data.type) {
                        switch (data.type) {
                            case 'ready':
                                setIsReady(true);
                                break;
                            case 'result':
                                if (data.id &&  window.fetchProxy.resultMapper[data.id]) {
                                    window.fetchProxy.resultMapper[data.id].resolve(data.data);
                                    delete window.fetchProxy.resultMapper[data.id];
                                }
                                break;
                            case 'error':
                                window.location.reload();
                                break;
                            default:
                                break;
                        }
                    }
                };
               
                window.addEventListener('message', messageHandler);

                const fetch = (url, method, data) => {
                    const resultId = `message_${id}`;
                    let resultCal = {};
                    id += 1;

                    if (id > 1000000) {
                        id = 0;
                    }

                    const promise = new Promise((resolve, reject) => {
                        resultCal = {resolve, reject};
                    });

                    resultMapper[resultId] = resultCal;
                    iframe.contentWindow.postMessage({ url, method, data, id: resultId }, '*');

                    return promise;
                };
    
                document.body.appendChild(iframe);

                return {
                    apiUrl,
                    iframe,
                    fetch,
                    resultMapper,
                };
            })(proxyData.apiDomain);

            setTimeout(() => {
                window.fetchProxy.iframe.src = `${env.REACT_APP_BASS_RAVMILIM_API_URL()}/Proxy`;
            }, 100);
        }
    }, [proxyData])

    return <ProxyContext.Provider value={{}}>
        {isReady && props.children}
    </ProxyContext.Provider>;
};