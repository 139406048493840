import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Title from '../../components/Title';
import SignupForm from '../../components/SignupForm';
import Button, { buttonType } from '../../components/Button';
import OrgForm from './orgForm';
import OuterLayer from '../../containers/OuterLayer';
import { useLabels } from '../../hooks';
import { PageContext, DeviceContext, UserContext, UserType, OutersContainerContext } from '../../contexts';
import { scrollTop } from '../../utils/helpers';
import { env } from '../../config';
import styles from './Purchase.module.scss';

const pageKey = "purchase";

const subscriptionType = {
  corporate: 'corporate',
  monthly: 'monthly',
  yearly: 'yearly',
};

const Home = () => {
  const [ subscription, setSubscription ] = useState();
  const { getLabel, getLocalUrlByLanguage } = useLabels();
  const { setPageKey } = useContext(PageContext);
  const { isMobileView } = useContext(DeviceContext);
  const { hasPermissions, userType, orgData, codeDeliveryDetails } = useContext(UserContext);
  const { appLayoutTitleContainer } = useContext(OutersContainerContext);
  const navigate = useNavigate();

  useEffect(() => {
    if(hasPermissions) {
      navigate(getLocalUrlByLanguage(`/`));
    }
  }, [hasPermissions, getLocalUrlByLanguage, navigate]);

  useEffect(() => {
    setPageKey(pageKey);
  }, [setPageKey]);

  const setSubscriptionState = useCallback((subscription) => {
    setSubscription(subscription);
    scrollTop();
  }, []);

  const corporate = useMemo(() => {
    return <OrgForm />;
  }, []);

  const signup = useMemo(() => {
    return <div className={`${styles.container} ${styles.container_signup}`}>
        <div className={styles.sign_up}>
          <OuterLayer container={appLayoutTitleContainer} TagWrapper='span'>{getLabel(env.REACT_APP_ORG_ENABLE() ? 'purchase_page_signup_org_title' : 'purchase_page_signup_title')}</OuterLayer>
          {/*!isMobileView && false && <Title className={styles.title} TagWrapper="div"><span>{getLabel(env.REACT_APP_ORG_ENABLE() ? 'purchase_page_signup_org_title' : 'purchase_page_signup_title')}</span></Title>*/}
          <SignupForm />
        </div>
      </div>;
  }, [appLayoutTitleContainer, getLabel]);

  const subscriptionSelector = useMemo(() => {
    return <div className={`${styles.container} ${styles.container_subscriptions}`}>
        <div className={styles.subscriptions}>
          <div className={styles.subscription}>
            <Title className={styles.title} TagWrapper="div"><span>{getLabel('purchase_page_subscription_corporate_title')}</span></Title>
            <div className={styles.subscription_content}>
              <div className={styles.subscription_text}>{getLabel('purchase_page_subscription_corporate_text')}</div>
            </div>
            <div className={styles.subscription_buttons}>
              <Button type={buttonType.premium} className={styles.subscription_button} onClick={()=>{setSubscriptionState(subscriptionType.corporate)}} >{getLabel('purchase_page_subscription_corporate_button')}</Button>
            </div>
          </div>
          <div className={`${styles.subscription} ${styles.subscription_main}`}>
            <div className={styles.subscription_label} data-label={getLabel('purchase_page_subscription_yearly_label')}></div>
            <Title className={styles.title} TagWrapper="div"><span>{getLabel('purchase_page_subscription_yearly_title')}</span></Title>
            <div className={styles.subscription_content}>
              <div className={styles.subscription_price} dangerouslySetInnerHTML={{ __html: getLabel('purchase_page_subscription_yearly_price')}} />
              <div className={styles.subscription_text}>{getLabel('purchase_page_subscription_yearly_text')}</div>
            </div>
            <div className={styles.subscription_buttons}>
              <Button type={buttonType.premium} className={styles.subscription_button} onClick={()=>{setSubscriptionState(subscriptionType.yearly)}} >{getLabel('purchase_page_subscription_buy_button')}</Button>
            </div>
          </div>
          <div className={styles.subscription}>
            <Title className={styles.title} TagWrapper="div"><span>{getLabel('purchase_page_subscription_monthly_title')}</span></Title>
            <div className={styles.subscription_content}>
              <div className={styles.subscription_price} dangerouslySetInnerHTML={{ __html: getLabel('purchase_page_subscription_monthly_price')}} />
              <div className={styles.subscription_text}>{getLabel('purchase_page_subscription_monthly_text')}</div>
            </div>
            <div className={styles.subscription_buttons}>
              <Button type={buttonType.premium} className={styles.subscription_button} onClick={()=>{setSubscriptionState(subscriptionType.monthly)}} >{getLabel('purchase_page_subscription_buy_button')}</Button>
            </div>
          </div>
        </div>
      </div>;
  }, [getLabel, setSubscriptionState]);

  const layout = useMemo(() => {
    if (env.REACT_APP_ORG_ENABLE() && ((orgData && orgData.isActive) || codeDeliveryDetails)) {
      return signup;
    }

    if (env.REACT_APP_ORG_ENABLE() || subscription === subscriptionType.corporate) {
      return corporate;
    }

    if (!subscription) {
      return subscriptionSelector;
    }

    if (userType === UserType.guest) {
      return signup;
    }
    
    return <></>;
  }, [codeDeliveryDetails, corporate, orgData, signup, subscription, subscriptionSelector, userType]);

  return layout;
};

export default Home;
