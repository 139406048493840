
export const debounce = function (callback, delay) {
    let timer;

    return function (...args) {
        clearTimeout(timer);
        timer = setTimeout(() => {
            callback.apply(this, args);
        }, delay);
    };
};

export const throttle = function (callback, delay) {
    let isWaitting = false;
    let savedAggs = null;
    let saveThis = null;

    function wrapper (...args) {
        if(isWaitting) {
            savedAggs = args;
            saveThis = this;
            return;
        }
        
        callback.apply(this, args);
        isWaitting = true;

        setTimeout(() => {
            isWaitting = false;
            if(saveThis) {
                wrapper.apply(saveThis, savedAggs);
                savedAggs = null;
                saveThis = null;
            }
        }, delay);
    };

    return wrapper;
};

export const curry = function (fn) {
    function curried(...args) {
        return (args.length >= fn.length) ? fn.apply(this, args) : curried.bind(this, ...args);
    };
    return curried;
};

export const fetch = function (url, init, jwtToken) {
    if (window.fetchProxy) {
        return window.fetchProxy.fetch(url, init.method, init.body);
    }

    return window.fetch(url, {
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Device-UUID': getDeviceUUID(),
            'Session-UUID': getSessionUUID(),
            'Org-Enable': (process.env.REACT_APP_ORG_ENABLE === "true" || process.env.REACT_APP_ORG_ENABLE === window.location.host) ? "true" : "false",
            ...(jwtToken ? {Authorization: `Bearer ${jwtToken}`} : {}),
        },
        credentials: "include",
        ...init
    }).then(response => {
        return response.json();
    });
};

export const createUUID = function () {
    const url = URL.createObjectURL(new Blob())
    const [id] = url.toString().split('/').reverse()
    URL.revokeObjectURL(url)
    return id
};

export const getDeviceUUID = function () {
    let id = localStorage.getItem('DeviceUUID');
    if (!id) {
        id = createUUID();
        localStorage.setItem('DeviceUUID', id);
    }
    return id;
};

export const getSessionUUID = function (updated = false) {
    const updatedSessionUUID = function () {
        let id = createUUID();
        localStorage.setItem('SessionUUID', id);
        return id;
    };

    if (updated) {
        return updatedSessionUUID();
    }

    let id = localStorage.getItem('SessionUUID');
    if (!id) {
        id = updatedSessionUUID();
    }
    return id;
};

export const getRootFontSize = function () {
    return parseFloat(window.getComputedStyle(document.body).getPropertyValue('font-size'));
};

export const pxToRem = function (px) {
    return px / getRootFontSize();
};

export const isFullUrl = function (url) {
    const pattern = new RegExp('^(?:[a-z]+:)?//', 'i');
    if (pattern.test(url))
        return true;
    return false;
}

export const getLinkTarget = function (url) {
    return isFullUrl(url) ? '_blank' : '_top';
};

export const formatString = function (template, ...args) {
    return template.replace(/{([0-9]+)}/g, function (match, index) {
        return typeof args[index] === 'undefined' ? match : args[index];
    });
};

export const getTextWithoutHtml = function (text) {
    const div = document.createElement('div');
    div.innerHTML = `<div>${text}</div>`;
    return div.innerText;
};

export const removeAllHtmlTags = function (text) {
    const div = document.createElement('div');
    div.innerHTML = text;
    const texts = [];
    div.childNodes.forEach(node => {
        if (node.nodeType === window.Node.TEXT_NODE && node.nodeValue) {
            texts.push(node.nodeValue.trim());
        }
    });
    return texts.join(' ');
};

export const getRootDom = function () {
    return document.getElementById("root");
};

export const getAppDom = function () {
    return document.getElementById("app");
};

export const scrollTop = function (top = 0, behavior = 'smooth') {
    let newTop = top;

    if (newTop > 0) {
        const stickySectionSeparatorTop = getStickySectionSeparatorClientRect().top;
        const pageContentHeight = document.getElementById("page_content").getBoundingClientRect().height;
        const appContentHeight = getAppDom().getBoundingClientRect().height;

        const scrollView = appContentHeight - stickySectionSeparatorTop;
        const maxScroll = pageContentHeight <= scrollView ? 0 : pageContentHeight - scrollView - 10;
        if (newTop > maxScroll) {
            newTop = maxScroll;
        }
    }

    if (newTop < 0) {
        newTop = 0;
    }

    getAppDom().scroll({top: newTop, behavior});
};

export const getStickySectionSeparatorClientRect = function () {
    return document.getElementById("sticky_section_separator").getBoundingClientRect();
};

export const getAppScrollTop = function () {
    return getAppDom().scrollTop;
};
