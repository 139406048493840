import labelsConfig from "./labels";
import envConfig from "./env";
import pagesConfig from "./pages";
import headerConfig from "./header";
import footerConfig from "./footer";

export const systemLanguages = ['he', 'en'];
export const labels = labelsConfig;
export const env = envConfig;
export const pages = pagesConfig;
export const header = headerConfig;
export const footer = footerConfig;