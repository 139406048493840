import React, { useCallback, useEffect, useRef, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import FormInputField from '../FormInputField';
import Button, { buttonType } from '../../components/Button';
import Alert from '../Alert';
import { alertType } from '../Alert/alertType';
import Loader from '../Loader';
import { useLabels } from '../../hooks';
import { UserContext } from '../../contexts';
import styles from './ChangePasswordForm.module.scss';

const ChangePasswordForm = () => {
    const [ showLoader, setShowLoader ] = useState(false);
    const [ error, setError ] = useState();
    const navigate = useNavigate();
    const { getLabel, getCognitoApiError, getLocalUrlByLanguage } = useLabels();
    const { changePassword } = useContext(UserContext);
    const isActive = useRef(false);
    const oldPasswordRef = useRef();
    const newPasswordRef = useRef();
    const confirmNewPasswordRef = useRef();
    const alertRef = useRef();

    useEffect(() => {
        isActive.current = true;

        return () => {
            isActive.current = false;
        };
    }, []);

    const onSignupButtonClickHandle = useCallback((event) => {
        event?.preventDefault();
        let focusField = null;

        if (!oldPasswordRef.current.validation()) {
            focusField = focusField || oldPasswordRef.current;
        }

        if (!newPasswordRef.current.validation()) {
            focusField = focusField || newPasswordRef.current;
        }

        if (!confirmNewPasswordRef.current.validation()) {
            focusField = focusField || confirmNewPasswordRef.current;
        }

        if (focusField) {
            focusField.focus();
            return;
        }

        setError(null);
        setShowLoader(true);

        const oldPassword = oldPasswordRef.current.getValue();
        const newPassword = newPasswordRef.current.getValue();

        changePassword(oldPassword, newPassword).then(() => {
            if (isActive.current) {
                setShowLoader(false);
                if (alertRef.current) {
                    alertRef.current.show({
                        type: alertType.alert,
                        text: getLabel('changepassword_form_success_alert_text'),
                        button_text: getLabel('changepassword_form_success_alert_button')
                    });
                }
            }
        }).catch(error => {
            if (isActive.current) {
                setError(error);
                setShowLoader(false);
            }
        });
    }, [changePassword, getLabel]);

    const confirmPasswordAgainNewPasswordValidation = useCallback((password) => {
        return password === newPasswordRef.current.getValue() ? '' : getLabel('changepassword_form_confirm_new_password_match_error');
    }, [getLabel]);


    return <>
        <div className={styles.change_password}>
            <form className={styles.form} onSubmit={onSignupButtonClickHandle}>
                <FormInputField label={getLabel('changepassword_form_old_password_field')} placeholder={getLabel('changepassword_form_old_password_field')} ref={oldPasswordRef} required={true} type="password" />
                <FormInputField label={getLabel('changepassword_form_new_password_field')} placeholder={getLabel('changepassword_form_new_password_field')} ref={newPasswordRef} passwordValidation={true} type="password" />
                <FormInputField label={getLabel('changepassword_form_confirm_password_field')} placeholder={getLabel('changepassword_form_confirm_password_field')} ref={confirmNewPasswordRef} validationPattern={confirmPasswordAgainNewPasswordValidation} type="password" />
                <button hidden={true}></button>
            </form>
            <div className={styles.buttons}>
                <div className={styles.error} dangerouslySetInnerHTML={{ __html: getCognitoApiError(error)}} />
                <Button type={buttonType.premium} className={styles.button} onClick={onSignupButtonClickHandle} >{getLabel('changepassword_form_save_button')}</Button>
                <Button type={buttonType.link} className={styles.button} onClick={() => {navigate(getLocalUrlByLanguage(`/user`))}} >{getLabel('changepassword_form_cancel_button')}</Button>
            </div>
        </div>
        <Alert ref={alertRef} />
        {showLoader && <Loader />}
    </>;
};

export default ChangePasswordForm;