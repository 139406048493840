import React, { useContext, useMemo } from 'react';
import { PageContext } from '../../contexts';
import { navigationItemComponent } from '../../hooks';
import styles from './NavigationItem.module.scss';


const NavigationItem = ({type, pages, className, ...props}) => {
    const { pageKey} = useContext(PageContext);

    const itemClassName = useMemo(() => {
        let classList = [styles.item, styles[`item_${type}`]];

        if (pages && pages.indexOf(pageKey) >= 0) {
            classList.push(styles.item_selected);
        }

        if (className) {
            classList.push(className);
        }

        return classList.join(' ');
    }, [type, pages, pageKey, className]);

    const Tag = useMemo(() => {
        return navigationItemComponent[type] || "div";
    }, [type]);

    return <Tag className={itemClassName} {...props} />;
};

export default NavigationItem;