/* eslint-disable import/no-anonymous-default-export */
import { contentType } from '../../pages/Dynamic/contentType';

export default {
    default: {
        seo_title: "רב-מילים | מילון עברי-עברי, המילון העדכני ביותר ברשת",
        seo_description: "רב-מילים – המילון העברי המקיף והעדכני ביותר ברשת. המילון כולל הסברים מלאים, דוגמאות שימוש, מילים נרדפות, ביטויים וצירופי לשון, חריזה, תרגום עברי-אנגלי-עברי, ניתוח דקדוקי, זיהוי וניקוד כל צורת נטייה, פותר תשבצים ועוד",
        seo_keywords: "",
        seo_image: "",
    },
    about: {
        title: "",
        sub_title: "",
        title_mobile: "על המילון",
        content: [
            {
                type: contentType.about,
                topics: [
                    {
                        title: "על המילון",
                        html: `
                            <ul>
                                <li>מלוא התכולה של מילון רב-מילים השלם – כל אוצר המילים העשיר של השפה העברית על כל רבדיה, הסברים, מקורות, דוגמאות שימוש ועוד.</li>
                                <li>רשימת כל הצירופים הלשוניים שבהם מופיעה כל מילה, והסבריהם .</li>
                                <li>חריזה אוטומטית - רשימת כל המילים המתחרזות עם כל מילה .</li>
                                <li>'מעבדה לשונית': ניתוח דקדוקי מלא לכל מילה (חלק דיבר, מין, מספר, בניין, גוף, זמן, שורש, צורת יסוד, כינויים ואותיות שימוש, כתיב מלא תקני, ועוד).</li>
                                <li>אוצר המילים הנרדפות (התזאורוס) של חברת מלינגו, הכולל מספר עצום של מילים נרדפות (ואף צירופי מילים) מכל רובדי השפה .</li>
                                <li>תרגום עברי-אנגלי ואנגלי-עברי.</li>
                            </ul>
                        `
                    },
                    {
                        title: "רב מילים",
                        html: `
                            מילון רב-מילים ברשת האינטרנט הוא פיתוח נוסף של חברת מלינגו בע"מ, והמשכו של מפעל רב-מילים, שהוא מפעל המילונאות והבלשנות החישובית הגדול בישראל.<br/>
                            במפעל זה, שפותח במרכז לטכנולוגיה חינוכית, הושקעו כמאה שנות אדם, והשתתפו בו מיטב מומחי הלשון, המילונאות והמחשבים בארץ, בראשות פרופ' יעקב שויקה ז"ל, מומחה בעל שם עולמי בבלשנות חישובית.<br/>
                            המילון העברי של רב-מילים, שגרסתו המודפסת יצאה לאור ב-1997, הוא המילון המקיף והחדשני ביותר של העברית בת-זמננו. הוא משלב גישה חדשנית במילונאות, ידע מתקדם בבלשנות וטכנולוגיית מחשב עדכנית.<br/>
                            <br/>
                            השימוש בתוכנת רב-מילים קל, ידידותי, ואינו דורש ידע מוקדם בלשון. אפשר להקליד כל מילה בדיוק כפי שהיא מופיעה בטקסט כלשהו (גם מילים בנטייה, עם כינויים, עם אותיות שימוש וכו'), ולקבל עליה הסבר מלא, דוגמאות וכן מידע לשוני רב ומגוון. ניתן גם להקליד מילה באנגלית ולקבל את תרגומה לעברית.<br/>
                            המילון המקוון מתעדכן באופן שוטף, נוספות לו מילים חדשות מבית היוצר של האקדמיה ללשון העברית, בצד מונחים טכנולוגיים ואחרים המתווספים לשפה, ואף שימושים חדשים בלשון הדיבור ובסלנג.<br/>
                            נשמח לקבל הערות והצעות, ולהוסיפן במידת האפשר למילון.<br/>
                        `
                    },
                    {
                        title: "העורכים",
                        html: `
                            <span style="white-space:nowrap">ראש המערכת:</span> פרופ' יעקב שויקה ז"ל •&nbsp;
                            <span style="white-space:nowrap">עורך ראשי:</span> עוזי פריידקין •&nbsp;
                            <span style="white-space:nowrap">עורכים:</span> ד"ר חיים א' כהן, יעל צחי-ינאי •&nbsp;
                            <span style="white-space:nowrap">עורך מונחים מדעיים:</span> ד"ר יכין אונא •&nbsp;
                            <span style="white-space:nowrap">ריכוז צוות מחשוב:</span> יוני נאמן •&nbsp;
                            <span style="white-space:nowrap">ריכוז צוות לשון:</span> רחל פינקל, חגית כרמיאל •&nbsp;
                            <span style="white-space:nowrap">ריכוז עריכה והפקה:</span> שרה שויקה •&nbsp;
                            <span style="white-space:nowrap">תכנות:</span> אבי דנון, יוסי סרוסי •&nbsp;
                            <span style="white-space:nowrap">כתיבת ערכים:</span> מינה פריידקין •&nbsp;
                            <span style="white-space:nowrap">בדיקות לשוניות:</span> שלומית סבן בר-שי •&nbsp;
                            <span style="white-space:nowrap">הקלדה והגהות:</span> שרון ירושלמי-פטל, מיכל צימרמן, דורית שמעוני •&nbsp;
                            <span style="white-space:nowrap">ועדת היגוי:</span> פרופ' יעקב שויקה (ז"ל, יו"ר), משה זינגר (ז"ל),  פרופ'  ראובן מירקין, פרופ' רפאל ניר (ז"ל),פרופ' מנחם צבי קדרי (ז"ל), פרופ' אורה שורצולד •&nbsp;
                            <span style="white-space:nowrap">תרגום מעברית לאנגלית:</span> ד"ר  זאב  צנטנר, מרים בלום •&nbsp;
                            <span style="white-space:nowrap">תרגום מאנגלית לעברית:</span> ברכה ניר&nbsp;
                        `
                    },
                ]
            }
        ],
    },
    numbers: {
        title: "מספרים במילים",
        sub_title_pc: "<span>אפשר תמיד לחפש מספרים בשורת החיפוש שלנו ויתקבל </span><br /><span>תרגום של ספרות ומספרים למילים בעברית ובאנגלית</span>",
        content: [
            {
                type: contentType.items_grid,
                columns_pc: 2,
                columns_mobile: 1,
                item_templet_pc: [
                    {fontWeight: 'bold', direction: 'rtl'},
                    {direction: 'rtl'},
                    {direction: 'ltr'},
                ],
                item_templet_mobile: [
                    {fontSize: '1rem', direction: 'rtl'},
                    {direction: 'rtl'},
                    {direction: 'ltr'},
                ],
                items: [
                    {
                        rows: [
                            "מספר – 125689",
                            "מֵאָה עֶשְׂרִים וַחֲמִשָּׁה אֶלֶף שֵׁשׁ מֵאוֹת שְׁמוֹנִים וָתֵשַׁע",
                            "one hundred twenty-five thousand six hundred eighty-nine",
                        ],
                        link: "",
                    },
                    {
                        rows: [
                            "מספר עם שם עצם בעברית – 12 שעות, 12 ימים",
                            "(המספר במילים מותאם למין הדקדוקי).",
                            "שְׁתֵּים עֶשְׂרֵה שָׁעוֹת, שְׁנֵים עָשָׂר יָמִים",
                        ],
                        link: "",
                        item_templet_pc: [
                            {fontWeight: 'bold', direction: 'rtl'},
                            {direction: 'rtl'},
                            {direction: 'rtl'},
                        ],
                        item_templet_mobile: [
                            {fontSize: '1rem', direction: 'rtl'},
                            {direction: 'rtl'},
                            {direction: 'rtl'},
                        ],
                    },
                    {
                        rows: [
                            "תאריך – 06/08/08",
                            "שִׁשָּׁה בְּאוֹגוּסְט אַלְפַּיִם וּשְׁמוֹנֶה",
                            "August sixth, two thousand eight",
                        ],
                        link: "",
                    },
                    {
                        rows: [
                            "שעה – 11:15",
                            "אַחַת עֶשְׂרֵה וַחֲמֵשׁ עֶשְׂרֵה דַּקּוֹת",
                            "eleven fifteen a.m.",
                        ],
                        link: "",
                    },
                    {
                        rows: [
                            "שברים – 1/5, 3/4",
                            "חֲמִישִׁית fifth",
                            "שְׁלוֹשָׁה רְבָעִים three quarters",
                        ],
                        link: "",
                        item_templet_pc: [
                            {fontWeight: 'bold', direction: 'rtl'},
                            {direction: 'rtl'},
                            {direction: 'rtl'},
                        ],
                        item_templet_mobile: [
                            {fontSize: '1rem', direction: 'rtl'},
                            {direction: 'rtl'},
                            {direction: 'rtl'},
                        ],
                    },
                    {
                        rows: [
                            "אחוזים – 25%",
                            "עֶשְׂרִים וַחֲמִשָּׁה אָחוּז",
                            "twenty-five percent",
                        ],
                        link: "",
                    },
                    {
                        rows: [
                            "דולרים – 250$",
                            "מָאתַיִם וַחֲמִשִּׁים דּוֹלָר",
                            "two hundred fifty dollars",
                        ],
                        link: "",
                    },
                    { 
                        rows: [
                            "שבר עשרוני - 2.43",
                            "שְׁתַּיִם נְקֻדָּה אַרְבָּעִים וְשָׁלוֹשׁ",
                            "two point forty-three",
                        ],
                        link: "",
                    }
                ]
            }
        ]
    },
    help: {
        title: "עזרה",
        sub_title: "עמוד זה יהיה מוכן בקרוב. תודה על הסבלנות.",
        content: [
            
        ]
    },
    contact_us: {
        title: "כתבו לנו",
        content: [
            {
                type: contentType.hubspot,
                data: {
                    region: "eu1",
                    portalId: "26939907",
                    formId: "50497816-3f40-492e-aa0d-a0b02ffc0ae6"
                },
                width: "",
                height: "",
            }
        ]
    },
    license: {
        title: "תנאי שימוש",
        content: [
            {
                type: contentType.html,
                html: `
                    <p>מסמך זה מנוסח בלשון זכר מטעמי נוחות, אך הוא מכוון לנשים ולגברים כאחד.</p>
                    <p>ברוכים הבאים לאתר מילון רב מילים של מלינגו בע"מ ("<b>האתר</b>"). הסכם זה הוא ביני/בינינו (להלן: "<b>אני</b>", "<b>אנו</b>", "<b>אנחנו</b>", "<b>הלקוח</b>") לבין חברת מלינגו בע"מ ("<b>מלינגו</b>"). במקרה של מינוי הנרכש עבור ארגון (חברה, שותפות, עמותה וכיוב'), אני, באישורי להסכם זה, מאשר שאני נציג מוסמך של הארגון המשתמש ("<b>הארגון</b>").</p>
                    <p>השימוש באתר כפוף לתנאים המפורטים להלן, והשימוש באתר מייצג את הסכמת הלקוח לתנאים אלה.</p>
                    <p><b>באשר הלקוח הינו לקוח פרטי, הרי שהוא מקבל את התנאים האלה מטעם עצמו, והתייחסויות ברבים הינן כדין התייחסויות ביחיד. באשר הלקוח הינו ארגון, הרי שמאשר ההתקשרות מקבל את התנאים בשם הארגון.</b></p>
                    <p>הלקוח מתחייב כי פרטי הזהות אשר מסר נמסרו על ידו כולל, במקרה של ארגון, השם הרשום וה- IP הקשור של הארגון, הינם נכונים, שלמים ומעודכנים. במקרה ומדובר בהרשמה של ארגון, תנאי שימוש אלה יהיו תקפים לגבי כל משתמש ("<b>המשתמשים</b>") אשר ישתמש באתר באופן חוקי במסגרת תנאים אלה.</p>
                    <p>
                        <ol>
                            <li><p>הלקוח מודע לכך שהרישיון המוענק בזאת להשתמש באתר מוגבל אליו בלבד (אם נרשם באופן פרטי) או למשתמשים שלו בלבד (אם הלקוח נרשם כארגון).</p></li>
                            <li><p>במקרה של רכישת מינוי בודד או מספר מינויים (שאינו מינוי ארגוני בדרך של פתיחת IP  לכלל הארגון) הלקוח מאשר ומתחייב בזאת כי השימוש במינוי (אחד) מוגבל לשימוש של משתמש אחד בלבד בו-זמנית. כלומר, שימוש של מספר אנשים במקביל באותו מינוי בו-זמנית אסור בהחלט והנו בניגוד לתנאי הרישיון של המינוי גם כאשר מדובר בארגון.</p></li>
                            <li><p>הלקוח מודע לכך שהשימוש באתר מוגבל להקשה ידנית או העתקה של מילים בודדות או קבוצות מילים לתוך התיבה המיועדת לכך באתר ושכל גישה אל האתר או שימוש בו (בין אם ניתנה לכך רשותו של הלקוח ובין אם לאו) באמצעות תוכנית אוטומטית ליצירת שאילתות, היא אסורה בהחלט ומהווה הפרה מהותית של תנאים אלה.</p></li>
                            <li><p>זכות השימוש באתר מוגבלת אך ורק לצפייה במילה מתורגמת או משפט מתורגם ו/או הגדרות ו/או מידע בשפה אחרת ("<b>נתוני האתר</b>") כפי שמוצגים באתר לשימושים לא מסחריים, וכל שימוש אחר, כולל אך לא מוגבל לשימוש מסחרי או שימוש למטרות רווח בנתוני האתר, הוא אסור. על מנת למנוע כל ספק יובהר כי הכוונה היא, בין היתר, שכל יצירה של רשימות מילים, לקסיקונים, או נתונים לינגוויסטיים מכל סוג שהוא תוך שימוש באתר, כל שליחה לאתר ו/או איסוף מהאתר של רשימות מילים ו/או ביטויים, בין אם במיון אלפביתי או שלא במיון אלפביתי, כל העתקה מן האתר של כל כמות של נתוני האתר לכל מדיה שהיא, בין אם לצורך תחרות עם אתר רב מילים או כל מוצר אחר של מלינגו, או לכל צורך אחר, היא אסורה בהחלט. מבלי לגרוע מן האמור לעיל, כל רשימת מילים, לקסיקון, או נתונים לינגוויסטיים המופקים ע"י שימוש באתר ייחשבו תוכן האתר ויהיו קניינה הבלעדי של מלינגו.</p></li>
                            <li><p>הלקוח מודע לכך שהשימוש באתר מוגבל לדפוס שימוש ידני סביר, אשר נקבע בהתאם לתנאים המפורטים בסעיפים 2 ו- 3 ("<b>דפוס שימוש סביר</b>"). הלקוח מקבל בזאת את שיקול הדעת של מלינגו בקביעת מהות דפוס השימוש הסביר, אשר בין היתר יהיה פונקציה של היות הלקוח משתמש פרטי יחיד או ארגון. הלקוח מודע לעובדה שאם וכאשר תקבע מלינגו שהוא השתמש באתר בניגוד לדפוס השימוש הסביר, הלקוח עלול להיחסם מגישה ושימוש נוספים באתר עד שמלינגו תקבע לשביעות רצונה המלאה שהשימוש שלו אינו מפר את התנאים האלה.</p></li>
                            <li><p>כל עוד נמצא הלקוח פועל בניגוד לדפוס השימוש הסביר, מלינגו זכאית להשהות את ההרשמה שלו ללא התראה וללא צורך להשיב לו את דמי ההרשמה ו/או לסרב לחדש את ההרשמה שלו.</p></li>
                            <li><p>מבלי לגרוע מן הכלליות של תנאים אלה, אסור (א) לפרוץ לתוך האתר, ו- (ב) לשנות, לגרום נזק, להוסיף או להסיר מתכני האתר, העיצוב שלו, התוכנה שלו או כל חומר אחר הכלול בו.</p></li>
                            <li><p>כל זכויות הקניין הרוחני של האתר והשייכות אליו, כולל זכויות יוצרים באתר וכל התכנים שלו – כולל, מבלי לגרוע מכלליות האמור לעיל, העיצוב של האתר, הקוד עליו הוא מבוסס, התוכנה שלו, הקבצים הגרפיים שלו, הטקסט שלו, התכנים של המילון, כל הפורמטים והמרכיבים שלו וכל חומר אחר הכלול בו ("תכני האתר") – שמורים למלינגו. אסור להפיץ, לשכפל, להעתיק, להציג בפומבי, להעביר לצד ג' או לעשות שימוש אחר כלשהוא מלבד המותר בהתאם לתנאים אלה, את תכני האתר או חלק כלשהוא מהם, ללא הסכמתה מראש ובכתב של מלינגו.</p></li>
                            <li><p>מלינגו לא תהא אחראית לכל נזק ישיר או עקיף, כספי או אחר, אשר עלול להיגרם למשתמשים או לצדדים שלישיים כלשהם כתוצאה משימוש באתר או מהסתמכות על מידע המופיע בו או שנאסף ממנו.</p></li>
                            <li><p>למלינגו תהא שמורה הזכות, בכל זמן, לכלול פרסומות של צד ג' באתר. מלינגו אינה אחראית לתוכן הפרסומות הללו ולא לכל אתר אליו הן עשויות להיות מקושרות; כמו כן מלינגו אינה תומכת או ממליצה בהכרח על המוצרים או השירותים המתפרסמים באתר.</p></li>
                            <li>
                                <p>
                                    <div>קניה באתר - תשלום, החזרים וביטולים:</div>
                                    <div>• הזמנה של השירות תעשה באמצעות כרטיס אשראי, בבעלות וברשות המשתמש בלבד. במסגרת הזמנה של מוצר/שירות המוצע באתר, מאשר הנרשם לחברת מלינגו להעביר למנפיקת כרטיס אשראי חיובים בגין השירות האמור כפי שתפרט מלינגו למנפיקה. הרשאה זו תפקע בהודעה של הנרשם למלינגו בכתב, תוך 3 ימי עסקים לאחר מתן ההודעה. הרשאה זו תהיה בתוקף גם לחיוב כרטיס שיונפק ויישא מספר אחר, כחלופה לכרטיס שמספרו נקוב בשובר זה.</div>
                                    <div>• שימוש בפרטים כוזבים, לרבות פרטים אישיים ו/או שימוש במספר כרטיס אשראי שאינו בבעלות או ברשות הנרשם, הינם עבירה פלילית, ומלינגו תנקוט בהליכים משפטיים כנגד כל העובר עבירה פלילית זו, מבלי לתת כל התראה נוספת, לרבות תביעות נזיקין בגין נזקים שעלולים להיגרם לאתר או למלינגו או לכל צד שלישי.</div>
                                    <div>• האתר הינו אתר מאובטח והסליקה מבוצעת על ידי צד ג' העומד בתקן PCI . מלינגו תנקוט באמצעי הזהירות המקובלים לשמירת הפרטים האישיים וכרטיסי האשראי של הרוכש, ככל האפשר.</div>
                                    <div>• החיוב בכרטיס אשראי, במידה ואושר ע"י חברת האשראי, הינו מידי. עסקת הרכישה תבוצע מיד עם קבלת האישור מחברת האשראי.</div>
                                    <div>• הסכומים הנקובים באתר כוללים מע"מ אלא אם צוין אחרת במפורש.</div>
                                    <div>• בתום הרכישה תונפק חשבונית/אסמכתא על רכישה, אשר תשלח לכתובת הדואר האלקטרוני כפי שנמסר בעת הרישום.</div>
                                    <div>• מדיניות הביטול תהיה כפופה לחוק הגנת הצרכן, התשמ"א-1981 (להלן: "חוק הגנת הצרכן") ואין באמור בתנאי שימוש אלו כדי לגרוע ממנו. הודעת ביטול תתקבל אצל מלינגו באם נשלחה בדואר אלקטרוני בתוך 14 ימי עסקים מיום עשיית העסקה. במקרה של ביטול לאחר 14 יום יבוצע הביטול החל ממועד החיוב העתידי הקרוב. במקרה של מינוי חודשי מתמשך הביטול יתאפשר רק לאחר 3 חודשי חיוב.</div>
                                    <div>• החזר כספי/זיכוי/החלפה בשירותים אחרים המוצעים באתר יינתנו בכפוף לביטול תוך 14 ימים כאמור לעיל והכל בכפוף לתשלום דמי ביטול בסך של 5% ממחיר העסקה/השירות או 100 ₪, לפי הנמוך מבניהם והכל בהתאם להוראות חוק הגנת הצרכן.</div>
                                </p>
                            </li>
                            <li><p>הלקוח מודע ומסכים לעובדה שמלינגו תהא זכאית לעשות שימוש בפרטים שהלקוח והמשתמשים מספקים ובכל המידע הנאגר אודות דפוסי השימוש של הלקוח לשם שיפור השירות המסופק על ידי מלינגו, ובלבד שפרטים אישיים המזהים את המשתמשים לא יועברו לצד ג' כלשהוא.  </p></li>
                            <li><p>האתר מסופק "כמות שהוא" ("AS IS"), ללא אחריות מסוג כלשהי. למלינגו אין ולא תהיה אחריות מכל סוג, בין אם במפורש או במרומז, ביחס לתנאים אלה או לאתר, לרבות אך מבלי לגרוע מכלליות האמור,  ליכולת משתמעת לסחירות, חוקיות בטריטוריה כלשהיא או התאמה לצורך מיוחד. הלקוח לוקח על עצמו את כל האחריות ביחס לאיכות, התיפקוד והביצועים של התוכנה. מלינגו אינה אחראית על התוצאות של תוכנית או שירות כלשהם, או שכל הפגמים, השגיאות או הבאגים או מי מהם, יתוקנו, או שהפונקציונליות הכלולה באתר תעמוד בדרישות או בציפיות של הלקוח. בפרט, אין ערובה לכך שהתוכנה תייצר תרגומים מדויקים בכל מקרה.   </p></li>
                            <li><p>הגבלת אחריות: למעט המצויין אחרת במפורש בכללים אלה או המוגבל ע"י החוק, האחריות של מלינגו כלפי הלקוח, בין אם חוזית, נזיקית (כולל רשלנות), או כל עילה אחרת, לא תעלה בשום מקרה על הסכומים ששילם הלקוח למלינגו עבור זכות השימוש במהלך 12 החודשים שקדמו למועד שבו קמה העילה ללקוח. בשום מקרה לא תהיה מלינגו אחראית כלפי הלקוח בגין מידע שאבד או ניזוק, אובדן הכנסות, אובדן רווחים, חוסר יכולת להשתמש בתוכנה, עלויות רכישה של מוצרים מחליפים או בגין נזקים מיוחדים, עקיפים, תוצאתיים, נלווים או פיצויים עונשיים, ללא קשר לאופן בו קרו וללא קשר לרעיון האחריות העולה בדרך כלשהיא מתנאים אלה. הגבלת אחריות זאת תחול גם במקרה בו נודע למלינגו על האפשרות לקרות הנזקים הנ"ל ומבלי להתחשב באופי הסיבה לפעולה או לטענה. הגבלת האחריות הנ"ל והחרגת נזקים כלשהם תחול מבלי להתחשב ביעילותם או הצלחתם של סעדים אחרים. מלינגו לא תוכל לספק את האתר על בסיס כלכלי ללא הגבלות אלה, המפורטות בסעיף זה.</p></li>
                            <li><p>מלינגו לא תהא אחראית על כל אי-זמינות זמנית של האתר שנגרם בשל נפילה, כשל או ליקוי בתפקוד של קווי התקשורת, התוכנה או התפעול הנכון של האתר. במקרים כנ"ל, מלינגו תעשה כמיטב יכולתה לחדש את השירות מהר ככל האפשר.</p></li>
                            <li>
                                <p>
                                    מבלי לגרוע מן האמור לעיל, למלינגו תהיה שמורה הזכות לסיים את זכות השימוש של הלקוח באתר במקרה שהוא ביצע אחת מן הפעולות המתוארות להלן, ולחסום את הגישה שלו לשירותי האתר:
                                    <ol style="list-style: hebrew;">
                                        <li><p> סיפק פרטים כוזבים (לדוגמא, כתובת ה- IP) בעת ההרשמה לשירותי האתר.</p></li>
                                        <li><p>העתיק, הפיץ, פירסם, העביר או ביצע שימוש אחר כלשהוא בניגוד לתנאים האלה, לגבי התוכן של האתר, ו/או הפרטים והקבצים הכלולים באתר, ללא השגת אישור על כך ממלינגו מראש ובכתב.</p></li>
                                        <li><p>ביצע כל פעולה המצויה בניגוד לתנאים אלה.</p></li>
                                    </ol>
                                </p>
                            </li>
                            <li><p>הלקוח מסכים להגן ולשפות את מלינגו (וכל אחד מחברות האם, חברות הבת, החברות הקשורות, העובדים, הסוכנים, ספקי התוכן צד ג' או ספקי רשיונות, ואת חברי ההנהלה, המנהלים, העובדים והסוכנים שלהם בהתאמה) בפיצוי מלא בגין כל תביעה, חבות, הוצאה, כולל שכר טרחת עו"ד והוצאות משפט, וכל החיובים העלולים להיגרם בשל השימוש של הלקוח באתר או בגין הפרתו של תנאי כלשהוא מתנאים אלה או תנאי חוקי כלשהוא. הלקוח מודע לכך שמלינגו שומרת את הזכות, לפי שיקול דעתה הבלעדי ועל חשבונה, להגן על עצמה מפני כל עניין אשר מהווה נושא לשיפוי על ידי הלקוח. הלקוח ישתף פעולה באופן מלא כנדרש בהגנה כנגד תביעה כלשהיא.  </p></li>
                            <li><p>למלינגו תהיה שמורה הזכות לעדכן את מחירי השירות של רב מילים מעת לעת בהתאם לשיקול דעתה הבלעדי.</p></li>
                            <li><p>למלינגו תהיה שמורה הזכות להפסיק את השירותים המסופקים ע"י האתר ללא אזהרה או הודעה מוקדמת. מלינגו לא תהיה אחראית לכל נזק שייגרם למשתמש ו/או לכל צד ג' כתוצאה מהפסקה של השירותים כנ"ל.</p></li>
                        </ol>
                    </p>
                    <p>אנו מאחלים לכם שימוש מהנה ופורה באתר רב-מילים.</p>
                `
            }
        ]
    },
    search: {

    },
    not_found: {

    }
};