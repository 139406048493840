
import React, { useCallback } from 'react';
import Button, { buttonType } from '../../Button';
import styles from './Alert.module.scss';

const Alert = ({text= "", button_text= "", onButtonClick, onClose}) => {
    const onCloseButtonClickHandle = useCallback(() => {
      if (onButtonClick) {
        onButtonClick();
      }
      if (onClose) {
        onClose();
      }
    }, [onButtonClick, onClose]);

    return <div className={styles.container}>
        <div className={styles.text} dangerouslySetInnerHTML={{ __html: text}} />
        <div className={styles.buttons}>
            <Button type={buttonType.premium} className={styles.close_button} onClick={onCloseButtonClickHandle} >{button_text}</Button>
        </div>
    </div>;
};
  
export default Alert;