import React from 'react';
import styles from './Button.module.scss';

export const buttonType = {
  default: 'default',
  premium: 'premium',
  link: 'link',
};

const Button = ({ type = buttonType.default, className = '', children, ...props }) => {
  return <button className={`${styles.button} ${styles[`button_${type}`]} ${className}`} {...props} >{children}</button>;
};

export default Button;
