import { useCallback, useContext } from 'react';
import { DeviceContext } from '../contexts';

export const useDataPerDevice = () => {
    const { isMobileView, isMobile } = useContext(DeviceContext);

    const getDataPerDeviceView = useCallback((mobile_data, pc_data, default_data) => {
        if (isMobileView && mobile_data) {
          return mobile_data;
        }
    
        if (!isMobileView && pc_data) {
          return pc_data;
        }
    
        return default_data;
    }, [isMobileView]);

    const getDataPerDevice = useCallback((mobile_data, pc_data, default_data) => {
        if (isMobile && mobile_data) {
          return mobile_data;
        }
    
        if (!isMobile && pc_data) {
          return pc_data;
        }
    
        return default_data;
    }, [isMobile]);

    return {
        getDataPerDeviceView,
        getDataPerDevice,
    };
};