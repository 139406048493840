import React, { useCallback, useEffect, useRef, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import FormInputField from '../FormInputField';
import Button, { buttonType } from '../../components/Button';
import Loader from '../Loader';
import Alert from '../Alert';
import { alertType } from '../Alert/alertType';
import { useLabels } from '../../hooks';
import { UserContext } from '../../contexts';
import styles from './PersonalInformationForm.module.scss';
import { env } from '../../config';

const PersonalInformationForm = () => {
    const [ showLoader, setShowLoader ] = useState(false);
    const [ error, setError ] = useState();
    const navigate = useNavigate();
    const { getLabel, getCognitoApiError, getLocalUrlByLanguage } = useLabels();
    const { updateAttributes, userData } = useContext(UserContext);
    const { given_name, family_name, email, phone_number } = userData;
    const isActive = useRef(false);
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const alertRef = useRef();

    useEffect(() => {
        isActive.current = true;

        return () => {
            isActive.current = false;
        };
    }, []);

    const onSignupButtonClickHandle = useCallback((event) => {
        event?.preventDefault();
        let focusField = null;

        if (!firstNameRef.current.validation()) {
            focusField = focusField || firstNameRef.current;
        }

        if (!lastNameRef.current.validation()) {
            focusField = focusField || lastNameRef.current;
        }

        if (!emailRef.current.validation()) {
            focusField = focusField || emailRef.current;
        }

        if (!phoneRef.current.validation()) {
            focusField = focusField || phoneRef.current;
        }

        if (focusField) {
            focusField.focus();
            return;
        }

        setError(null);
        setShowLoader(true);

        const firstName = firstNameRef.current.getValue();
        const lastName = lastNameRef.current.getValue();
        const email = emailRef.current.getValue();
        const phone = phoneRef.current.getValue();

        updateAttributes({
            firstName,
            lastName,
            email,
            phone
        }).then((result) => {
            if (isActive.current) {
                setShowLoader(false);
                if (alertRef.current) {
                    alertRef.current.show({
                        type: alertType.alert,
                        text: getLabel('personalinformation_form_success_alert_text'),
                        button_text: getLabel('personalinformation_form_success_alert_button')
                    });
                }
            }
        }).catch(error => {
            if (isActive.current) {
                setError(error);
                setShowLoader(false);
            }
        });
    }, [getLabel, updateAttributes]);


    return <>
        <div className={styles.personal_information}>
            <form className={styles.form} onSubmit={onSignupButtonClickHandle}>
                <FormInputField ref={firstNameRef} value={given_name} label={getLabel('personalinformation_form_first_name_field')} placeholder={getLabel('personalinformation_form_first_name_field')} required={true} />
                <FormInputField ref={lastNameRef} value={family_name} label={getLabel('personalinformation_form_last_name_field')} placeholder={getLabel('personalinformation_form_last_name_field')} required={true} />
                <FormInputField ref={emailRef} {...(env.REACT_APP_ORG_ENABLE() ? {disabled: true} : {})} value={email} label={getLabel(env.REACT_APP_ORG_ENABLE() ? 'personalinformation_form_email_org_field' : 'personalinformation_form_email_field')} placeholder={getLabel(env.REACT_APP_ORG_ENABLE() ? 'yourname@yourcompany.com' : 'personalinformation_form_email_field')} required={true} emailValidation={true} type="email" />
                <FormInputField ref={phoneRef} value={phone_number} label={getLabel('personalinformation_form_phone_field')} placeholder={getLabel('personalinformation_form_phone_field')} required={true} telValidation={true} type="tel" />
                <button hidden={true}></button>
            </form>
            <div className={styles.buttons}>
                <div className={styles.error} dangerouslySetInnerHTML={{ __html: getCognitoApiError(error)}} />
                <Button type={buttonType.premium} className={styles.button} onClick={onSignupButtonClickHandle} >{getLabel('personalinformation_form_save_button')}</Button>
                <Button type={buttonType.link} className={styles.button} onClick={() => {navigate(getLocalUrlByLanguage(`/user`))}} >{getLabel('personalinformation_form_cancel_button')}</Button>
            </div>
        </div>
        <Alert ref={alertRef} />
        {showLoader && <Loader />}
    </>;
};

export default PersonalInformationForm;