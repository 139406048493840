/* eslint-disable import/no-anonymous-default-export */
import { contentType } from '../../pages/Dynamic/contentType';


export default {
    default: {
        seo_title: "RAV-MILIM | Hebrew Hebrew Dictionary",
        seo_description: "",
        seo_keywords: "",
        seo_image: "",
    },
    about: {
        title: "",
        sub_title: "",
        title_mobile: "על המילון",
        direction: "rtl",
        content: [
            {
                type: contentType.about,
                style: {
                    direction: "rtl",
                    textAlign: "right"
                },
                topics: [
                    {
                        title: "על המילון",
                        html: `
                            <ul>
                                <li>מלוא התכולה של מילון רב-מילים השלם – כל אוצר המילים העשיר של השפה העברית על כל רבדיה, הסברים, מקורות, דוגמאות שימוש ועוד.</li>
                                <li>רשימת כל הצירופים הלשוניים שבהם מופיעה כל מילה, והסבריהם .</li>
                                <li>חריזה אוטומטית - רשימת כל המילים המתחרזות עם כל מילה .</li>
                                <li>'מעבדה לשונית': ניתוח דקדוקי מלא לכל מילה (חלק דיבר, מין, מספר, בניין, גוף, זמן, שורש, צורת יסוד, כינויים ואותיות שימוש, כתיב מלא תקני, ועוד).</li>
                                <li>אוצר המילים הנרדפות (התזאורוס) של חברת מלינגו, הכולל מספר עצום של מילים נרדפות (ואף צירופי מילים) מכל רובדי השפה .</li>
                                <li>תרגום עברי-אנגלי ואנגלי-עברי.</li>
                            </ul>
                        `
                    },
                    {
                        title: "רב מילים",
                        html: `
                            מילון רב-מילים ברשת האינטרנט הוא פיתוח נוסף של חברת מלינגו בע"מ, והמשכו של מפעל רב-מילים, שהוא מפעל המילונאות והבלשנות החישובית הגדול בישראל.<br/>
                            במפעל זה, שפותח במרכז לטכנולוגיה חינוכית, הושקעו כמאה שנות אדם, והשתתפו בו מיטב מומחי הלשון, המילונאות והמחשבים בארץ, בראשות פרופ' יעקב שויקה ז"ל, מומחה בעל שם עולמי בבלשנות חישובית.<br/>
                            המילון העברי של רב-מילים, שגרסתו המודפסת יצאה לאור ב-1997, הוא המילון המקיף והחדשני ביותר של העברית בת-זמננו. הוא משלב גישה חדשנית במילונאות, ידע מתקדם בבלשנות וטכנולוגיית מחשב עדכנית.<br/>
                            <br/>
                            השימוש בתוכנת רב-מילים קל, ידידותי, ואינו דורש ידע מוקדם בלשון. אפשר להקליד כל מילה בדיוק כפי שהיא מופיעה בטקסט כלשהו (גם מילים בנטייה, עם כינויים, עם אותיות שימוש וכו'), ולקבל עליה הסבר מלא, דוגמאות וכן מידע לשוני רב ומגוון. ניתן גם להקליד מילה באנגלית ולקבל את תרגומה לעברית.<br/>
                            המילון המקוון מתעדכן באופן שוטף, נוספות לו מילים חדשות מבית היוצר של האקדמיה ללשון העברית, בצד מונחים טכנולוגיים ואחרים המתווספים לשפה, ואף שימושים חדשים בלשון הדיבור ובסלנג.<br/>
                            נשמח לקבל הערות והצעות, ולהוסיפן במידת האפשר למילון.<br/>
                        `
                    },
                    {
                        title: "העורכים",
                        html: `
                            <span style="white-space:nowrap">ראש המערכת:</span> פרופ' יעקב שויקה ז"ל •&nbsp;
                            <span style="white-space:nowrap">עורך ראשי:</span> עוזי פריידקין •&nbsp;
                            <span style="white-space:nowrap">עורכים:</span> ד"ר חיים א' כהן, יעל צחי-ינאי •&nbsp;
                            <span style="white-space:nowrap">עורך מונחים מדעיים:</span> ד"ר יכין אונא •&nbsp;
                            <span style="white-space:nowrap">ריכוז צוות מחשוב:</span> יוני נאמן •&nbsp;
                            <span style="white-space:nowrap">ריכוז צוות לשון:</span> רחל פינקל, חגית כרמיאל •&nbsp;
                            <span style="white-space:nowrap">ריכוז עריכה והפקה:</span> שרה שויקה •&nbsp;
                            <span style="white-space:nowrap">תכנות:</span> אבי דנון, יוסי סרוסי •&nbsp;
                            <span style="white-space:nowrap">כתיבת ערכים:</span> מינה פריידקין •&nbsp;
                            <span style="white-space:nowrap">בדיקות לשוניות:</span> שלומית סבן בר-שי •&nbsp;
                            <span style="white-space:nowrap">הקלדה והגהות:</span> שרון ירושלמי-פטל, מיכל צימרמן, דורית שמעוני •&nbsp;
                            <span style="white-space:nowrap">ועדת היגוי:</span> פרופ' יעקב שויקה (ז"ל, יו"ר), משה זינגר (ז"ל),  פרופ'  ראובן מירקין, פרופ' רפאל ניר (ז"ל),פרופ' מנחם צבי קדרי (ז"ל), פרופ' אורה שורצולד •&nbsp;
                            <span style="white-space:nowrap">תרגום מעברית לאנגלית:</span> ד"ר  זאב  צנטנר, מרים בלום •&nbsp;
                            <span style="white-space:nowrap">תרגום מאנגלית לעברית:</span> ברכה ניר&nbsp;
                        `
                    },
                ]
            }
        ],
    },
    numbers: {
        title: "Numbers to Text",
        sub_title_pc: "<span>Translation of digits and numbers to Hebrew and English words</span>",
        content: [
            {
                type: contentType.items_grid,
                columns_pc: 2,
                columns_mobile: 1,
                style: {
                    direction: 'rtl'
                },
                item_templet_pc: [
                    {fontWeight: 'bold', direction: 'rtl'},
                    {direction: 'rtl'},
                    {direction: 'ltr'},
                ],
                item_templet_mobile: [
                    {fontSize: '1rem', direction: 'rtl'},
                    {direction: 'rtl'},
                    {direction: 'ltr'},
                ],
                items: [
                    {
                        rows: [
                            "מספר – 125689",
                            "מֵאָה עֶשְׂרִים וַחֲמִשָּׁה אֶלֶף שֵׁשׁ מֵאוֹת שְׁמוֹנִים וָתֵשַׁע",
                            "one hundred twenty-five thousand six hundred eighty-nine",
                        ],
                        link: "",
                    },
                    {
                        rows: [
                            "מספר עם שם עצם בעברית – 12 שעות, 12 ימים",
                            "(המספר במילים מותאם למין הדקדוקי).",
                            "שְׁתֵּים עֶשְׂרֵה שָׁעוֹת, שְׁנֵים עָשָׂר יָמִים",
                        ],
                        link: "",
                        item_templet_pc: [
                            {fontWeight: 'bold', direction: 'rtl'},
                            {direction: 'rtl'},
                            {direction: 'rtl'},
                        ],
                        item_templet_mobile: [
                            {fontSize: '1rem', direction: 'rtl'},
                            {direction: 'rtl'},
                            {direction: 'rtl'},
                        ],
                    },
                    {
                        rows: [
                            "תאריך – 06/08/08",
                            "שִׁשָּׁה בְּאוֹגוּסְט אַלְפַּיִם וּשְׁמוֹנֶה",
                            "August sixth, two thousand eight",
                        ],
                        link: "",
                    },
                    {
                        rows: [
                            "שעה – 11:15",
                            "אַחַת עֶשְׂרֵה וַחֲמֵשׁ עֶשְׂרֵה דַּקּוֹת",
                            "eleven fifteen a.m.",
                        ],
                        link: "",
                    },
                    {
                        rows: [
                            "שברים – 1/5, 3/4",
                            "חֲמִישִׁית fifth",
                            "שְׁלוֹשָׁה רְבָעִים three quarters",
                        ],
                        link: "",
                        item_templet_pc: [
                            {fontWeight: 'bold', direction: 'rtl'},
                            {direction: 'rtl'},
                            {direction: 'rtl'},
                        ],
                        item_templet_mobile: [
                            {fontSize: '1rem', direction: 'rtl'},
                            {direction: 'rtl'},
                            {direction: 'rtl'},
                        ],
                    },
                    {
                        rows: [
                            "אחוזים – 25%",
                            "עֶשְׂרִים וַחֲמִשָּׁה אָחוּז",
                            "twenty-five percent",
                        ],
                        link: "",
                    },
                    {
                        rows: [
                            "דולרים – 250$",
                            "מָאתַיִם וַחֲמִשִּׁים דּוֹלָר",
                            "two hundred fifty dollars",
                        ],
                        link: "",
                    },
                    { 
                        rows: [
                            "שבר עשרוני - 2.43",
                            "שְׁתַּיִם נְקֻדָּה אַרְבָּעִים וְשָׁלוֹשׁ",
                            "two point forty-three",
                        ],
                        link: "",
                    }
                ]
            }
        ]
    },
    help: {
        title: "Help",
        sub_title: "<span>This page will be available soon.&nbsp;</span><span>Thank you for your patience.</span>",
        content: [
        ]
    },
    contact_us: {
        title: "Contact Us",
        content: [
            {
                type: contentType.hubspot,
                data: {
                    region: "eu1",
                    portalId: "26939907",
                    formId: "579259c0-d405-449a-ab1f-ab0bd093c70f"
                },
                width: "",
                height: "",
            }
        ]
    },
    license: {
        title: "",
        content: [
            {
                type: contentType.html,
                html: `
                    <p>Welcome to the Rav-Milim dictionary website by Melingo Ltd. (the “Site”). Use of this Site is subject to the conditions set out below and your continued use of this Site indicates your acceptance of the said conditions. Where you are an individual private user, you are deemed to accept these conditions on behalf of yourself, and references in the plural should be understood in the singular. Where you are an organization (company, partnership, non-profit organization etc.), you are deemed to be an authorized representative of the organization and accept these conditions on behalf of the organization.</p>
                    <p>The identity details that I have provided, including, in the case of an organizational subscription, the registered name and associated IP address of the organization, of which I am an authorized representative (the <b>“Organization”</b>, “<b>We</b>/<b>Us</b>/<b>Our</b>”), are correct, complete and up-to-date as of the date of the acceptance of the said conditions. In the case of an organizational subscription, these usage conditions shall hold with regard to any users (the “<b>Users</b>”) who may legitimately use the Site within these conditions.</p>
                    <p>
                        <ol>
                            <li><p>I am aware that the license granted hereunder to use RavMilim Site is restricted only to me (if a private individual user) or to Our Users only (if registered as an organization)</p></li>
                            <li><p>In the case of having one subscription (or several subscriptions that are not an organizational subscription with open IP address for all the organization),  we  confirm and warrant that the right of use of the Site, having one subscription, is restricted to only one user at a time. Namely, the use of several users at the same subscription simultaneously is absolutely prohibited and constitutes a breach of these conditions.</p></li>
                            <li><p>We are aware that the license granted hereunder to use the Site is restricted to Us and to Our Users only.</p></li>
                            <li><p>We are aware that use of the Site is limited to manual typing or copying of individual words or strings of words into the box on the Site and that any access to or use of the Site (whether with Our permission or not) by an automatic query-generating program is absolutely prohibited and constitutes a fundamental breach of these conditions.</p></li>
                            <li><p>The right of use of the Site is strictly limited to the viewing of word or phrase translations and/or definitions and/or other language information (“the Site Data”) as presented on the Site for non-commercial, purposes, and any other use, including but not limited to commercial or for-profit use of the Site Data is prohibited. For the avoidance of doubt, this means amongst other things that  any entering to the Site or collection using the Site of word lists, lexicons, or linguistic data of any kind using the Site, any harvesting, entering of lexical word lists, whether alphabetically ordered or not, whether manually or through an automatic program, and any copying of any amount of Site Data from the Site to any media, whether for the purpose of competition with the Rav Milim Website or any other Melingo product,  or for any other purpose, is strictly prohibited. Without derogating from the above, any word list, lexicon, or linguistic data derived from the Site shall be considered Site Content shall be fully owned by Melingo.</p></li>
                            <li><p>We are aware that use of the Site is limited to a reasonable manual usage pattern, determined in accordance with the provisions of section 2 and 3 (the “Reasonable Usage Pattern”). We hereby accept Melingo’s discretion to determine what the Reasonable Usage Pattern, which shall be a function, amongst other things, of whether We are an individual private user or an Organization. We are aware that if and when We are determined by Melingo to have used the site in contradiction of the Reasonable Usage Pattern We may be automatically blocked from further use of the Site until Melingo can determine to its satisfaction that Our usage is not in breach of these conditions.</p></li>
                            <li><p>Insofar as We are found to continually or substantially contradict the Reasonable Usage Pattern, Melingo may suspend Our subscription without notice and without returning all or any part of subscription fees and/or refuse to renew it.</p></li>
                            <li><p>Without derogating from the generality of these conditions, it shall be prohibited (a) to hack into the Site, and (b) to alter, to damage, to add to, or to remove from, the Site’s contents, its design, its software or any other material incorporated into it.</p></li>
                            <li><p>All intellectual property rights in and pertaining to the Site, including copyright in the Site and all its contents – including, without derogating from the generality of the foregoing, the design of the site, its underlying code, its software, its graphic files, its text, the contents of the dictionary, all of its formatting and components and any other material incorporated into it (the “<b>Site Contents</b>”) – are reserved to Melingo. It is prohibited to distribute, to duplicate, to copy, to publicly demonstrate, to transfer to a third party or to make any use other than that permitted according to these conditions, of the Site Contents or any part of them, without Melingo’s prior written permission.</p></li>
                            <li><p>Melingo shall have no responsibility for any direct or indirect damage, financial or otherwise, which may be caused to Users or third parties whatsoever as a result of use of this Site or reliance on information appearing on it or gathered from it.</p></li>
                            <li>
                                <p>
                                    <div>Along my signup to Rav-Milim, I approve that Melingo may forward charges for the said service to the credit card operator as detailed by Melingo. My approval will expire within 1 business day following my letter to Melingo subjected to the registration terms and conditions of the said website.</div>
                                    <div>This permission shall be valid for charges to other cards as an alternative to the card with the number in this voucher</div>
                                </p>
                            </li>
                            <li><p>Melingo shall have the option, at any time, to include third party advertisements on the Site. Melingo does not take any responsibility for the content of these advertisments nor of any websites to which may be linked; nor does Melingo necessarily endorse or recommend the products or services advertised.</p></li>
                            <li><p>We are aware of and We further consent to the fact that Melingo shall have the right to make use of the details We and the Users individually provide and any information gathered about Our usage patterns to improve the service provided by Melingo provided that no information personally identifying the users is transferred to any third party.</p></li>
                            <li><p>The Site is provided “AS IS", without a warranty of any kind. THERE ARE, AND THERE WILL BE, NO WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, WITH RESPECT TO THESE CONDITIONS, OR THE SITE, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, LEGALITY IN ANY TERRITORY OR FITNESS FOR A PARTICULAR PURPOSE. WE ASSUME ALL RISKS AS TO THE QUALITY, FUNCTION AND PERFORMANCE OF THE SOFTWARE. MELINGO DOES NOT WARRANT THE RESULTS OF ANY PROGRAM OR SERVICE, OR THAT ALL OR ANY DEFECTS, ERRORS OR BUGS WILL BE CORRECTED, OR THAT THE FUNCTIONALITY CONTAINED IN THE SITE WILL MEET OUR REQUIREMENTS OR EXPECTATIONS. IN PARTICULAR, THERE IS NO WARRANTY THAT THE SOFTWARE WILL PRODUCE ACCURATE TRANSLATIONS IN EVERY CASE.</p></li>
                            <li><p>LIMITATION OF LIABILITY: EXCEPT AS EXPLICITLY STATED OTHERWISE IN THESE CONDITIONS OR AS LIMITED BY LAW, IN NO EVENT SHALL MELINGO’S LIABILITY TO US, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, EXCEED THE FEES PAID BY US TO MELINGO FOR OUR RIGHT OF USE DURING 12 MONTHS PRESEDING THE DATE OF THE CAUSE. UNDER NO CIRCUMSTANCES WILL MELINGO BE LIABLE TO US FOR ANY LOST OR DEGRADED DATA, LOST REVENUE, LOST PROFITS, INABILITY TO USE THE SOFTWARE, COST OF PROCUREMENT OF SUBSTITUTE PRODUCTS, OR FOR SPECIAL, INDIRECT, CONSEQUENTIAL, INCIDENTAL OR PUNITIVE DAMAGES, HOWEVER CAUSED AND REGARDLESS OF THEORY OF LIABILITY, ARISING IN ANY WAY OUT OF THESE CONDITIONS. THIS LIMITATION SHALL APPLY EVEN IF MELINGO HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF THE THEORY OR THE NATURE OF THE CAUSE OF ACTION OR CLAIM. THE FOREGOING LIMITATION OF LIABILITY AND EXCLUSION OF CERTAIN DAMAGES SHALL APPLY REGARDLESS OF THE SUCCESS OR EFFECTIVENESS OF OTHER REMEDIES. MELINGO WOULD NOT BE ABLE TO PROVIDE THE SITE ON AN ECONOMIC BASIS WITHOUT SUCH LIMITATIONS AS ARE SET OUT IN THIS SECTION.</p></li>
                            <li><p>Melingo shall not be liable for any temporary unavailability of the Site due to deterioration, failure or malfunction of telecommunication lines, the software or the proper operation of the Site. In such cases, Melingo shall make its best efforts to resume the service as promptly as possible.</p></li>
                            <li>
                                <p>
                                    Without derogating from the above, Melingo shall have the right to terminate Our right of use of the Site in the event that We perform one of the activities detailed below, and to block Our access to the Site:
                                    <ol>
                                        <li><p>Submitted false details (for example the IP address) while signing up for the Site services.</p></li>
                                        <li><p>Copied, distributed, advertised, transferred or made any other use contrary to these conditions, of the contents of the Site and/or of the details and files included in this Site, without obtaining the prior written consent of Melingo.</p></li>
                                        <li><p>Performed  any act that is contrary to these conditions.</p></li>
                                    </ol>
                                </p>
                            </li>
                            <li><p>We agree to defend, indemnify and hold harmless Melingo (and any of its parents, subsidiaries, affiliates, employees, agents, third party content providers, or licensors, and their respective directors, officers, employees, and agents) from and against all claims, liability, and expenses, including attorneys' fees and legal fees and costs, arising out of Our use of the Site or our breach of any provision of these conditions or any relevant legal provision. We acknowledge that Melingo reserves the right, in its sole discretion and at its own expense, to assume the exclusive defense and control of any matter that is subject to indemnification by Us. We will cooperate as fully as reasonably required in the defense of any claim.</p></li>
                            <li><p>Melingo shall have the right to terminate the services provided by the Site without prior notice or warning. Melingo shall not be liable for any damage caused to the user and/or to any third party as a result of such termination of services.</p></li>
                        </ol>
                    </p>
                    <p>We wish you a pleasant and productive use of the Rav Milim Site.</p>
                `
            }
        ]
    },
    search: {

    },
    not_found: {

    }
};