import About from './About';
import ItemsGrid from './ItemsGrid';
import Iframe from './Iframe';
import Html from './Html';
import Hubspot from './Hubspot';

export const contentType = {
    about: "about",
    items_grid: "items_grid",
    iframe: "iframe",
    html: "html",
    hubspot: "hubspot",
};

export const contentComponent = {
    about: About,
    items_grid: ItemsGrid,
    iframe: Iframe,
    html: Html,
    hubspot: Hubspot,
};