import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Href from '../../components/Href';
import Alert from '../../components/Alert';
import { alertType } from '../../components/Alert/alertType';
import OuterLayer from '../../containers/OuterLayer';
import { useLabels } from '../../hooks';
import { PageContext, UserContext, UserType, OutersContainerContext } from '../../contexts';
import styles from './User.module.scss';

const pageKey = "user";

const User = () => {
  const { getLabel, getLocalUrlByLanguage } = useLabels();
  const { setPageKey } = useContext(PageContext);
  const { userType, hasPermissions } = useContext(UserContext);
  const { appLayoutTitleContainer } = useContext(OutersContainerContext);
  const navigate = useNavigate();
  const alertRef = useRef();

  useEffect(() => {
    if(!(userType === UserType.privet || userType === UserType.edu || userType === UserType.external)) {
      navigate(getLocalUrlByLanguage(`/`));
    }
  }, [getLocalUrlByLanguage, navigate, userType]);

  useEffect(() => {
    setPageKey(pageKey);
  }, [setPageKey]);

  const onSignoutHandle = useCallback(() => {
    if (alertRef.current) {
      alertRef.current.show({
          type: alertType.sign_out
    });
  }
  }, []);

  const onCancelSubscriptionHandle = useCallback(() => {
    
  }, []);

  
  return <>
    <OuterLayer container={appLayoutTitleContainer} TagWrapper='span'>{getLabel("user_page_title")}</OuterLayer>
    <div className={styles.container}>
      {(userType === UserType.privet || userType === UserType.external) && <Href className={styles.button} to={getLocalUrlByLanguage('/personal_information')}>{getLabel('user_page_editing_personal_details')}</Href>}
      {(userType === UserType.privet || userType === UserType.external) && <Href className={styles.button} to={getLocalUrlByLanguage('/change_password')}>{getLabel('user_page_change_password')}</Href>}
      <Href className={styles.button} to={getLocalUrlByLanguage('/contact_us')}>{getLabel('user_page_write_to_us')}</Href>
      <button className={styles.button} onClick={onSignoutHandle}>{getLabel('user_page_signout')}</button>
      {!hasPermissions && (userType === UserType.privet || userType === UserType.external) && <Href className={styles.button} to={getLocalUrlByLanguage('/purchase')}>{getLabel('user_page_purchase')}</Href>}
      {hasPermissions && userType === UserType.privet && <button className={`${styles.button} ${styles.cancel_subscription_button}`} onClick={onCancelSubscriptionHandle}>{getLabel('user_page_request_cancel_subscription')}</button>}
    </div>
    <Alert ref={alertRef} />
  </>;
};

export default User;
