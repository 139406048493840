/* eslint-disable import/no-anonymous-default-export */
import { navigationItemType, showForDeviceType, showForUserType, showForSiteType } from '../../hooks';
import { alertType } from '../../components/Alert/alertType';

export default [
    {
        label: "Word Search",
        path: "/en/",
        show_for_users: [showForUserType.has_permissions],
        type: navigationItemType.link,
    },     
    {
        label: "Word Search",
        show_for_users: [showForUserType.has_not_permissions],
        type: navigationItemType.alert,
        alert_data: {
            type: alertType.sign_up,
        },
    },     
    {
        label: "Nakdan Morfix",
        path: "https://nakdan.morfix.co.il/",
        type: navigationItemType.link,
    },  
    {
        label: "Personal Zone",
        path: "/en/user",
        show_for_users: [showForUserType.external, showForUserType.privet],
        type: navigationItemType.link,
    },  
    {
        label: "Personal Zone",
        show_for_users: [showForUserType.guest],
        show_for_sites: [showForSiteType.main],
        type: navigationItemType.alert,
        alert_data: {
            type: alertType.sign_up,
        },
    },  
    {
        label:  "Terms of Use",
        path: "/en/license",
        type: navigationItemType.link,
    }, 
    {
        label:  "Contact us",
        path: "/en/contact_us",
        type: navigationItemType.link,
    }, 
    {
        label: "Morfix",
        path: "https://www.morfix.co.il/en/",
        type: navigationItemType.link,
    }, 
    {
        label: "Crosswords Puzzle",
        path: "/en/crossword",
        show_for_users: [showForUserType.has_permissions],
        type: navigationItemType.link,
    }, 
    {
        label: "Crosswords Puzzle",
        show_for_users: [showForUserType.has_not_permissions],
        type: navigationItemType.alert,
        alert_data: {
            type: alertType.sign_up,
        },
    }, 
    {
        label:  "Numbers to Text",
        path: "/en/numbers",
        show_for_users: [showForUserType.has_permissions],
        type: navigationItemType.link,
    },   
    {
        label:  "Numbers to Text",
        show_for_users: [showForUserType.has_not_permissions],
        type: navigationItemType.alert,
        alert_data: {
            type: alertType.sign_up,
        },
    },   
    {
        label: "About",
        path: "/en/about",
        type: navigationItemType.link,
    },   
    {
        label:  "Editing Guidelines",
        path: "https://www.ravmilim.co.il/adv/general/index.htm",
        type: navigationItemType.link,
    },   
    {
        label:  "Blog",
        path: "https://blog.ravmilim.co.il/",
        type: navigationItemType.link,
    },    
    {
        label:  "Help",
        path: "/en/help",
        type: navigationItemType.link,
    },    
];