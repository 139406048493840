import React from 'react';
import Lottie from 'react-lottie';
import * as animationData from './pre_loader.json'
import styles from './Preloader.module.scss';

const Preloader = ({}) => {
    
    return <div className={styles.preloader}>
        <Lottie 
            options={{
                loop: true,
                autoplay: true, 
                animationData: animationData
            }}
        />
    </div>;
};
  
export default Preloader;