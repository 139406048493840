import React, { useContext, useEffect, useMemo } from 'react';
import OuterLayer from '../../containers/OuterLayer';
import { PageContext, OutersContainerContext } from '../../contexts';
import { useDataPerDevice, useLabels } from '../../hooks';
import { contentComponent } from './contentType';
import styles from './Dynamic.module.scss';

const Dynamic = ({
  pageKey = '', 
  title, 
  title_mobile, 
  title_pc, 
  sub_title, 
  sub_title_mobile, 
  sub_title_pc, 
  content,
  content_mobile,
  content_pc,
  direction,
  direction_mobile,
  direction_pc,
}) => {
  const { setPageKey } = useContext(PageContext);
  const { appLayoutTitleContainer, appLayoutSubTitleContainer } = useContext(OutersContainerContext);
  const { getDataPerDeviceView } = useDataPerDevice();
  const { getLabel} = useLabels();

  useEffect(() => {
    setPageKey(pageKey);
  }, [pageKey, setPageKey]);

  const pageTitle = useMemo(() => {
    return getDataPerDeviceView(title_mobile, title_pc, title);
  }, [getDataPerDeviceView, title_mobile, title_pc, title]);

  const pageSubTitle = useMemo(() => {
    return getDataPerDeviceView(sub_title_mobile, sub_title_pc, sub_title);
  }, [getDataPerDeviceView, sub_title_mobile, sub_title_pc, sub_title]);

  const pageContent = useMemo(() => {
    return getDataPerDeviceView(content_mobile, content_pc, content) || [];
  }, [getDataPerDeviceView, content_mobile, content_pc, content]);

  const directionData = useMemo(() => {
    return getDataPerDeviceView(direction_mobile, direction_pc, direction) || getLabel('direction');
  }, [getDataPerDeviceView, direction_mobile, direction_pc, direction, getLabel]);

  return <>
    {pageTitle && <OuterLayer container={appLayoutTitleContainer} TagWrapper='span'>{pageTitle}</OuterLayer>}
    {pageSubTitle && <OuterLayer container={appLayoutSubTitleContainer} dangerouslySetInnerHTML={{ __html: pageSubTitle}} />}
    <div className={styles.container} data-dynamic_page_direction={directionData}>
      {pageContent.map((content, index) => {
        if (!content) {
          return null;
        }

        const contentType = getDataPerDeviceView(content.type_mobile, content.type_pc, content.type);
        const Component = contentType && contentComponent[contentType];

        return Component && <div 
          key={`dynamic_content_${contentType}--${index}`}
          className={`${styles.content} ${styles[`content_${contentType}`]}`
        }>
          <Component {...content} />
        </div>
      })}
    </div>
  </>;
};

export default Dynamic;
