import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import WordExplain, { formatWordExplain } from '../../components/WordExplain';
import Tabs from '../../components/Tabs';
import { useLabels } from '../../hooks';
import { removeAllHtmlTags } from '../../utils/helpers';
import wordExplainStyles from '../../components/WordExplain/WordExplain.module.scss';
import styles from './Search.module.scss';

const FullAnalyzeData = ({data, searchStr}) => {
    const tubsList = useRef(["hebrewExplain", "thesaurus", "idioms", "rhyme", "translate", "grammar"]);
    const [ selectedTabIndex, setSelectedTabIndex ] = useState(0);
    const { getLabel, getLocalUrlByLanguage } = useLabels();

    useEffect(() => {
        setSelectedTabIndex(0);
    }, [data]);

    const onTabClickHandle = useCallback(({index}) => {
        setSelectedTabIndex(index);
    }, []);

    const content = useMemo(() => {
        if (!data) {
            return null;
        }

        const contentType = tubsList.current[selectedTabIndex];
        const contentData = data && data[contentType];

        const formatWordLink = (templet, id) => {
            const text = formatWordExplain(templet);
            const newSearchStr = removeAllHtmlTags(text);
            const href = getLocalUrlByLanguage(`/search/${encodeURIComponent(newSearchStr)}/${encodeURIComponent(newSearchStr)}/${id}`);
            return `<a href="${href}" class="${wordExplainStyles.link}">${text}</a>`;
        };

        if (selectedTabIndex === 0) {
            if (!(contentData && contentData.explanation && contentData.explanation.length > 0)) {
                if(data && data["idioms"] && data["idioms"].idioms && data["idioms"].idioms.length > 0) {
                    return "(הסבר למילה זו מופיע רק בצירופים)";
                }
                return "לא נמצאה הסבר";
            }

            const explanation = contentData.explanation;

            const createItem = (item, groupIndex, itemIndex, showNum) => {
                return <>
                    <div>{itemIndex === 0 && showNum ? <span>{item.num}. </span>: ""}<WordExplain explain={item.explain} /></div>
                    <ul className={styles.full_analyze_explanation_example}>
                        {(item.examples || []).map((example, exampleIndex) => <li key={`explanation--${groupIndex}--${itemIndex}--${exampleIndex}`}>{example}</li>)}
                    </ul>
                </>;
            }

            const createGroup = (group, groupIndex, showNum) => {
                return group.items.map((item, itemIndex) => {
                    return <div key={`explanation_group_item--${groupIndex}--${itemIndex}`} className={styles.full_analyze_explanation}>{createItem(item, groupIndex, itemIndex, showNum)}</div>;
                });
            }

            const groups = explanation.reduce((acor, item) => {
                if (item.num === "0") {
                    if (acor.group.length > 0) {
                        acor.group[acor.group.length - 1].items.push(item);
                        return acor;
                    }

                    if (!acor.group0) {
                        acor.group0 = {key: item.num, items: []};
                    }
                    acor.group0.items.push(item);
                    return acor;
                }

                if (!(item.num in acor.keys)) {
                    acor.keys[item.num] = acor.group.length;
                    acor.group.push({key: item.num, items: []});
                    acor.count++;
                }

                acor.group[acor.keys[item.num]].items.push(item);

                return acor;
            }, {keys: {}, group: [], count: 0, group0: null});

            const showNum = groups.count > 1;

            const showFullExplanationTitle = contentData.inputWordEntry.replace(/-/g, " ").split(' ').filter(w => !!w).length > 1 && 
                !(data["grammar"] && data["grammar"].analysis && data["grammar"].analysis.length > 0);

            return <>
                {showFullExplanationTitle && <div className={styles.full_analyze_explanation_group}>
                    <span>הסבר מלא לצירוף</span>&nbsp;
                    <span><b>{contentData.inputWordEntry}</b></span>&nbsp;
                    <span>{contentData.partOfSpeech}</span>
                </div>}
                {groups.group0 && <div>{createGroup(groups.group0, 0, false)}</div>}
                <div>
                    {groups.group.map((item, index) => {
                        return <div key={`explanation_group--${index}`} className={styles.full_analyze_explanation_group}>{createGroup(item, index, showNum)}</div>;
                    })}
                </div>
            </>;
        }

        if (selectedTabIndex === 1) {
            if (!(contentData && contentData.group && contentData.group.length > 0)) {
                return "לא נמצאו מילים נרדפות";
            }

            return contentData.group.map((group, index) => {
                const sections = (group || []).map(section => {
                    return (section || []).map(item => formatWordLink(item.inputWordEntry, item.idnum)).join(', ');
                }).join('; ');

                return <div key={`thesaurus--${index}`}>
                    <div><WordExplain explain={sections} /></div>
                    {contentData.group.length - 1 > index && <br />}
                </div>;
            });
        }

        if (selectedTabIndex === 2) {
            if (!(contentData && contentData.idioms && contentData.idioms.length > 0)) {
                return "לא נמצאו צירופים";
            }

            return <>
                <div>צירופים עם המילה <b>{contentData.inputWordEntry}</b> {contentData.partToSpeech} {contentData.idioms.length === 1 ? "(צירוף אחד)" : `(${contentData.idioms.length} צירופים)`}</div><br />
                <div className={styles.full_analyze_data_list}>{contentData.idioms.map((item, index) => {
                    return <div key={`idioms--${item.idnum}`}><WordExplain explain={formatWordLink(item.inputWordEntry, item.idnum)} /></div>;
                })}</div>
            </>
        }

        if (selectedTabIndex === 3) {
            const aPartialListWord = "(...רשימה חלקית)";
            const aPartialListLabel = "(רשימה חלקית)";

            const bestRhymesList = contentData && contentData.bestRhymesList && contentData.bestRhymesList.length > 0 && contentData.bestRhymesList.filter(item => item.word !== contentData.inputWordEntry && item.word !== aPartialListWord);
            const properRhymesList = contentData && contentData.properRhymesList && contentData.properRhymesList.length > 0 && contentData.properRhymesList.filter(item => item.word !== contentData.inputWordEntry && item.word !== aPartialListWord);
            const leastRhymesList = contentData && contentData.leastRhymesList && contentData.leastRhymesList.length > 0 && contentData.leastRhymesList.filter(item => item.word !== contentData.inputWordEntry && item.word !== aPartialListWord);

            if (!(bestRhymesList || properRhymesList || leastRhymesList)) {
                return "לא נמצאה חריזה";
            }

            return <>
                {(bestRhymesList && bestRhymesList.length > 0) && <div>
                    <div><b>חרוז משובח:</b></div>
                    <div>{bestRhymesList.map(item => item.word).join(', ')}</div>
                    {contentData.bestRhymesList[contentData.bestRhymesList.length - 1].word === aPartialListWord && <div>{aPartialListLabel}</div>}
                    <br />
                </div>}
                {(properRhymesList && properRhymesList.length > 0) && <div>
                    <div><b>חרוז ראוי:</b></div>
                    <div>{properRhymesList.map(item => item.word).join(', ')}</div>
                    {contentData.properRhymesList[contentData.properRhymesList.length - 1].word === aPartialListWord && <div>{aPartialListLabel}</div>}
                    <br />
                </div>}
                {(leastRhymesList && leastRhymesList.length > 0) && <div>
                    <div><b>חרוז עובר:</b></div>
                    <div>{leastRhymesList.map(item => item.word).join(', ')}</div>
                    {contentData.leastRhymesList[contentData.leastRhymesList.length - 1].word === aPartialListWord && <div>{aPartialListLabel}</div>}
                </div>}
            </>;
        }

        if (selectedTabIndex === 4) {
            if (!(contentData && contentData.translation)) {
                return "לא נמצא תרגום";
            }

            return <div style={{direction: "ltr", textAlign: "left"}}>{contentData.translation}</div>;
        }

        if (selectedTabIndex === 5) {
            if (!(contentData && (
                (contentData.analysis && contentData.analysis.length > 0) || 
                contentData.inflections
            ))) {
                return "אין ניתוח דקדוקי";
            }

            const createAnalysisTable = (analysis, index) => {
                const columnsNames = {
                    pleneText: "כתיב מלא תקני", 
                    partOfSpeech: "חלק דיבור", 
                    stem: "בניין", 
                    tense: "זמן", 
                    person: "גוף", 
                    gender: "מין", 
                    number: "מספר", 
                    prefix: "אותיות שימוש", 
                    root: "שורש", 
                    pronoun: "כינוי",
                    construct: "סמיכות",
                };

                return <div key={`analysis_tabel--${index}`}>
                    <div>
                        <b>{index ? `${index}. ` : ''}<span>{analysis.vocalization}</span> = <span>{analysis.parsing}</span></b>
                    </div>
                    <table>
                        <tbody>
                            {["pleneText", "partOfSpeech", "stem", "tense", "person", "gender", "number", "pronoun", "prefix", "root", "construct"].map(row => {
                                if (["stem", "tense", "person", "pronoun"].indexOf(row) >= 0 && !analysis[row]) {
                                    return null;
                                }

                                if (
                                    ["gender", "number"].indexOf(row) >= 0 && 
                                    [
                                        "שם פרטי", 
                                        "תואר הפועל", 
                                        "מילת יחס", 
                                        "מילת קריאה", 
                                        "מילת קישור", 
                                        "מילת שאלה", 
                                        "מילת הסבר", 
                                        "תחילית", 
                                        "סופית"
                                    ].indexOf(analysis["partOfSpeech"]) >= 0 && 
                                    !analysis[row]
                                ) {
                                    return null;
                                }

                                return <tr key={`analysis_tabel_row--${row}`}>
                                    <td>{columnsNames[row]}</td>
                                    <td>{analysis[row]}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                    {analysis.remark && <div><span>{analysis.remark}</span></div>}
                </div>;
            };

            return <div className={styles.full_analyze_analysis_grammar}>
                <div><span>המילה שהוקלדה:</span> <b>{searchStr}</b></div>
                <div><span>הערך המילוני:</span> <b>{contentData.inputWordEntry}</b></div>
                <br/>
                {contentData.inflections && <div>
                    <div><u><span>נטיות:</span></u></div>
                    <div><b>{contentData.inflections}</b></div>
                    <br />
                </div>}
                {(contentData.analysis && contentData.analysis.length > 0) && <div>
                    <div><u><span>{contentData.analysis.length === 1 ? "הניתוח הדקדוקי:" : "הניתוחים הדקדוקיים:"}</span></u></div>
                    <div className={styles.full_analyze_analysis_table}>
                        {contentData.analysis.map((analysis, index) => createAnalysisTable(analysis, contentData.analysis.length > 1 ? index + 1 : 0))}
                    </div>
                </div>}
            </div>;
        }

    }, [data, selectedTabIndex, searchStr, getLocalUrlByLanguage]);

    return <div className={styles.full_analyze_data}>
        <Tabs onTabClick={onTabClickHandle} tabs={tubsList.current.map((tab, index) => {
            return {
                key: tab,
                name: getLabel(`full_analyze_data_tab_${tab}`),
                isSelected: index === selectedTabIndex
            }
        })} />
        <div className={`${styles.full_analyze_data_content} ${styles['full_analyze_data_content--' + tubsList.current[selectedTabIndex]]}`}>
            {content}
        </div>
    </div>;
};
  
export default FullAnalyzeData;