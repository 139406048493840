import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import FormInputField from '../FormInputField';
import Button, { buttonType } from '../../components/Button';
import Loader from '../Loader';
import { useLabels } from '../../hooks';
import { UserContext } from '../../contexts';
import { formatString, getTextWithoutHtml } from '../../utils/helpers';
import styles from './LoginForm.module.scss';

const ForgotPassword = ({
    setConfirmPasswordText = () => {},
    setConfirmPasswordUsername = () => {},
    setViewState = () => {},
}) => {
    const [ showLoader, setShowLoader ] = useState(false);
    const [ error, setError ] = useState();
    const { getLabel, getCognitoApiError } = useLabels();
    const { sendResetPasswordCode } = useContext(UserContext);
    const isActive = useRef(false);
    const loginResetUsernameRef = useRef();

    useEffect(() => {
        isActive.current = true;

        return () => {
            isActive.current = false;
        };
    }, []);

    const onSendMessageButtonClickHandle = useCallback((event) => {
        event.preventDefault();
        let focusField = null;

        if (!loginResetUsernameRef.current.validation()) {
            focusField = focusField || loginResetUsernameRef.current;
        }

        if (focusField) {
            focusField.focus();
            return;
        }

        setError(null);
        setShowLoader(true);

        const username = loginResetUsernameRef.current.getValue();
        setConfirmPasswordUsername(username);
        sendResetPasswordCode(username).then((data) => {
            if (isActive.current) {
                const confirmPasswordText = formatString(
                    getLabel(
                        data.DeliveryMedium === 'EMAIL' ? 
                        'login_form_confirm_password_text_by_email' : 
                        'login_form_confirm_password_text_by_phone'
                    ), 
                    `<span class="${styles.ltr_data}">${getTextWithoutHtml(data.Destination)}</span>`
                );
                setConfirmPasswordText(confirmPasswordText);
                setViewState('confirm_password');
                setShowLoader(false);
            }
        }).catch(error => {
            if (isActive.current) {
                setError(error);
                setShowLoader(false);
            }
        });
    }, [setConfirmPasswordUsername, sendResetPasswordCode, getLabel, setConfirmPasswordText, setViewState]);

    const forgotPasswordForm = useMemo(() => {
        return <>
            <div className={styles.login}>
                <div className={styles.content}>
                    <div>
                        <div className={styles.title}>{getLabel('login_form_forgot_password_title')}</div>
                        <div className={styles.text}>{getLabel('login_form_forgot_password_text')}</div>
                        <form className={styles.fields} onSubmit={onSendMessageButtonClickHandle}>
                            <FormInputField label={getLabel('login_form_username_field')} placeholder={getLabel('login_form_username_field')} ref={loginResetUsernameRef} type="email" required={true} />
                            <button hidden={true}></button>
                        </form>
                    </div>
                    <div className={styles.buttons}>
                        <div>
                            <div className={styles.error} dangerouslySetInnerHTML={{ __html: getCognitoApiError(error)}} />
                            <Button type={buttonType.premium} className={styles.main_button} onClick={onSendMessageButtonClickHandle} >{getLabel('login_form_forgot_password_send_message_button')}</Button>
                        </div>
                    </div>
                </div>
            </div>
            {showLoader && <Loader />}
        </>;
    }, [error, getCognitoApiError, getLabel, onSendMessageButtonClickHandle, showLoader]);


    return forgotPasswordForm;
};

export default ForgotPassword;