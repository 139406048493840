
import React, { useCallback, useContext } from 'react';
import Button, { buttonType } from '../../Button';
import { useLabels } from '../../../hooks';
import { UserContext } from '../../../contexts';
import styles from './SignOut.module.scss';

const SignOut = ({onClose}) => {
    const { getLabel } = useLabels();
    const { signOut } =  useContext(UserContext);

    const onCloseButtonClickHandle = useCallback(() => {
      onClose();
    }, [onClose]);
    
    const onSignOutButtonClickHandle = useCallback(() => {
      signOut();
      onClose();
    }, [onClose, signOut]);

    return <div className={styles.container}>
        <div className={styles.title} dangerouslySetInnerHTML={{ __html: getLabel('alert_signout_title')}} />
        <div className={styles.buttons}>
            <Button type={buttonType.premium} className={styles.close_button} onClick={onCloseButtonClickHandle} >{getLabel('alert_signout_close_button')}</Button>
            <Button type={buttonType.link} onClick={onSignOutButtonClickHandle} >{getLabel('alert_signout_sign_out_button')}</Button>
        </div>
    </div>;
};
  
export default SignOut;