/* eslint-disable import/no-anonymous-default-export */

export default {
    lang: "he",
    direction: "rtl",

    main_site: "https://www.ravmilim.com/",
    org_site: "https://org.ravmilim.com/",

    footer_social_phone_url: "tel:+972036070927",
    footer_social_instagram_url: "footer_social_instagram_url",
    footer_social_facebook_url: "https://facebook.com/ravmilim",
    footer_social_phone_text: "טלפון",
    footer_social_facebook_text: "פייסבוק",

    keyboard_icon_title: "מקלדת",
    popup_close_icon_title: "סגירת חלון",
    sidebar_close_icon_title: "סגירת תפריט",
    search_icon_title: "חיפוש",

    landscape_text: "<div>יש לסובב את הטלפון למצב</div><div>מאונך כדי לצפות באתר</div><div>בצורה מיטבית</div>",

    search: "חיפוש",
    new_search: "חיפוש חדש",
    clean: "נקה",

    sidebar_signout_button: "יציאה מהחשבון",

    //#region alert SignUp
    alert_signup_title: `
        <div>אנחנו יודעים כמה חשוב לך לדעת יותר,</div>
        <div>אבל אפשרות זו מיועדת למנויים בלבד</div>
    `,
    alert_signup_title_privet: "נראה שפג תוקפו של המינוי שלך",
    alert_signup_content: `
        <div>זה הזמן להצטרף לאלפי המנויים שלנו, וליהנות</div>
        <div>מכל האפשרויות של רב-מילים</div>
    `,
    alert_signup_content_privet: `
        <div>אל דאגה, ניתן לחדש אותו בקלות, </div>
        <div>ולהמשיך ליהנות מרב-מילים.</div>
        <div>נציגינו ייצרו עימך קשר לחידוש המינוי.</div>
    `,
    alert_signup_subscription_button: "לחידוש המינוי",
    alert_signup_log_in_button: "יש לי מינוי!",
    alert_signup_later_button: "לא כרגע",
    //#endregion alert SignUp

    //#region alert SignOut
    alert_signout_title: "<div>רגע לפני שניפרד,</div><div>בטוח שברצונך להתנתק?</div>",
    alert_signout_close_button: "רוצה להישאר",
    alert_signout_sign_out_button: "רוצה להתנתק",
    //#endregion alert SignOut
    
    //#region cognito api errors
    cognito_api_error_NotAuthorizedException: "אימייל או סיסמה שגויים",
    cognito_api_error_LimitExceededException: "חרגת ממספר הניסיונות המותרים, נא לנסות שוב לאחר זמן מה",
    cognito_api_error_CodeMismatchException: "קוד האימות שגוי, נא לנסות שוב",
    cognito_api_error_ExpiredCodeException: "קוד אימות פג תוקף, ביקשו קוד חדש",
    cognito_api_error_UserNotConfirmedException: "המשתמש לא אושר.",
    cognito_api_error_SignUpNotAuthorizedException: "לקוח ניסה לכתוב מאפיין לא מורשה",
    cognito_api_error_UsernameExistsException: "כתובת המייל כבר רשומה",
    cognito_api_error_ChangePasswordNotAuthorizedException: "הסיסמה הישנה שגויה",
    cognito_api_error_CognitoOrgEmailDomainError: "יש להזין כתובת מייל ארגונית בלבד",
    cognito_api_error_CognitoOrgNotExistError: "קוד ארגון שגוי",
    cognito_api_error_AliasExistsException: "חשבון עם האימייל הנתון כבר קיים",
    cognito_api_error_InvalidParameterException: "מספר טלפון לא חוקי",
    cognito_api_error_CognitoUserLimitLoginError: "עברת על מכסה יומית לכמות התחברויות, אנא נסה מאוחר יותר.",
    //#endregion cognito api errors

    //#region FormInputField
    form_input_field_required_error: "שדה חובה",
    form_input_field_minlength_error: "לפחות {0} תווים",
    form_input_field_email_error: 'כתובת דוא"ל לא חוקית',
    form_input_field_tel_error: "מספר טלפון לא חוקי",
    form_input_field_password_length_error: "הסיסמה חייבת להכיל לפחות {0} תווים",
    form_input_field_password_lowercase_error: "הסיסמה חייבת להכיל אותיות קטנות",
    form_input_field_password_uppercase_error: "הסיסמה חייבת להכיל אות גדולה",
    form_input_field_password_numeric_error: "הסיסמה חייבת להכיל מספר",
    form_input_field_password_symbol_error: "הסיסמה חייבת להכיל תו מיוחד או רווח",
    form_input_field_password_he_error: "הסיסמה לא יכולה להכיל אותיות בעברית",
    form_input_field_password_space_error: "אסור שהסיסמה תכיל רווח מוביל או נגרר",
    //#endregion FormInputField

    //#region Login form
    login_form_title: "התחברות",
    login_form_username_field: "אימייל",
    login_form_username_org_field: "מייל ארגוני",
    login_form_password_field: "סיסמה",
    login_form_forgot_password_button: "שכחת סיסמה?",
    login_form_login_button: "התחברות",
    login_form_signup_button: "אין לך חשבון?",
    login_form_signup_with_orgcode_button: "הרשמה באמצעות קוד ארגון",
    login_form_edu_button: "התחברות דרך משרד החינוך",
    login_form_org_button: "התחברות ארגונית",
    login_form_main_site_button: "מעבר לאתר הרגיל",
    login_form_forgot_password_title: "איפוס סיסמה",
    login_form_forgot_password_text: 'יש להזין למטה את כתובת הדוא"ל שלך, ותישלח אליו הודעה לאיפוס הסיסמה',
    login_form_forgot_password_send_message_button: "שליחה",
    login_form_confirm_password_title: "בחירת סיסמה חדשה",
    login_form_confirm_password_text_by_email: 'שלחנו קוד איפוס סיסמה בדוא"ל אל {0}. יש להזין אותו למטה כדי לאפס את הסיסמה שלך.',
    login_form_confirm_password_text_by_phone: "שלחנו קוד איפוס סיסמה בטלפון אל {0}. יש להזין אותו למטה כדי לאפס את הסיסמה שלך.",
    login_form_confirm_code_field: "קוד",
    login_form_confirm_new_password_field: "סיסמה",
    login_form_confirm_new_password_field_placeholder: "יש להזין כאן את הסיסמה החדשה",
    login_form_confirm_again_new_password_field: "אימות סיסמה",
    login_form_confirm_again_new_password_field_placeholder: "יש להזין שוב את הסיסמה שבחרת",
    login_form_confirm_change_password_button: "שמירה והתחברות",
    login_form_confirm_new_password_match_error: "הסיסמאות חייבות להיות זהות",
    login_form_new_password_required_password_title: "בחירת סיסמה חדשה",
    login_form_new_password_required_code_field: "קוד",
    login_form_new_password_required_new_password_field: "סיסמה",
    login_form_new_password_required_new_password_field_placeholder: "יש להזין כאן את הסיסמה החדשה",
    login_form_new_password_required_again_new_password_field: "אימות סיסמה",
    login_form_new_password_required_again_new_password_field_placeholder: "יש להזין שוב את הסיסמה שבחרת",
    login_form_new_password_required_change_password_button: "שמירה והתחברות",
    login_form_new_password_required_new_password_match_error: "הסיסמאות חייבות להיות זהות",
    login_form_orgcode_title: "קוד ארגון",
    login_form_orgcode_text: `יש להזין למטה את קוד הארגון שלך, ומייד לאחר מכן להירשם לאתר עם המייל הארגוני שלך.`,
    login_form_orgcode_code_field: "קוד ארגון",
    login_form_orgcode_get_org_data_button: "הבא",
    //#endregion Login form

    //#region Signup form
    signup_form_first_name_field: "שם פרטי",
    signup_form_last_name_field: "שם משפחה",
    signup_form_email_field: "אימייל",
    signup_form_email_org_field: "מייל ארגוני",
    signup_form_phone_field: "טלפון",
    signup_form_password_field: "סיסמה",
    signup_form_password_field_placeholder: "8 תווים לפחות",
    signup_form_again_password_field: "אישור סיסמה",
    signup_form_again_password_field_placeholder: "נא להזין שוב את הסיסמה שבחרת",
    signup_form_agree_to_terms_use_text: "אני מסכים/ה",
    signup_form_terms_use_text: "לתנאי השימוש.",
    signup_form_create_account_button: "יצירת חשבון ומעבר לתשלום",
    signup_form_create_account_org_button: "הבא",
    signup_form_cancel_button: "ביטול",
    signup_form_terms_use_error: "כדי להמשיך צריך להסכים לתנאי השימוש",
    signup_form_confirm_account_title: "אשר את חשבונך",
    signup_form_confirm_account_by_email: 'שלחנו קוד בדוא"ל אל {0}. יש להזין אותו למטה כדי לאשר את חשבונך.',
    signup_form_confirm_account_by_phone: 'שלחנו קוד בטלפון אל {0}. יש להזין אותו למטה כדי לאשר את חשבונך.',
    signup_form_confirm_account_code_field: "קוד אימות",
    signup_form_confirm_account_confirm_button: "אישור חשבון",
    signup_form_confirm_account_resend_code_button: "לא קיבלת קוד? שלחו אליי קוד חדש",
    //#endregion Signup form

    //#region PersonalInformation form
    personalinformation_form_first_name_field: "שם פרטי",
    personalinformation_form_last_name_field: "שם משפחה",
    personalinformation_form_email_field: "אימייל",
    personalinformation_form_email_org_field: "מייל ארגוני",
    personalinformation_form_phone_field: "טלפון",
    personalinformation_form_save_button: "שמירה",
    personalinformation_form_cancel_button: "ביטול",
    personalinformation_form_success_alert_text: "הפרטים עודכנו בהצלחה",
    personalinformation_form_success_alert_button: "סגירה",
    //#endregion PersonalInformation form

    //#region ChangePassword form
    changepassword_form_old_password_field: "סיסמה ישנה",
    changepassword_form_new_password_field: "סיסמה חדשה",
    changepassword_form_confirm_password_field: "אישור סיסמה חדשה",
    changepassword_form_confirm_new_password_match_error: "הסיסמאות חייבות להיות זהות",
    changepassword_form_save_button: "שמירה",
    changepassword_form_cancel_button: "ביטול",
    changepassword_form_success_alert_text: "הסיסמה עודכנה בהצלחה!",
    changepassword_form_success_alert_button: "סיום",
    //#endregion ChangePassword form

    //#region home page
    home_page_opening_text: "המילון העברי העדכני והמקיף ביותר ברשת",
    sign_up_button: "ליצירת מינוי >",
    log_in_button: "כניסה לחשבון קיים",
    home_page_subscription_button: "לחידוש המינוי",
    home_page_topic_1: "תרגום",
    home_page_topic_2: "דקדוק",
    home_page_topic_3: "תשבצון",
    home_page_topic_4: "מספרים",
    home_page_topic_5: "הסבר",
    home_page_topic_6: "נרדפות",
    home_page_topic_7: "צירופים",
    home_page_topic_8: "חריזה",
    home_page_about_title: "מהו רב-מילים",
    home_page_about_text_1: `
        <div>אתר רב–מילים הוא המילון היחיד הכולל ניתוח מורפולוגי מלא, המאפשר למצוא כל מילה עברית, בכל צורת נטייה ובכל צורת כתיב.</div>
        <div>המילון מתעדכן באופן שוטף, נוספות לו מילים שמחדשת האקדמיה ללשון העברית, בצד מונחים לועזיים חדשים ואף חידושי סלנג.</div>
    `,
    home_page_about_text_2: `
        <div>הערכים במילון כוללים הסבר לכל המשמעויות של הערך שחיפשנו, דוגמאות שימוש, מילים נרדפות, צירופים שבהם מופיע הערך, מילים המתחרזות עם הערך שחיפשנו, תרגום לאנגלית וניתוח דקדוקי.</div>
    `,
    home_page_customers_title: "בין לקוחותינו",
    //#endregion home page

    //#region purchase page
    purchase_page_subscription_corporate_title: "מינוי ארגוני",
    purchase_page_subscription_corporate_text: "מיועד לארגונים בעלי 10 עובדים ומעלה. מלאו את פרטי הארגון וניצור עימכם קשר.",
    purchase_page_subscription_corporate_button: "למילוי פרטים >",
    purchase_page_subscription_yearly_title: "מינוי שנתי",
    purchase_page_subscription_yearly_price: "<span>₪</span>419",
    purchase_page_subscription_yearly_text: "תשלום חד-פעמי",
    purchase_page_subscription_yearly_label: "הנמכר ביותר",
    purchase_page_subscription_monthly_title: "מינוי חודשי",
    purchase_page_subscription_monthly_price: "<span>₪</span>35 לחודש",
    purchase_page_subscription_monthly_text: "תשלום חודשי מתחדש",
    purchase_page_subscription_buy_button: "ליצירת מינוי >",
    purchase_page_signup_title: "הרשמה לאתר",
    purchase_page_signup_org_title: "הרשמה למשתמשים ארגוניים",
    purchase_page_subscription_org_title: "הרשמה לאתר",
    purchase_page_subscription_org_submitted_title: "נעים להכיר",
    purchase_page_subscription_org_submitted_sub_title: "שלום, {0}!",
    purchase_page_subscription_org_submitted_text: `
        <div>נציגינו ייצרו עימך קשר טלפוני בימים הקרובים כדי</div>
        <div>להשלים את תהליך הרכישה.</div>
    `,
    purchase_page_subscription_org_submitted_home_button: "לדף הבית",
    //#endregion purchase page

    //#region user page
    user_page_title: "אזור אישי",
    user_page_editing_personal_details: "עריכת פרטים אישיים",
    user_page_change_password: "שינוי סיסמה",
    user_page_write_to_us: "כתבו לנו",
    user_page_signout: "התנתקות",
    user_page_request_cancel_subscription: "בקשה לביטול מנוי",
    user_page_purchase: "לחידוש המינוי",
    //#region user page 

    //#region personal_information page
    personalinformation_page_title: "פרטים אישיים",
    //#region personal_information page 

    //#region change_password page
    changepassword_page_title: "שינוי סיסמה",
    //#region change_password page 

    //#region search page 
    page_search_title: "חיפוש מילה",
    page_search_sub_title: "<span>כל מילה, ביטוי, צירוף או מספר בלחיצת כפתור. &nbsp;</span><span>יש להקליד בשורת החיפוש את המילה המבוקשת.</span>",
    word_search_input_placeholder: "חיפוש מילה",

    number_of_search_results: "התקבלו {0} תוצאות:",
    number_of_search_1_results: "התקבלה תוצאה אחת:",
    search_results_correction_title: "לא מצאנו את מה שחיפשת",
    search_results_number_correction_title: "אין תרגום לביטוי המספרי המבוקש",
    search_results_exceeding_daily_searches_limit: "חריגה ממגבלת החיפושים היומית.",
    search_results_correction_sub_title: "אולי התכוונת ל:",
    search_results_correction_contact_us_1: "המילה שחיפשת לא קיימת?",
    search_results_correction_contact_us_2: "כתבו לנו",

    full_analyze_data_tab_hebrewExplain: "הסבר",
    full_analyze_data_tab_thesaurus: "נרדפות",
    full_analyze_data_tab_idioms: "צירופים",
    full_analyze_data_tab_rhyme: "חריזה",
    full_analyze_data_tab_translate: "תרגום",
    full_analyze_data_tab_grammar: "דקדוק",
    full_analyze_other_words_title: "תוצאות חיפוש נוספות:",
    //#endregion search page

    //#region crossword page 
    page_crossword_title: "תשבצון",
    page_crossword_sub_title: "<span>בחרו את אורך המילה והקלידו את האותיות </span><br /><span>הידועות לכם (לפחות אות אחת).</span>",

    crossword_number_of_letters: "מספר אותיות",
    crossword_number_of_letters_mobile: "מספר אותיות",
    crossword_number_of_letters_option: "{0} אותיות",
    crossword_only_hebrew_letters_alert_message: "נא למלא אותיות בעברית בלבד",
    crossword_enter_at_least_one_letter_alert_message: "נא למלא לפחות אות אחת במילה לחיפוש",
    crossword_leave_at_least_one_empty_letter_alert_message: "נא להשאיר לפחות אות אחת ריקה במילה לחיפוש",
    crossword_no_results_message: "לא נמצאו מילים מתאימות",
    //#endregion crossword page
};