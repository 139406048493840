import React, { useCallback, useState, useContext, useEffect, useRef } from 'react';
import OuterLayer from '../../containers/OuterLayer';
import TouchController from '../../containers/TouchController';
import Icon from '../Icon';
import { DeviceContext, OutersContainerContext } from '../../contexts';
import { useLabels } from '../../hooks';
import styles from './DragBox.module.scss';

const DragBox = ({children, className = '', startPosition, onClose = () => {}, ...props}) => {
    const [ position, setPosition ] = useState();
    const [ movePosition, setMovePosition ] = useState({x: 0, y: 0});
    const { viewWidth, viewHeight } = useContext(DeviceContext);
    const { outerLayersContainer } = useContext(OutersContainerContext);
    const { getLabel } = useLabels();
    const boxContainerRef = useRef();

    useEffect(() => {
        setPosition(startPosition);
    }, [startPosition, setPosition]);

    const updateBoxPosition = useCallback(() => {
        if(boxContainerRef && boxContainerRef.current) {
            const boxRect = boxContainerRef.current.getBoundingClientRect();
            setPosition(normalizedPosition({
                x: boxRect.left,
                y: boxRect.top
            }, viewWidth, viewHeight));
        }
    }, [viewWidth, viewHeight]);

    useEffect(() => {
        setTimeout(updateBoxPosition, 1);
    }, [updateBoxPosition]);

    const setBoxContainerRef = useCallback((ref) => {
        boxContainerRef.current = ref;
        setTimeout(updateBoxPosition, 1);
    }, [updateBoxPosition]);

    const onTouchMoveHandle = useCallback(({controllerData}) => {
        setMovePosition({
            x: controllerData.deltaX,
            y: controllerData.deltaY,
        });
    }, []);

    const onTouchEndHandle = useCallback(({controllerData}) => {
        setMovePosition({
            x: 0,
            y: 0,
        });

        setPosition(normalizedPosition({
            x: position.x + controllerData.deltaX,
            y: position.y + controllerData.deltaY,
        }, viewWidth, viewHeight));
    }, [position, viewWidth, viewHeight]);

    const normalizedPosition = (position, viewWidth, viewHeight) => {
        const boxRect = boxContainerRef.current.getBoundingClientRect();
        const boxWidth = boxRect.width, boxHeight = boxRect.height;
        let x = position.x, y = position.y;

        if (boxWidth > viewWidth) {
            if (position.x >= 0) {
                x = 0;
            }
            else if (viewWidth > position.x + boxWidth) {
                x = viewWidth - boxWidth
            }
        }
        else {
            if (position.x <= 0) {
                x = 0;
            }
            else if (position.x + boxWidth > viewWidth) {
                x = viewWidth - boxWidth;
            }
        }

        if (boxHeight > viewHeight) {
            y = 0;
        }
        else {
            if (position.y <= 0) {
                y = 0;
            }
            else if (position.y + boxHeight > viewHeight) {
                y = viewHeight - boxHeight;
            }
        }
        
        return { x, y };
    };

    const calcPosition = () => {
        return {
            left: (position.x + movePosition.x) + 'px',
            top: (position.y + movePosition.y) + 'px',
        }
    };
  
    return position && <OuterLayer container={outerLayersContainer} className={`${styles.container} ${className}`} {...props} style={calcPosition()}>
        <div ref={setBoxContainerRef}>
            <div className={styles.tools}>
                <TouchController className={styles.touch_controller} onTouchMove={onTouchMoveHandle} onTouchEnd={onTouchEndHandle} />
                <button className={styles.close} onClick={onClose}><Icon className={styles.close_icon} icon="close" title={getLabel('popup_close_icon_title')} /></button>
            </div>
            <div>{children}</div>
        </div>
    </OuterLayer>;
};
  
export default DragBox;