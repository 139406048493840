import React from 'react';
import { Link } from 'react-router-dom';
import { isFullUrl } from '../../utils/helpers';
import styles from './Href.module.scss';

export default function Href({ children, className = '', target = '_top', to, onClick, ...rest }) {
  const handleOnClick = (event) => {
    if(onClick){
      onClick(event);
    }
  }
  
  return (
    <>
      {target === '_top' && !isFullUrl(to) && (to+'').substring(0, 11) !== "javascript:" ? (
        <Link className={`${styles.link} ${className}`} to={to} {...rest} onClick={handleOnClick}>
          {children}
        </Link>
      ) : (
        <a className={`${styles.link} ${className}`} href={to} target={target} {...rest} onClick={handleOnClick}>
          {children}
        </a>
      )}
    </>
  );
}
