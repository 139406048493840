import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Selector from '../../components/Selector';
import KeyboardInput from '../../components/KeyboardInput';
import Alert from '../../components/Alert';
import Loader from '../../components/Loader';
import Href from '../../components/Href';
import Button, { buttonType } from '../../components/Button';
import OuterLayer from '../../containers/OuterLayer';
import { useLabels } from '../../hooks';
import { useMissingLetters } from '../../api';
import { PageContext, OutersContainerContext, DeviceContext, UserContext } from '../../contexts';
import { formatString } from '../../utils/helpers'; 
import styles from './Crossword.module.scss';

export const pageKey = "crossword";

const Crossword = () => {
  const alertRef = useRef();
  const [ numberOfLetters, setNumberOfLetters ] = useState(4);
  const [ letters, setLetters ] = useState(['', '', '', '']);
  const [ searchWord, setSearchWord ] = useState();
  const { word } = useParams();
  const navigate = useNavigate();
  const { hasPermissions } = useContext(UserContext);
  const { setPageKey } = useContext(PageContext);
  const { isMobileView, isMobile } = useContext(DeviceContext);
  const { 
    appLayoutTitleContainer,
    appLayoutSubTitleContainer 
  } = useContext(OutersContainerContext);
  const { getLabel, getLocalUrlByLanguage } = useLabels();
  const { loading: missingLettersLoading, error: missingLettersError, data: missingLettersData } = useMissingLetters(searchWord);

  useEffect(() => {
    if(!hasPermissions) {
      navigate(getLocalUrlByLanguage(`/`));
    }
  }, [hasPermissions, getLocalUrlByLanguage, navigate]);

  useEffect(() => {
    setPageKey(pageKey);
  }, [setPageKey]);

  useEffect(() => {
    let inputWord = (word || "____").replace(/[^א-ת_]+/g, '').slice(0, 16);

    while (inputWord.length < 2) {
      inputWord += "_";
    }

    setNumberOfLetters(inputWord.length);
    setLetters(inputWord.split('').map(letter => letter === "_" ? "" : letter));
    setSearchWord(inputWord.replace(/[_]/g, '').length > 0 ? inputWord.replace(/[_]/g, '?') : null);
  }, [word]);

  const showAlert = useCallback((message) => {
    if(message && alertRef.current) {
      alertRef.current.show({content: message});
    }
  }, []);

  const searchHandle = useCallback(() => {
    if (searchWord) {
      return;
    }

    const lettersCount = letters.join('').length;

    if (lettersCount === 0) {

      showAlert(getLabel('crossword_enter_at_least_one_letter_alert_message'));
      return;
    } 

    if (lettersCount === letters.length) {
      showAlert(getLabel('crossword_leave_at_least_one_empty_letter_alert_message'));
      return;
    } 

    const newSearchWord = letters.map(letter => letter || "_").join('');

    if (newSearchWord === word) {
      setSearchWord(letters.map(letter => letter || "?").join(''));
      return;
    }

    navigate(getLocalUrlByLanguage(`/crossword/${encodeURIComponent(newSearchWord)}`));
  }, [letters, searchWord, word, navigate, getLocalUrlByLanguage, getLabel, showAlert]);

  const onSubmitHandle = useCallback((event) => {
    searchHandle();
    event.preventDefault();
  }, [searchHandle]);

  const onNewSearchHandle = useCallback(() => {
    setSearchWord(null);
  }, []);

  const onLengthSelectorChangeHandle = useCallback(({target}) => {
    const numberOfLetters = Number(target.value);

    let newLetters = []; // [...letters].slice(0, numberOfLetters);
    while (newLetters.length < numberOfLetters) {
      newLetters.push('');
    }

    setNumberOfLetters(numberOfLetters);
    setLetters(newLetters);
  }, []);

  const updateLetters = useCallback((key, input) => {
    if (["{enter}", "enter"].indexOf(key.toLowerCase()) >= 0) {
      searchHandle();
      return;
    }

    let newLetters = [...letters];
    const functionIndex = [
      " ",          // functionIndex 0
      "{space}",    // functionIndex 1
      "_",          // functionIndex 2
      "{bksp}",     // functionIndex 3
      "backspace",  // functionIndex 4
      "{tab}",      // functionIndex 5
      "arrowleft",  // functionIndex 6
      "arrowdown",  // functionIndex 7
      "arrowright", // functionIndex 8
      "arrowup"     // functionIndex 9
    ].indexOf(key.toLowerCase());

    if (/^[א-ת]$/.test(key) || key === '' || functionIndex >= 0) {
      const inputs = Array.from(input.parentNode.childNodes) || [];
      const inputIndex = inputs.indexOf(input);

      if (inputIndex >= 0) {
        let goToNext = true;
        let goToPrev = false;

        switch (functionIndex) {
          case 0:
          case 1:
          case 2:
            newLetters[inputIndex] = '';
            break;
          case 3:
          case 4:
            newLetters[inputIndex] = '';
            goToNext = false;
            goToPrev = true;
            break;
          case 5:
          case 6:
          case 7:
            break;
          case 8:
          case 9:
            goToNext = false;
            goToPrev = true;
            break;
          default:
            newLetters[inputIndex] = key;
        }

        if (goToNext && inputs.length > inputIndex + 1) {
          setTimeout(() => {inputs[inputIndex + 1].focus()}, 1);
        }
        else if (goToPrev && inputIndex > 0 && inputs.length > 0) {
          setTimeout(() => {inputs[inputIndex - 1].focus()}, 1);
        }
        else {
          setTimeout(() => {input.focus();}, 1);
        }
      }
    }
    else if (key && key.length === 1) {
      showAlert(getLabel('crossword_only_hebrew_letters_alert_message'));
    }

    setLetters(newLetters);
  }, [letters, searchHandle, getLabel, showAlert]);

  const onKeyDownHandle = useCallback(({key, target}) => {
    updateLetters(key, target);
  }, [updateLetters]);

  const onMobileInputHandle = useCallback(({target, nativeEvent}) => {
    if (nativeEvent && 'data' in nativeEvent && 'inputType' in nativeEvent) {
      if (nativeEvent.data) {
        updateLetters(nativeEvent.data, target);
        return;
      }
      if (nativeEvent.inputType && nativeEvent.inputType.toLowerCase() === 'deletecontentbackward') {
        updateLetters('backspace', target);
        return;
      }
    }

    const inputs = Array.from(target.parentNode.childNodes) || [];
    const inputIndex = inputs.indexOf(target);
    if (inputIndex >= 0 && inputIndex < letters.length) {
      updateLetters((target.value || '').replace(letters[inputIndex]).slice(0, 1), target);
    }
  }, [letters, updateLetters]);

  const onMobileKeyUp = useCallback(({keyCode, target}) => {
    if (keyCode === 13) {
      updateLetters("enter", target);
    }
  }, [updateLetters]);

  const onKeyboardPressHandle = useCallback((button, input) => {
    updateLetters(button, input);
  }, [updateLetters]);

  const onChangeHandle = useCallback((event) => {
    event.preventDefault();
  }, []);

  const onCleanHandle = useCallback(() => {
    setLetters(letters.map(letter => ''));
  }, [letters]);

 /*
  const onLettersInputFocusHandle = useCallback(({target}) => {
    if (isMobile) {
      setTimeout(() => {
        var rect = target.getBoundingClientRect();
        document.querySelector("#app").scrollTop -= window.innerHeight - rect.height - 2 - rect.top;
      }, 100);
    }
  }, [isMobile]);
  */

  const lengthSelectorTitle = useMemo(() => {
    return isMobileView ? getLabel("crossword_number_of_letters_mobile") : getLabel("crossword_number_of_letters")
  }, [isMobileView, getLabel]);

  const options = useMemo(() => {
    return [2,3,4,5,6,7,8,9,10,11,12,13,14,15,16].map(option => {
      return {
        key: option,
        text: formatString(getLabel("crossword_number_of_letters_option"), option)
      }
    })
  }, [getLabel]);

  const inputEvent = useMemo(() => {
    return isMobile ? {
      onInput: onMobileInputHandle,
      onKeyUp: onMobileKeyUp
    } : {
      onKeyDown: onKeyDownHandle
    };
  }, [isMobile, onMobileInputHandle, onMobileKeyUp, onKeyDownHandle]);

  const lettersInput = useMemo(() => {
    return letters.map((letter, index) => <KeyboardInput 
      key={`letter_input--${index}`}
      className={styles.crossword_input_letter}
      onKeyboardPress={onKeyboardPressHandle} 
      onChange={onChangeHandle} 
      value={letter} 
      disabled={!!searchWord}
      placeholder={index + 1}
      {...inputEvent}
    />);
  }, [letters, inputEvent, searchWord, onKeyboardPressHandle, onChangeHandle]);

  const loader = useMemo(() => {
    return missingLettersLoading && <Loader />;
  }, [missingLettersLoading]);

  const results = useMemo(() => {
    const aPartialListWord = "רשימה חלקית (100 מילים ראשונות)";
    const aPartialListLabel = "רשימה חלקית (100 מילים ראשונות)";

    return missingLettersData && <div className={styles.results}>
      {missingLettersData.result && missingLettersData.result.map((result, index) => {
        if (index === missingLettersData.result.length - 1 && result.word === aPartialListWord) {
          return <div key={`result_word--${index}-${result.word}`} className={styles.partial_list}>{aPartialListLabel}</div>
        }

        if (
          index === missingLettersData.result.length - 1 ||
          (
            index === missingLettersData.result.length - 2 && 
            missingLettersData.result[missingLettersData.result.length - 1].word === aPartialListWord
          )
        ) {
          return <span key={`result_word--${index}-${result.word}`}>
            <Href 
              className={styles.result}
              to={getLocalUrlByLanguage(`/search/${encodeURIComponent(result.word)}`)}
              target="_blank"
            >{result.word}</Href>{" "}
          </span>;
        }

        return <span key={`result_word--${index}-${result.word}`}>
          <Href 
            className={styles.result}
            to={getLocalUrlByLanguage(`/search/${encodeURIComponent(result.word)}`)}
            target="_blank"
          >{result.word}</Href>{", "}
        </span>;
      })}
      {!(missingLettersData.result && missingLettersData.result.length > 0) && <div className={styles.no_results}>
        {getLabel('crossword_no_results_message')}
      </div>}
    </div>
  }, [missingLettersData, getLocalUrlByLanguage, getLabel]);

  if (searchWord) {
    return <>
      <OuterLayer container={appLayoutTitleContainer} TagWrapper='span'>{getLabel("page_crossword_title")}</OuterLayer>
      <OuterLayer container={appLayoutSubTitleContainer} dangerouslySetInnerHTML={{ __html: getLabel("page_crossword_sub_title")}} />
      <div className={styles.container}>
        <div className={styles.content}>
          <form className={styles.crossword_input} data-number_of_letters={numberOfLetters} onSubmit={onSubmitHandle}>{lettersInput}</form>
          {results}
        </div>
        <div className={styles.buttons}>
          <Button type={isMobileView ? buttonType.link : buttonType.premium} onClick={onNewSearchHandle} >{getLabel("new_search")}</Button>
        </div>
      </div>
      <Alert ref={alertRef} />
      {loader}
    </>;
  }

  return <>
    <OuterLayer container={appLayoutTitleContainer} TagWrapper='span'>{getLabel("page_crossword_title")}</OuterLayer>
    <OuterLayer container={appLayoutSubTitleContainer} dangerouslySetInnerHTML={{ __html: getLabel("page_crossword_sub_title")}} />
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.length_selector}>
          <div className={styles.length_selector_title}>{lengthSelectorTitle}</div>
          <Selector options={options} value={numberOfLetters} onChange={onLengthSelectorChangeHandle} />
        </div>
        <form className={styles.crossword_input} data-number_of_letters={numberOfLetters} onSubmit={onSubmitHandle}>{lettersInput}</form>
      </div>
      <div className={styles.buttons}>
        <Button type={buttonType.premium} onClick={onSubmitHandle} >{getLabel("search")}</Button>
        <Button type={buttonType.link} onClick={onCleanHandle} >{getLabel("clean")}</Button>
      </div>
      {results}
    </div>
    <Alert ref={alertRef} />
    {loader}
  </>;
};

export default Crossword;
