import React, { useMemo, useState, useCallback } from 'react';
import HubspotForm from 'react-hubspot-form';
import Loader from '../../../components/Loader';
import { useDataPerDevice } from '../../../hooks';
import { scrollTop } from '../../../utils/helpers';
import styles from './Hubspot.module.scss';

const Hubspot = ({
  data, data_mobile, data_pc, 
  width, width_mobile, width_pc, 
  height, height_mobile, height_pc, 
  className = '',
  onFormSubmitted = () => {},
}) => {
  const [loading, setLoading] = useState(true);
  const { getDataPerDeviceView } = useDataPerDevice();

  const hubspotData = useMemo(() => {
    return getDataPerDeviceView(data_mobile, data_pc, data) || '';
  }, [getDataPerDeviceView, data_mobile, data_pc, data]);

  const hubspotWidth = useMemo(() => {
    return getDataPerDeviceView(width_mobile, width_pc, width) || '';
  }, [getDataPerDeviceView, width_mobile, width_pc, width]);

  const hubspotHeight = useMemo(() => {
    return getDataPerDeviceView(height_mobile, height_pc, height) || '';
  }, [getDataPerDeviceView, height_mobile, height_pc, height]);

  const onReadyHandler = useCallback(() => {
    setLoading(false);
  }, []);

  const onFormSubmittedHandle = useCallback((form, data) => {
    scrollTop();
    if(onFormSubmitted) {
      onFormSubmitted(form, data);
    }
  }, [onFormSubmitted]);

  return hubspotData && <>
    <div style={{width: hubspotWidth, height: hubspotHeight}} className={`${styles.hubspo} ${className}`} >
      <HubspotForm 
        {...hubspotData} 
        onReady={onReadyHandler}
        onFormSubmitted={onFormSubmittedHandle}
      />
    </div>
    {loading && <Loader />}
  </>;
};

export default Hubspot;
