import { DeviceProvider } from './device';
import { LanguageProvider } from './language';
import { PageProvider } from './page';
import { UserProvider } from './user';
import { ProxyProvider } from './proxy';
import { OutersContainerProvider } from './outersContainer';
import { KeyboardProvider } from './keyboard';

export const ProvidersWrapper = ({ children }) => {
    return [
        KeyboardProvider, 
        DeviceProvider, 
        PageProvider, 
        OutersContainerProvider,
        LanguageProvider, 
        UserProvider,
        ProxyProvider
    ].reduce((accumulator, ProviderItem) => { 
         return <ProviderItem>{accumulator}</ProviderItem>;
    }, children);
};