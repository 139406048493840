import { useCallback, useContext, useState, useEffect } from 'react';
import { LanguageContext } from '../contexts';
import { getTextWithoutHtml } from '../utils/helpers';
import { labels as labelsConfig } from '../config';

export const useLabels = () => {
    const { language } = useContext(LanguageContext);
    const [ labels, setLabels] = useState({});

    useEffect(() => {
        setLabels(labelsConfig[language] || {});
    }, [language]);

    const getLabel = useCallback((labelId) => {
        return (labels && labelId in labels) ? labels[labelId] : labelId;
    }, [labels]);

    const getCognitoApiError = useCallback((error) => {
        if (error) {
            if (error.code) {
                const labelKey = `cognito_api_error_${error.code}`;
                const label = getLabel(labelKey);
                if (labelKey !== label) {
                    return label;
                }
            }

            if (error.message) {
                return `<span style='direction: ltr;'>${getTextWithoutHtml(error.message)}</span>`;
            }
        }
        return '';
    }, [getLabel]);

    const getDataByDirection = useCallback((ifRtl, ifLtr) => {
        return getLabel("direction") === "rtl" ? ifRtl : ifLtr;
    }, [getLabel]);

    const getLocalUrlByLanguage = useCallback((url) => {
        return `${language === "he" ? '' : `/${language}`}${url}`;
    }, [language]);

    return {
        labels: labels,
        getLabel: getLabel,
        getCognitoApiError: getCognitoApiError,
        getDataByDirection: getDataByDirection,
        getLocalUrlByLanguage: getLocalUrlByLanguage,
    };
};