import React, { useCallback, useEffect, useRef, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import FormInputField from '../FormInputField';
import Button, { buttonType } from '../../components/Button';
import Href from '../Href';
import Loader from '../Loader';
import { useLabels } from '../../hooks';
import { UserContext, DeviceContext } from '../../contexts';
import styles from './SignupForm.module.scss';
import { env } from '../../config';

const Signup = () => {
    const [ showLoader, setShowLoader ] = useState(false);
    const [ error, setError ] = useState();
    const [ showTermsUseError, setShowTermsUseError ] = useState(false);
    const { getLabel, getLocalUrlByLanguage, getCognitoApiError} = useLabels();
    const { signUp, orgData } = useContext(UserContext);
    const { isMobileView } = useContext(DeviceContext);
    const navigate = useNavigate();
    const isActive = useRef(false);
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const passwordRef = useRef();
    const passwordAgainRef = useRef();
    const termsUseRef = useRef();

    useEffect(() => {
        isActive.current = true;

        return () => {
            isActive.current = false;
        };
    }, []);

    const onSignupButtonClickHandle = useCallback((event) => {
        event?.preventDefault();
        let focusField = null;

        if (!firstNameRef.current.validation()) {
            focusField = focusField || firstNameRef.current;
        }

        if (!lastNameRef.current.validation()) {
            focusField = focusField || lastNameRef.current;
        }

        if (!emailRef.current.validation()) {
            focusField = focusField || emailRef.current;
        }

        if (!phoneRef.current.validation()) {
            focusField = focusField || phoneRef.current;
        }

        if (!passwordRef.current.validation()) {
            focusField = focusField || passwordRef.current;
        }

        if (!passwordAgainRef.current.validation()) {
            focusField = focusField || passwordAgainRef.current;
        }

        if (!termsUseRef.current.checked) {
            focusField = focusField || termsUseRef.current;
        }
        setShowTermsUseError(!termsUseRef.current.checked);

        if (focusField) {
            focusField.focus();
            return;
        }

        setError(null);
        setShowLoader(true);

        const firstName = firstNameRef.current.getValue();
        const lastName = lastNameRef.current.getValue();
        const email = emailRef.current.getValue();
        const phone = phoneRef.current.getValue();
        const password = passwordRef.current.getValue();
        
        signUp({
            firstName,
            lastName,
            email,
            phone,
            password,
        }).then(() => {
            if (isActive.current) {
                setShowLoader(false);
            }
        }).catch(error => {
            if (isActive.current) {
                setError(error);
                setShowLoader(false);
            }
        });
    }, [signUp]);

    const onCancelButtonClickHandle = useCallback(() => {
        navigate(getLocalUrlByLanguage('/'));
    }, [getLocalUrlByLanguage, navigate]);

    const passwordAgainValidation = useCallback((password) => {
        return password === passwordRef.current.getValue() ? '' : getLabel('login_form_confirm_new_password_match_error');
    }, [getLabel]);


    return <>
        <div className={styles.signup}>
            <form className={styles.form} onSubmit={onSignupButtonClickHandle}>
                <FormInputField ref={firstNameRef} label={getLabel('signup_form_first_name_field')} placeholder={getLabel('signup_form_first_name_field')} required={true} />
                <FormInputField ref={lastNameRef} label={getLabel('signup_form_last_name_field')} placeholder={getLabel('signup_form_last_name_field')} required={true} />
                <FormInputField ref={emailRef} label={getLabel(env.REACT_APP_ORG_ENABLE() ? 'signup_form_email_org_field' : 'signup_form_email_field')} placeholder={env.REACT_APP_ORG_ENABLE() ? `yourname@${orgData ? orgData.domain : 'yourcompany.com'}` : getLabel('signup_form_email_field')} required={true} emailValidation={true} type="email" />
                <FormInputField ref={phoneRef} label={getLabel('signup_form_phone_field')} placeholder={getLabel('signup_form_phone_field')} required={true} telValidation={true} type="tel" />
                <FormInputField ref={passwordRef} label={getLabel('signup_form_password_field')} placeholder={getLabel('signup_form_password_field_placeholder')} passwordValidation={true} type="password" />
                <FormInputField ref={passwordAgainRef} label={getLabel('signup_form_again_password_field')} placeholder={getLabel('signup_form_again_password_field_placeholder')} validationPattern={passwordAgainValidation} type="password" />
                <div className={styles.terms_use}>
                    <div>
                        <label>
                            <input type='checkbox' ref={termsUseRef} onChange={(e)=>{setShowTermsUseError(!e.target.checked)}} />
                            <span>{getLabel('signup_form_agree_to_terms_use_text')}</span>
                        </label>&nbsp;
                        <Href to={getLocalUrlByLanguage('/license')} target="_blank">{getLabel('signup_form_terms_use_text')}</Href>
                    </div>
                    <div className={styles.error}>{showTermsUseError && getLabel('signup_form_terms_use_error')}</div>
                </div>
                <button hidden={true}></button>
            </form>
            <div className={styles.buttons}>
                <div className={styles.error} dangerouslySetInnerHTML={{ __html: getCognitoApiError(error)}} />
                <Button type={buttonType.premium} className={styles.button} onClick={onSignupButtonClickHandle} >{getLabel(env.REACT_APP_ORG_ENABLE() ? 'signup_form_create_account_org_button' : 'signup_form_create_account_button')}</Button>
                {isMobileView && <Button type={buttonType.link} className={`${styles.button} ${styles.cancel_button}`} onClick={onCancelButtonClickHandle} >{getLabel('signup_form_cancel_button')}</Button>}
            </div>
        </div>
        {showLoader && <Loader />}
    </>;
};

export default Signup;