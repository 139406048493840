import React, { useCallback, useMemo, useState } from 'react';
import Title from '../../../components/Title';
import { useDataPerDevice } from '../../../hooks';
import { scrollTop, getStickySectionSeparatorClientRect, getAppScrollTop } from '../../../utils/helpers';
import styles from './About.module.scss';

const About = ({topics, topics_mobile, topics_pc}) => {
  const [ selectedTopic, setSelectedTopic ] = useState(0);
  const { getDataPerDeviceView } = useDataPerDevice();

  const topicsList = useMemo(() => {
    return getDataPerDeviceView(topics_mobile, topics_pc, topics) || [];
  }, [getDataPerDeviceView, topics_mobile, topics_pc, topics]);

  const onTopicSelect = useCallback((index, target) => {
    const footer = document.querySelector('#footer');

    if(footer.style.display === 'none') {
      return;
    }

    setSelectedTopic(selectedTopic === index ? null : index);

    document.querySelector('#footer').style.display = 'none';
    setTimeout(() => {
      let top = getAppScrollTop() + target.closest(`.${styles.topic_title}`).getBoundingClientRect().top - getStickySectionSeparatorClientRect().top;
      scrollTop(top);

      setTimeout(() => {
        document.querySelector('#footer').style.display = '';
      }, 100);
    }, 750);
  }, [selectedTopic]);

  return <div className={styles.about}>
    {topicsList.map((topic, index) => {
      const title = getDataPerDeviceView(topic.title_mobile, topic.title_pc, topic.title) || '';
      const html = getDataPerDeviceView(topic.html_mobile, topic.html_pc, topic.html) || '';

      const titleComp = getDataPerDeviceView(
        <div className={styles.topic_title}><button onClick={({target}) => {onTopicSelect(index, target)}}>{title}</button></div>,
        <Title className={styles.topic_title} TagWrapper="div">{title && <span>{title}</span>}</Title>
      );

      const topicContent = getDataPerDeviceView(
        <div className={styles.topic_content}><div dangerouslySetInnerHTML={{ __html: html}} /></div>,
        <div className={styles.topic_content} dangerouslySetInnerHTML={{ __html: html}} />
      );

      return <div key={`about_topic--${index}`} className={`${styles.topic} ${index === selectedTopic ? styles.topic_selected : ''}`}> 
        {titleComp}
        {topicContent}
      </div>;
    })}
  </div>;
};

export default About;
