
import React, { useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Button, { buttonType } from '../../Button';
import { useLabels } from '../../../hooks';
import { UserContext, UserType, PageContext } from '../../../contexts';
import styles from './SignUp.module.scss';

const SignUp = ({onClose}) => {
    const { getLabel, getLocalUrlByLanguage } = useLabels();
    const { userType, orgData } = useContext(UserContext);
    const { pageKey } = useContext(PageContext);
    const navigate = useNavigate();

    const view = useMemo(() => {
      let view = 'signup';
      if (userType !== UserType.guest || (pageKey === "login" && orgData)) {
        view = 'subscription';
      }
      return view;
    }, [orgData, pageKey, userType]);

    const onSignUpButtonClickHandle = useCallback(() => {
      // window.open(`${getLabel('main_site')}purchase`, "_blank");
      navigate(getLocalUrlByLanguage(`/purchase`));
      onClose();
    }, [onClose, navigate, getLocalUrlByLanguage]);
    
    const onLogInButtonClickHandle = useCallback(() => {
      if (view === 'signup') {
        navigate(getLocalUrlByLanguage(`/login`));
      }
      onClose();
    }, [view, onClose, navigate, getLocalUrlByLanguage]);

    const title = useMemo(() => {
      let key = view === 'signup' ? 'alert_signup_title' : 'alert_signup_title_privet';
      return <div className={styles.title} dangerouslySetInnerHTML={{ __html: getLabel(key)}} />;
    }, [getLabel, view]);

    const content = useMemo(() => {
      let key = view === 'signup' ? 'alert_signup_content' : 'alert_signup_content_privet';
      return <div className={styles.content} dangerouslySetInnerHTML={{ __html: getLabel(key)}} />;
    }, [getLabel, view]);

    const signUpButton = useMemo(() => {
      let key = view === 'signup' ? 'sign_up_button' : 'alert_signup_subscription_button';
      return <Button type={buttonType.premium} className={styles.sign_up_button} onClick={onSignUpButtonClickHandle} >{getLabel(key)}</Button>;
    }, [getLabel, onSignUpButtonClickHandle, view]);

    const logInButton = useMemo(() => {
      let key = view === 'signup' ? 'alert_signup_log_in_button' : 'alert_signup_later_button';
      return <Button type={buttonType.link} onClick={onLogInButtonClickHandle} >{getLabel(key)}</Button>;
    }, [getLabel, onLogInButtonClickHandle, view]);

    return <div className={styles.container}>
      {title}
      {content}
      <div className={styles.buttons}>
          {signUpButton}
          {logInButton}
      </div>
    </div>;

    
};
  
export default SignUp;