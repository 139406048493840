import { useCallback, useEffect, useState, useContext } from 'react';
import { UserContext } from '../contexts';
import { fetch } from '../utils/helpers';

export const useFetch = (url) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState();
    const [requestData, setRequestData] = useState();
    const { getJwtToken, pingUserData } = useContext(UserContext);

    useEffect(() => {
        if (!(url && requestData)) {
            setLoading(false);
            setError(null);
            setData(null);
            return () => {};
        }

        let isActive = true;
        const jwtToken = getJwtToken();

        setLoading(true);

        fetch(`${url}${requestData.queryString}`, requestData.init, jwtToken).then(json => {
            if (isActive) {
                setData(json);
                setError(null);
                setLoading(false);
            }
        }).catch(error => {
            if (isActive) {
                setData(null);
                setError(error);
                setLoading(false);
                pingUserData();
            }
        });

        return () => {
            isActive = false;
        };
    }, [url, requestData, getJwtToken, pingUserData]);

    const post = useCallback((data, init = {}) => {
        setRequestData({
            init: {
                ...init,
                method: 'POST',
                body: JSON.stringify(data || {}),
            },
            queryString: '',
        });
    }, []);

    const get = useCallback((data, init = {}) => {
        let queryString = data ? Object.keys(data).map(key => {
          return `${key}=${encodeURIComponent(data[key])}`;
        }).join('&') : '';

        if (queryString) {
            queryString = `?${queryString}`;
        }

        setRequestData({
            init: {
                ...init,
                method: 'GET',
            },
            queryString: queryString,
        });
    }, []);

    const clean = useCallback(() => {
        setRequestData(null);
    }, []);

    return {
        loading,
        error,
        data,
        post,
        get,
        clean,
    };
};