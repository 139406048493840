import React, { forwardRef, useImperativeHandle, useRef, useCallback } from 'react';
import Icon from '../Icon';
import KeyboardInput from '../KeyboardInput';
import { useLabels } from '../../hooks';
import styles from './SearchInput.module.scss';

const SearchInput = forwardRef(({className = '', placeholder = '', autoFocus = false, onSearch = () => {}, ...props}, ref) => {
    const inputRef = useRef(null);
    const { getLabel } = useLabels();

    useImperativeHandle(ref, () => inputRef.current, []);

    const onSubmitHandle = useCallback((event) => {
        onSearch(inputRef.current.getValue());
        event.preventDefault();
    }, [onSearch]);

    const onKeyboardPressHandle = useCallback((button) => {
        if (inputRef.current && button === '{enter}') {
            onSearch(inputRef.current.getValue());
        }
    }, [onSearch]);
  
    return <>
        <div className={`${styles.form} ${className}`} {...props}>
            <form onSubmit={onSubmitHandle}>
                <KeyboardInput className={styles.input} placeholder={placeholder} ref={inputRef} onKeyboardPress={onKeyboardPressHandle} autoFocus={autoFocus} />
            </form>
            <div className={styles.tools}>
                <button className={styles.tool} onClick={onSubmitHandle}><Icon className={styles.tool_icon} icon="search" title={getLabel('search_icon_title')} /></button>
            </div>
        </div>
    </>;
});
  
export default SearchInput;