import React from 'react';
import { useLabels } from '../../hooks';
import styles from './Landscape.module.scss';
import animate from './animate.gif';

const Landscape = ({}) => {
    const { getLabel } = useLabels();
    
    return <div className={styles.landscape}>
        <img src={animate} width={150} height={150} alt='landscape_animate' />
        <div className={styles.text} dangerouslySetInnerHTML={{ __html: getLabel("landscape_text")}} />
    </div>;
};
  
export default Landscape;