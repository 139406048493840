import React, { useState, useEffect, useContext } from 'react';
import OuterLayer from '../../containers/OuterLayer'
import { OutersContainerContext } from '../../contexts';
import styles from './Sidebar.module.scss';

const Sidebar = ({children, isOpen = false, staticRender = false, side = "left", onClose = () => {} }) => {
    const [ showSidebar, setShowSidebar ] = useState(false);
    const { outerLayersContainer } = useContext(OutersContainerContext);

    useEffect(() => {
        let isActive = true;
        let timer;

        timer = setTimeout(() => {
            if (isActive) {
                setShowSidebar(isOpen);
            }
        }, 300);

        return () => {
            isActive = false;

            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [isOpen]);

    return (showSidebar || isOpen || staticRender) && <OuterLayer container={outerLayersContainer}>
        <div className={`${styles.sidebar_wrapper} ${showSidebar && isOpen ? styles.sidebar_wrapper_open : ''}`}>
            <div className={styles.sidebar_mask} onClick={onClose}></div>
            <div className={`${styles.sidebar_content} ${styles['sidebar_content_' + side]} ${(showSidebar || isOpen) ? styles.sidebar_content_transition : ''}`}>{children}</div>
        </div>
    </OuterLayer>;
};

export default Sidebar;
