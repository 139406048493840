import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import FormInputField from '../FormInputField';
import Button, { buttonType } from '../../components/Button';
import Loader from '../Loader';
import { useLabels } from '../../hooks';
import { UserContext } from '../../contexts';
import styles from './LoginForm.module.scss';

const NewPasswordRequired = ({
    newPasswordRequiredData,
}) => {
    const [ showLoader, setShowLoader ] = useState(false);
    const [ error, setError ] = useState();
    const { getLabel, getCognitoApiError } = useLabels();
    const { completeNewPasswordChallenge } = useContext(UserContext);
    const isActive = useRef(false);
    const newPasswordRequiredNewPasswordRef = useRef();
    const newPasswordRequiredAgainNewPasswordRef = useRef();

    useEffect(() => {
        isActive.current = true;

        return () => {
            isActive.current = false;
        };
    }, []);

    const onChangePasswordButtonClickHandle = useCallback((event) => {
        event.preventDefault();
        let focusField = null;

        if (!newPasswordRequiredNewPasswordRef.current.validation()) {
            focusField = focusField || newPasswordRequiredNewPasswordRef.current;
        }

        if (!newPasswordRequiredAgainNewPasswordRef.current.validation()) {
            focusField = focusField || newPasswordRequiredAgainNewPasswordRef.current;
        }

        if (focusField) {
            focusField.focus();
            return;
        }

        setError(null);
        setShowLoader(true);

        const newPassword = newPasswordRequiredNewPasswordRef.current.getValue();
        
        completeNewPasswordChallenge(newPasswordRequiredData.cognitoUser, newPassword, newPasswordRequiredData.userAttributes)
    }, [completeNewPasswordChallenge, newPasswordRequiredData.cognitoUser, newPasswordRequiredData.userAttributes]);

    const newPasswordRequiredAgainNewPasswordValidation = useCallback((password) => {
        return password === newPasswordRequiredNewPasswordRef.current.getValue() ? '' : getLabel('login_form_new_password_required_new_password_match_error');
    }, [getLabel]);

    const newPasswordRequiredForm = useMemo(() => {
        return <>
            <div className={styles.login}>
                <div className={styles.content}>
                    <div>
                        <div className={styles.title}>{getLabel('login_form_new_password_required_password_title')}</div>
                        <form className={styles.fields} onSubmit={onChangePasswordButtonClickHandle}>
                            <FormInputField label={getLabel('login_form_new_password_required_new_password_field')} placeholder={getLabel('login_form_new_password_required_new_password_field_placeholder')} ref={newPasswordRequiredNewPasswordRef} passwordValidation={true} type="password" />
                            <FormInputField label={getLabel('login_form_new_password_required_again_new_password_field')} placeholder={getLabel('login_form_new_password_required_again_new_password_field_placeholder')} ref={newPasswordRequiredAgainNewPasswordRef} validationPattern={newPasswordRequiredAgainNewPasswordValidation} type="password" />
                            <button hidden={true}></button>
                        </form>
                    </div>
                    <div className={styles.buttons}>
                        <div>
                            <div className={styles.error} dangerouslySetInnerHTML={{ __html: getCognitoApiError(error)}} />
                            <Button type={buttonType.premium} className={styles.main_button} onClick={onChangePasswordButtonClickHandle} >{getLabel('login_form_new_password_required_change_password_button')}</Button>
                        </div>
                    </div>
                </div>
            </div>
            {showLoader && <Loader />}
        </>;
    }, [newPasswordRequiredAgainNewPasswordValidation, error, getCognitoApiError, getLabel, onChangePasswordButtonClickHandle, showLoader]);


    return newPasswordRequiredForm;
};

export default NewPasswordRequired;