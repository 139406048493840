import React, { useEffect, useState, useCallback } from 'react';
import ResizeController from '../../containers/ResizeController';
import { useLabels } from '../../hooks';
import styles from './PageHeader.module.scss'; 

function PageHeader({children, className = '', ...props}) {
    const [ headerContentSize, setHeaderContentSize ] = useState();
    const [ pageHeaderStyle, setPageHeaderStyle ] = useState({});
    const { getDataByDirection } = useLabels();

    useEffect(() => {
        if(!headerContentSize) {
            return;
        }

        let p3_dx = 53, 
            p3_dy = 53, 
            p5_dx = 93, 
            p5_dy = 40;
        
        if (headerContentSize.width <= 375) {
            p3_dx = p3_dx * headerContentSize.width / 375; 
            p3_dy = p3_dy * headerContentSize.width / 375; 
            p5_dx = p5_dx * headerContentSize.width / 375; 
            p5_dy = p5_dy * headerContentSize.width / 375;
        }

        if (headerContentSize.height < p5_dy) {
            p5_dy = headerContentSize.height;
        }

        const p1 = [0, 0], 
                p2 = [headerContentSize.width, 0], 
                p3 = [headerContentSize.width, headerContentSize.height + p3_dy],
                p4 = [headerContentSize.width - p3_dx, headerContentSize.height],
                p5 = [p5_dx, headerContentSize.height],
                p6 = [0, headerContentSize.height - p5_dy];

        const p4_1 = [headerContentSize.width, headerContentSize.height + (p3_dy * 1 / 3)],
                p4_2 = [headerContentSize.width - (p5_dx * 1 / 3), headerContentSize.height],
                p6_1 = [p5_dx * 4 / 8, headerContentSize.height],
                p6_2 = [0, headerContentSize.height - (p5_dy * 4 / 8)];

        const halfHeaderContentWidth = headerContentSize.width / 2;

        const flipCoordinates = (x) => {
            return halfHeaderContentWidth + halfHeaderContentWidth - x;
        };

        const clipPath = getDataByDirection(
            `path(" \
                M ${p1[0]} ${p1[1]} \
                H ${p2[0]} \
                V ${p3[1]} \
                C ${p4_1[0]} ${p4_1[1]} ${p4_2[0]} ${p4_2[1]} ${p4[0]} ${p4[1]} \
                H ${p5[0]} \
                C ${p6_1[0]} ${p6_1[1]} ${p6_2[0]} ${p6_2[1]} ${p6[0]} ${p6[1]} \
                V ${p1[1]} \
                Z \
            ")`,
            `path(" \
                M ${flipCoordinates(p1[0])} ${p1[1]} \
                H ${flipCoordinates(p2[0])} \
                V ${p3[1]} \
                C ${flipCoordinates(p4_1[0])} ${p4_1[1]} ${flipCoordinates(p4_2[0])} ${p4_2[1]} ${flipCoordinates(p4[0])} ${p4[1]} \
                H ${flipCoordinates(p5[0])} \
                C ${flipCoordinates(p6_1[0])} ${p6_1[1]} ${flipCoordinates(p6_2[0])} ${p6_2[1]} ${flipCoordinates(p6[0])} ${p6[1]} \
                V ${p1[1]} \
                Z \
            ")`
        );

        setPageHeaderStyle({
            clipPath: clipPath,
            paddingBottom: `${p3_dy}px`,
            marginBottom: `-${p3_dy}px`,
        });
    }, [headerContentSize, getDataByDirection]);

    const onResizeHandler = useCallback((size) => {
        setHeaderContentSize(size);
    }, []);

    return children && <div style={pageHeaderStyle} className={`${styles.page_header} ${className}`} {...props}>
        <div className={styles.page_header_content}>
            <ResizeController onResize={onResizeHandler}>
                {children}
            </ResizeController>
        </div>
    </div>;
}

export default PageHeader;
