/* eslint-disable import/no-anonymous-default-export */

export default {
    lang: "en",
    direction: "ltr",

    main_site: "https://www.ravmilim.com/en/",
    org_site: "https://org.ravmilim.com/en/",

    footer_social_phone_url: "tel:+972036070927",
    footer_social_instagram_url: "footer_social_instagram_url",
    footer_social_facebook_url: "https://facebook.com/ravmilim",
    footer_social_phone_text: "Phone",
    footer_social_facebook_text: "Facebook",

    keyboard_icon_title: "Keyboard",
    popup_close_icon_title: "Close popup",
    sidebar_close_icon_title: "Close menu",
    search_icon_title: "search",

    landscape_text: "<div>Rotate your phone to</div><div>portrait mode to view</div><div>the site optimally</div>",

    search: "Search",
    new_search: "New Search",
    clean: "Clean",

    sidebar_signout_button: "Sign out",

    //#region alert SignUp
    alert_signup_title: "For subscribers only",
    alert_signup_title_privet: "Your subscription has expired",
    alert_signup_content: `
        <div>Rav-Milim dictionary is available only for</div>
        <div>subscribers. Join now and enjoy the leading</div>
        <div>online Hebrew dictionary.</div>
    `,
    alert_signup_content_privet: `
        <div>Don't worry, you can easily renew it and keep</div>
        <div>enjoying Rav-Milim. Our customer service will</div>
        <div>contact you to renew your subscription.</div>
    `,
    alert_signup_subscription_button: "Subscription Renewal",
    alert_signup_log_in_button: "Log in",
    alert_signup_later_button: "Later",
    //#endregion alert SignUp

    //#region alert SignOut
    alert_signout_title: "<div>Just before we say goodbye,</div><div>are you sure you want to log out?</div>",
    alert_signout_close_button: "Want To Stay",
    alert_signout_sign_out_button: "Want To Log Out",
    //#endregion alert SignOut

    //#region cognito api errors
    cognito_api_error_NotAuthorizedException: "Incorrect email or password.",
    cognito_api_error_LimitExceededException: "Attempt limit exceeded, please try after some time.",
    cognito_api_error_CodeMismatchException: "Invalid verification code provided, please try again.",
    cognito_api_error_ExpiredCodeException: "Invalid code provided, please request a code again.",
    cognito_api_error_UserNotConfirmedException: "User is not confirmed.",
    cognito_api_error_SignUpNotAuthorizedException: "A client attempted to write unauthorized attribute",
    cognito_api_error_UsernameExistsException: "User already exists",
    cognito_api_error_ChangePasswordNotAuthorizedException: "Incorrect password.",
    cognito_api_error_CognitoOrgEmailDomainError: "You may use only an address in your organization’s domain",
    cognito_api_error_CognitoOrgNotExistError: "Invalid organization code",
    cognito_api_error_AliasExistsException: "An account with the given email already exists",
    cognito_api_error_InvalidParameterException: "Invalid phone number format",
    cognito_api_error_CognitoUserLimitLoginError: "You have exceeded the daily limit for the number of connections, please try again later.",
    //#endregion cognito api errors

    //#region FormInputField
    form_input_field_required_error: "Required field",
    form_input_field_minlength_error: "At least {0} characters",
    form_input_field_email_error: "Invalid email address",
    form_input_field_tel_error: "Invalid phone number",
    form_input_field_password_length_error: "Password must contain at least {0} characters",
    form_input_field_password_lowercase_error: "Password must contain a lower case letter",
    form_input_field_password_uppercase_error: "Password must contain an upper case letter",
    form_input_field_password_numeric_error: "Password must contain a number",
    form_input_field_password_symbol_error: "Password must contain a special character\\space",
    form_input_field_password_he_error: "Password can’t contain Hebrew letters",
    form_input_field_password_space_error: "Password must not contain a leading\\trailing space",
    //#endregion FormInputField

    //#region Login form
    login_form_title: "Log in",
    login_form_username_field: "Email",
    login_form_username_org_field: "Organizational Email",
    login_form_password_field: "Password",
    login_form_forgot_password_button: "Forgot Password?",
    login_form_login_button: "Log in",
    login_form_signup_button: "Sign Up",
    login_form_signup_with_orgcode_button: "Sign up with Organizational Passcode",
    login_form_edu_button: "התחברות דרך משרד החינוך",
    login_form_org_button: "Organization login",
    login_form_main_site_button: "Go to the General Website",
    login_form_forgot_password_title: "Password Reset",
    login_form_forgot_password_text: "Enter your Email below and we will send a message to reset your password",
    login_form_forgot_password_send_message_button: "Send",
    login_form_confirm_password_title: "Choose A new Password",
    login_form_confirm_password_text_by_email: "We have sent a password reset code by email to {0}. Enter it below to reset your password.",
    login_form_confirm_password_text_by_phone: "We have sent a password reset code by phone to {0}. Enter it below to reset your password.",
    login_form_confirm_code_field: "Code",
    login_form_confirm_new_password_field: "Password",
    login_form_confirm_new_password_field_placeholder: "Enter a password here",
    login_form_confirm_again_new_password_field: "Enter Password Again",
    login_form_confirm_again_new_password_field_placeholder: "Enter password again",
    login_form_confirm_change_password_button: "Saving And Log in",
    login_form_confirm_new_password_match_error: "Passwords must match",
    login_form_orgcode_title: "Organizational Passcode",
    login_form_orgcode_text: "Please enter your organizational passcode below. Then sign up to our website with your organizational email address.",
    login_form_orgcode_code_field: "Organizational Passcode",
    login_form_orgcode_get_org_data_button: "Next",
    //#endregion Login form

    //#region Signup form
    signup_form_first_name_field: "First Name",
    signup_form_last_name_field: "Last Name",
    signup_form_email_field: "Email",
    signup_form_email_org_field: "Organizational Email",
    signup_form_phone_field: "Phone",
    signup_form_password_field: "Password",
    signup_form_password_field_placeholder: "At least 8 characters",
    signup_form_again_password_field: "Enter Password Again",
    signup_form_again_password_field_placeholder: "Enter password again",
    signup_form_agree_to_terms_use_text: "I agree to the",
    signup_form_terms_use_text: "terms of use.",
    signup_form_create_account_button: "Create Account",
    signup_form_create_account_org_button: "Next",
    signup_form_cancel_button: "Cancel",
    signup_form_terms_use_error: "To continue, you must agree to the terms of use.",
    signup_form_confirm_account_title: "Confirm Your Account",
    signup_form_confirm_account_by_email: "We have sent a code by email to {0}. Enter it below to confirm your account.",
    signup_form_confirm_account_by_phone: "We have sent a code by phone to {0}. Enter it below to confirm your account.",
    signup_form_confirm_account_code_field: "Verification Code",
    signup_form_confirm_account_confirm_button: "Confirm Account",
    signup_form_confirm_account_resend_code_button: "Didn't receive a code? Send a new code",
    //#endregion Signup form

    //#region PersonalInformation form
    personalinformation_form_first_name_field: "First Name",
    personalinformation_form_last_name_field: "Last Name",
    personalinformation_form_email_field: "Email",
    personalinformation_form_email_org_field: "Organizational Email",
    personalinformation_form_phone_field: "Phone",
    personalinformation_form_save_button: "Save",
    personalinformation_form_cancel_button: "Cancel",
    personalinformation_form_success_alert_text: "Details changed successfully",
    personalinformation_form_success_alert_button: "Close",
    //#endregion PersonalInformation form

    //#region ChangePassword form
    changepassword_form_old_password_field: "Old Password",
    changepassword_form_new_password_field: "New Password",
    changepassword_form_confirm_password_field: "Confirm New Password",
    changepassword_form_confirm_new_password_match_error: "Passwords must match",
    changepassword_form_save_button: "Save",
    changepassword_form_cancel_button: "Cancel",
    changepassword_form_success_alert_text: "Password changed successfully",
    changepassword_form_success_alert_button: "Close",
    //#endregion ChangePassword form

    //#region home page
    home_page_opening_text: "A truly amazing tool for the learner of Modern Hebrew",
    sign_up_button: "Sign Up",
    log_in_button: "Log in",
    home_page_subscription_button: "Subscription Renewal",
    home_page_topic_1: "Rhymes",
    home_page_topic_2: "Expressions",
    home_page_topic_3: "Translation",
    home_page_topic_4: "Grammar",
    home_page_topic_5: "Numbers",
    home_page_topic_6: "Crosswords Puzzle",
    home_page_topic_7: "Synonyms",
    home_page_topic_8: "Definition",
    home_page_about_title: "About Rav-Milim",
    home_page_about_text_1: `
        <div>Use of Rav-Milim requires no former linguistic or computer knowledge. By simply typing in a word, in any form, an array of linguistic information is displayed, including the word Nikud. The dictionary includes 70,000 main entries covering all strata and styles of language, each with all of its inflectional forms, with clear explanations, sources and usage examples.</div>
    `,
    home_page_about_text_2: `
        <div>The dictionary is constantly updated, ensuring that all new words authorized by the Academy of the Hebrew Language, as well as all the latest technological and professional innovations, from a vast array of fields, will always be found in the dictionary.</div>
    `,
    home_page_customers_title: "Our Customers",
    //#endregion home page

    //#region purchase page
    purchase_page_subscription_corporate_title: "Organizational subscription",
    purchase_page_subscription_corporate_text: "For organizations with more than 10 employees. Fill in the organization details, and a customer representative will contact you.",
    purchase_page_subscription_corporate_button: "Filling In Details >",
    purchase_page_subscription_yearly_title: "Yearly Plan",
    purchase_page_subscription_yearly_price: "419<span>₪</span>",
    purchase_page_subscription_yearly_text: "One-time payment",
    purchase_page_subscription_yearly_label: "Most Popular",
    purchase_page_subscription_monthly_title: "Monthly Plan",
    purchase_page_subscription_monthly_price: "35<span>₪</span> per month",
    purchase_page_subscription_monthly_text: "Monthly recurring payment",
    purchase_page_subscription_buy_button: "Sign Up >",
    purchase_page_signup_title: "Registeration",
    purchase_page_signup_org_title: "Registeration",
    purchase_page_subscription_org_title: "Registration",
    purchase_page_subscription_org_submitted_title: "<span>Nice to</span>&nbsp;<span>meet you</span>",
    purchase_page_subscription_org_submitted_sub_title: "Hello, {0}!",
    purchase_page_subscription_org_submitted_text: `
        <div>Our customer service will contact you</div>
        <div>by phone in the next few days to</div>
        <div>complete the purchase process.</div>
    `,
    purchase_page_subscription_org_submitted_home_button: "Home Page",
    //#endregion purchase page

    //#region user page
    user_page_title: "Personal Zone",
    user_page_editing_personal_details: "Editing Personal Details",
    user_page_change_password: "Change Password",
    user_page_write_to_us: "Contact us",
    user_page_signout: "Sign out",
    user_page_request_cancel_subscription: "Request To Cancel Subscription",
    user_page_purchase: "Subscription Renewal",
    //#region user page 

    //#region personal_information page
    personalinformation_page_title: "Personal Details",
    //#region personal_information page 

    //#region change_password page
    changepassword_page_title: "Change Password",
    //#region change_password page 

    //#region search page 
    page_search_title: "Word Search",
    page_search_sub_title: "Look up a word, phrase, date or number",
    word_search_input_placeholder: "Word Search",

    number_of_search_results: "{0} results were received:",
    number_of_search_1_results: "1 result was received:",
    search_results_correction_title: "No results found for the word you've entered.",
    search_results_number_correction_title: "There is no translation for the requested numerical expression.",
    search_results_exceeding_daily_searches_limit: "Exceeding daily searches limit.",
    search_results_correction_sub_title: "Did you mean:",
    search_results_correction_contact_us_1: "Missing word?",
    search_results_correction_contact_us_2: "Contact us",

    full_analyze_data_tab_hebrewExplain: "Definition",
    full_analyze_data_tab_thesaurus: "Synonyms",
    full_analyze_data_tab_idioms: "Expressions",
    full_analyze_data_tab_rhyme: "Rhymes",
    full_analyze_data_tab_translate: "Translation",
    full_analyze_data_tab_grammar: "Grammar",
    full_analyze_other_words_title: "Other results:",
    //#endregion search page

    //#region crossword page
    page_crossword_title: "Crosswords Puzzle",
    page_crossword_sub_title: "<span>Select word length and enter the word's </span><br /><span>known letters (at least one letter)</span>",

    crossword_number_of_letters: "Number of letters in requested word:",
    crossword_number_of_letters_mobile: "Letters",
    crossword_number_of_letters_option: "{0} Lettes",
    crossword_only_hebrew_letters_alert_message: "Please enter hebrew letters only",
    crossword_enter_at_least_one_letter_alert_message: "Please enter at least one letter in searched term",
    crossword_leave_at_least_one_empty_letter_alert_message: "Please leave at least one empty box in searched term",
    crossword_no_results_message: "No suitable words found",
    //#endregion crossword page
};