import { useEffect, useState } from 'react';
import { useFetch } from './useFetch';
import { env } from '../config'

export const analyzeTextResultTypes = {
    match: "Match",
    suggestions: "Suggestions",
};

export const useAnalyzeText = (text) => {
    const [loading, setLoading] = useState(false);
    const {loading: fetchLoading, error, data, post, clean} = useFetch(`${env.REACT_APP_BASS_RAVMILIM_API_URL()}/AnalyzeText`);

    useEffect(() => {
        if (text) {
            post({text});
        }
        else {
            clean();
        }
    }, [text, post, clean]);

    useEffect(() => {
        let isActive = true;

        if (!fetchLoading) {
            setTimeout(() => {
                if (isActive) {
                    setLoading(false);
                }
            }, 500);
        }
        else {
            setLoading(true);
        }

        return () => {
            isActive = false;
        };
    }, [fetchLoading]);

    return {
        loading,
        error,
        data,
    };
};