import React, { useMemo, useState } from 'react';
import Login from './login';
import ForgotPassword from './forgotPassword';
import ConfirmPassword from './confirmPassword';
import NewPasswordRequired from './newPasswordRequired';
import SetOrg from './setOrg';

const LoginForm = () => {
    const [ viewState, setViewState ] = useState('login');
    const [ confirmPasswordText, setConfirmPasswordText ] = useState('');
    const [ confirmPasswordUsername, setConfirmPasswordUsername ] = useState('');
    const [ newPasswordRequiredData, setNewPasswordRequiredData ] = useState(null);

    const login = useMemo(() => {
        return <Login 
            setViewState={setViewState} 
            setNewPasswordRequiredData={setNewPasswordRequiredData}
        />;
    }, []);

    const forgotPassword = useMemo(() => {
        return <ForgotPassword 
            setConfirmPasswordText={setConfirmPasswordText} 
            setConfirmPasswordUsername={setConfirmPasswordUsername} 
            setViewState={setViewState} 
        />;
    }, []);

    const confirmPassword = useMemo(() => {
        return <ConfirmPassword 
            confirmPasswordText={confirmPasswordText} 
            confirmPasswordUsername={confirmPasswordUsername} 
            setViewState={setViewState}
            setNewPasswordRequiredData={setNewPasswordRequiredData}
        />;
    }, [confirmPasswordText, confirmPasswordUsername, setViewState]);

    const newPasswordRequired = useMemo(() => {
        return <NewPasswordRequired 
            newPasswordRequiredData={newPasswordRequiredData}
        />;
    }, [newPasswordRequiredData]);

    const setOrg = useMemo(() => {
        return <SetOrg />;
    }, []);

    switch (viewState) {
        case 'confirm_password':
            return confirmPassword;
        case 'forgot_password':
        case 'reset_password':
            return forgotPassword;
        case 'new_password_required':
            return newPasswordRequired;
        case 'set_org':
            return setOrg;
        default:
            return login;
    }
};

export default LoginForm;