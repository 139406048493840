import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FormInputField from '../FormInputField';
import Button, { buttonType } from '../../components/Button';
import Href from '../Href';
import Loader from '../Loader';
import { useLabels } from '../../hooks';
import { UserContext } from '../../contexts';
import { env } from '../../config';
import styles from './LoginForm.module.scss';

const Login = ({setViewState = () => {}, setNewPasswordRequiredData = () => {}}) => {
    const [ showLoader, setShowLoader ] = useState(false);
    const [ error, setError ] = useState();
    const { getLabel, getLocalUrlByLanguage, getCognitoApiError } = useLabels();
    const { signIn, resendConfirmationAccountCode } = useContext(UserContext);
    const navigate = useNavigate();
    const isActive = useRef(false);
    const loginUsernameRef = useRef();
    const loginPasswordRef = useRef();

    useEffect(() => {
        isActive.current = true;

        return () => {
            isActive.current = false;
        };
    }, []);

    const onLogInButtonClickHandle = useCallback((event) => {
        event.preventDefault();
        let focusField = null;

        if (!loginUsernameRef.current.validation()) {
            focusField = focusField || loginUsernameRef.current;
        }

        if (!loginPasswordRef.current.validation()) {
            focusField = focusField || loginPasswordRef.current;
        }

        if (focusField) {
            focusField.focus();
            return;
        }

        setError(null);
        setShowLoader(true);

        const username = loginUsernameRef.current.getValue();
        const password = loginPasswordRef.current.getValue();
        signIn(username, password).catch(error => {
            if (isActive.current) {
                switch(error.code) {
                    case 'HandleNewPasswordRequired':
                        setNewPasswordRequiredData(error);
                        setViewState('new_password_required');
                        break;
                    case 'PasswordResetRequiredException':
                        setViewState('reset_password');
                        break;
                    case 'UserNotConfirmedException':
                        resendConfirmationAccountCode(username, password).then(() => {
                            if (isActive.current) {
                                setShowLoader(false);
                                navigate(getLocalUrlByLanguage(`/purchase`));
                            }
                        }).catch(error => {
                            if (isActive.current) {
                                setError(error);
                                setShowLoader(false);
                            }
                        });
                        return;
                    default:
                        setError(error);
                        break;
                }
                setShowLoader(false);
            }
        });
    }, [signIn, setNewPasswordRequiredData, setViewState, resendConfirmationAccountCode, navigate, getLocalUrlByLanguage]);

    const onSignUpButtonClickHandle = useCallback(() => {
        navigate(getLocalUrlByLanguage(`/purchase`));
    }, [getLocalUrlByLanguage, navigate]);

    const onSignUpWithOrgcodeButtonClickHandle = useCallback(() => {
        setViewState('set_org');
    }, [setViewState]);
    
    const onForgotPasswordButtonClickHandle = useCallback(() => {
        setViewState('forgot_password');
        setError(null);
    }, [setViewState]);

    const loginForm = useMemo(() => {
        return <>
            <div className={styles.login}>
                <div className={styles.content}>
                    <div>
                        <div className={styles.title}>{getLabel('login_form_title')}</div>
                        <form className={styles.fields} onSubmit={onLogInButtonClickHandle}>
                            <FormInputField label={getLabel(env.REACT_APP_ORG_ENABLE() ? 'login_form_username_org_field' : 'login_form_username_field')} placeholder={getLabel(env.REACT_APP_ORG_ENABLE() ? 'yourname@yourcompany.com' : 'login_form_username_field')} ref={loginUsernameRef} type="email" required={true} />
                            <FormInputField label={getLabel('login_form_password_field')} placeholder={getLabel('login_form_password_field')} ref={loginPasswordRef} type="password" required={true} />
                            <button hidden={true}></button>
                        </form>
                        <div className={styles.forgot_password}>
                            <Button type={buttonType.link} className={styles.forgot_password_button} onClick={onForgotPasswordButtonClickHandle} >{getLabel('login_form_forgot_password_button')}</Button>
                        </div>
                    </div>
                    <div className={styles.buttons}>
                        <div>
                            <div className={styles.error} dangerouslySetInnerHTML={{ __html: getCognitoApiError(error)}} />
                            <Button type={buttonType.premium} className={styles.main_button} onClick={onLogInButtonClickHandle} >{getLabel('login_form_login_button')}</Button>
                        </div>
                        {!env.REACT_APP_ORG_ENABLE() && <Button type={buttonType.link} className={styles.sign_up_button} onClick={onSignUpButtonClickHandle} >{getLabel('login_form_signup_button')}</Button>}
                        {env.REACT_APP_ORG_ENABLE() && <Button type={buttonType.link} className={styles.sign_up_button} onClick={onSignUpWithOrgcodeButtonClickHandle} >{getLabel('login_form_signup_with_orgcode_button')}</Button>}
                    </div>
                    <div className={styles.org}>
                        {!env.REACT_APP_ORG_ENABLE() && <>
                            <Href className={`${styles.org_button} ${styles.edu_button}`}>{getLabel('login_form_edu_button')}</Href>
                            <Href className={styles.org_button} to={getLabel('org_site')}>{getLabel('login_form_org_button')}</Href>
                        </>}
                        {env.REACT_APP_ORG_ENABLE() && <>
                            <Href className={styles.org_button} to={getLabel('main_site')}>{getLabel('login_form_main_site_button')}</Href>
                        </>}
                    </div>
                </div>
            </div>
            {showLoader && <Loader />}
        </>;
    }, [error, getCognitoApiError, getLabel, onForgotPasswordButtonClickHandle, onLogInButtonClickHandle, onSignUpButtonClickHandle, onSignUpWithOrgcodeButtonClickHandle, showLoader]);

    return loginForm;
};

export default Login;