import React, { useContext, useEffect } from 'react';
import OuterLayer from '../../containers/OuterLayer';
import { PageContext, OutersContainerContext } from '../../contexts';
import styles from './NotFound.module.scss';

export const pageKey = "not_found";

const NoFound = () => {
  const { setPageKey } = useContext(PageContext);
  const { appLayoutTitleContainer } = useContext(OutersContainerContext);

  useEffect(() => {
    setPageKey(pageKey);
  }, [setPageKey]);

  return <>
    <OuterLayer container={appLayoutTitleContainer} TagWrapper='span'>404</OuterLayer>
    <div className={styles.container}>
    </div>
  </>;
};

export default NoFound;
