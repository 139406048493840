import React, { useContext, useState, useEffect, useCallback, useMemo, useRef } from 'react';
import Href from '../Href';
import Icon from '../Icon';
import Alert from '../Alert';
import NavigationItem from '../NavigationItem'; 
import KeyboardToggle from '../KeyboardToggle';
import Sidebar from '../Sidebar';
import { useParams, useNavigate } from 'react-router-dom';
import { DeviceContext, LanguageContext, UserContext, UserType } from '../../contexts';
import { useLabels, useFilterNavigationItem } from '../../hooks';
import { alertType } from '../Alert/alertType';
import { header as headerConfig } from "../../config";
import styles from './NavigationBar.module.scss';

const NavigationBar = ({ className = '', ...props }) => {
    const [ isSidebarOpen, setIsSidebarOpen ] = useState(false);
    const { filterNavigationItem } = useFilterNavigationItem();
    const { isMobileView } = useContext(DeviceContext);
    const { language } = useContext(LanguageContext);
    const { userType } = useContext(UserContext);
    const routerParams = useParams();
    const { getLabel, getLocalUrlByLanguage } = useLabels();
    const navigate = useNavigate();
    const alertRef = useRef();

    useEffect(() => {
        if(!isMobileView) {
            setIsSidebarOpen(false);
        }
    }, [isMobileView]);

    const openSidebar = useCallback(() => {
        setIsSidebarOpen(true);
    }, []);

    const closeSidebar = useCallback(() => {
        setIsSidebarOpen(false);
    }, []);

    const languageSwitch = useCallback(() => {
        closeSidebar(false);
        const goTo = language === 'en' ? `/${routerParams["*"]}` : `/en/${routerParams["*"]}`;
        setTimeout(() => {
            navigate(goTo);
        }, isMobileView ? 500 : 0);
    }, [language, isMobileView, routerParams, navigate, closeSidebar]);

    const languageSwitcher = useMemo(() => {
        return <Href className={styles.tool} onClick={languageSwitch}>{language === 'en' ? 'HE' : 'EN'}</Href>;
    }, [language, languageSwitch]);
    
    const links = useMemo(() => {
        return filterNavigationItem(headerConfig[language] || []).map((link, index) => {
            return <NavigationItem 
                key={`footer_item-${index}`} 
                className={styles.link}
                onClick={closeSidebar}
                {...link}
            />;
        });
    }, [filterNavigationItem, language, closeSidebar]); 

    const homePageUrl = useMemo(() => {
        return getLocalUrlByLanguage('/');
    }, [getLocalUrlByLanguage]);

    const onSignoutHandle = useCallback(() => {
        if (alertRef.current) {
            alertRef.current.show({
                type: alertType.sign_out
            });
            closeSidebar();
        }
    }, [closeSidebar]);

    return <>
        {isMobileView && <header className={`${styles.header} ${className}`} {...props}>
            <div className={styles.header_section}>
                <button className={styles.sidebar_toggler} onClick={openSidebar}>
                    <Icon icon="hamburger" className={styles.sidebar_toggler_icon} />
                </button>
                <Sidebar isOpen={isSidebarOpen} staticRender={true} onClose={closeSidebar} side={language === 'en' ? 'left' : 'right'}>
                    <div className={styles.sidebar_content}>
                        <div className={styles.sidebar_header}>
                            <button className={styles.sidebar_close_button} onClick={closeSidebar}>
                                <Icon icon="close" className={styles.sidebar_close_icon} title={getLabel('sidebar_close_icon_title')} />
                            </button>
                        </div>
                        <div className={styles.links}>
                            {links}
                            {languageSwitcher}
                        </div>
                        {(userType === UserType.privet || userType === UserType.edu || userType === UserType.external) && <div className={styles.sidebar_footer}>
                            <button className={styles.sidebar_signout_button} onClick={onSignoutHandle}> 
                                <Icon className={styles.sidebar_signout_icon} icon="signout" />
                                <span>{getLabel('sidebar_signout_button')}</span>
                            </button>
                        </div>}
                    </div>
                </Sidebar>
            </div>
            <div className={styles.header_section}>
                    <Href to={homePageUrl}>
                        <Icon className={styles.logo} icon="logo_medium"/>
                    </Href>
                </div>
            <div className={styles.header_section}></div>
        </header>}
        {!isMobileView && <header className={`${styles.header} ${className}`} {...props}>
            <div className={styles.header_section}>
                <Href to={homePageUrl}>
                    <Icon className={styles.logo} icon={`logo_small_${language}`}/>
                </Href>
            </div>
            <div className={styles.header_section}><div className={styles.links}>{links}</div></div>
            <div className={styles.header_section}>
                <div className={styles.tools}>
                    <KeyboardToggle className={styles.tool} />
                    {languageSwitcher}
                </div>
            </div>
        </header>}
        <Alert ref={alertRef} />
    </>;
};

export default NavigationBar;