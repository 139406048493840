import React, { useCallback, useMemo, useContext, useRef, useState } from 'react';
import TouchController from '../../containers/TouchController';
import ResizeController from '../../containers/ResizeController';
import { DeviceContext } from '../../contexts';
import { useLabels } from '../../hooks';
import styles from './Tabs.module.scss';

const Tabs = ({tabs, onTabClick}) => {
    const { isMobile } = useContext(DeviceContext);
    const { getDataByDirection } = useLabels();
    const [ isScrolling, setIsScrolling ] = useState(false);
    const [ tabsPosition, setTabsPosition ] = useState(0);
    const scrollData = useRef();
    const wrapperData = useRef();

    const onClickHandle = useCallback(({target}) => {
        const index = Array.from(target.parentNode.childNodes).indexOf(target);

        if (isMobile) {
            target.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }
        
        if (onTabClick) {
            onTabClick(tabs && {...tabs[index], index});
        }
    }, [tabs, onTabClick, isMobile]);

    const calcTabsPosition = useCallback((tabsWrapper, tabsDom, tabsPosition) => {
        if (isMobile) {
            return 0;
        }

        const tabsWidth = tabsDom.getBoundingClientRect().width;
        const wrapperWidth = tabsWrapper.getBoundingClientRect().width;

        if (tabsPosition > 0 || wrapperWidth >= tabsWidth) {
            return 0;
        }
        else if (tabsWidth + tabsPosition < wrapperWidth) {
            return wrapperWidth - tabsWidth;
        }

        return tabsPosition;
    }, [isMobile])

    const onTouchStartHandler = useCallback(({event}) => {
        const tabsWrapper = event.target.closest(`.${styles.tabs_wrapper}`);

        scrollData.current = {
            tabsWrapper: tabsWrapper,
            tabsDom: tabsWrapper.querySelector(`.${styles.tabs}`),
            isScrolling: false,
            startScroll: tabsPosition,
        }
    }, [tabsPosition]);

    const onTouchMoveHandler = useCallback(({controllerData}) => {
        if (!scrollData.current) {
            return;
        }

        if (controllerData.distance > 5 && !scrollData.current.isScrolling) {
            scrollData.current.isScrolling = true;
            setIsScrolling(true);
        }

        let newPosition = scrollData.current.startScroll + getDataByDirection(-1, 1) * controllerData.deltaX;
        setTabsPosition(calcTabsPosition(scrollData.current.tabsWrapper, scrollData.current.tabsDom, newPosition));
    }, [getDataByDirection, calcTabsPosition]);

    const onTouchEndHandler = useCallback(() => {
        setIsScrolling(false);
        scrollData.current = null;
    }, []);

    const onResizeHandler = useCallback(() => {
        if(wrapperData.current) {
            const tabsDom = wrapperData.current.querySelector(`.${styles.tabs}`);
            setTabsPosition(calcTabsPosition(wrapperData.current, tabsDom, tabsPosition));
        }
    }, [tabsPosition, calcTabsPosition]);

    const tabsDom = useMemo(() => {
        return <div className={styles.tabs}>
            {tabs.map((tab) => {
                const key = `tab_${tab.key}`;
                const tabName = tab.name;
                return <button 
                    key={key} 
                    data-text={tabName} 
                    className={`${styles.tab} ${tab.isSelected ? styles.tab_selected : ''}`}
                    onClick={onClickHandle}
                ><span>{tabName}</span></button>;
            })}
        </div>;
    }, [tabs, onClickHandle]);

    return isMobile ? 
    <div className={styles.tabs_wrapper}>{tabsDom}</div> : 
    <TouchController
        style={{"--tabs-position": `${tabsPosition}px`}}
        className={styles.tabs_wrapper}
        data-is_scrolling={isScrolling}
        TagWrapper='div'
        onTouchStart={onTouchStartHandler}
        onTouchMove={onTouchMoveHandler}
        onTouchEnd={onTouchEndHandler}
        ref={wrapperData}
    >
        <ResizeController onResize={onResizeHandler}>
            {tabsDom}
        </ResizeController>
    </TouchController>;
};
  
export default Tabs;