import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { formatString } from '../../utils/helpers';
import { useLabels } from '../../hooks';
import styles from './WordExplain.module.scss';

export const formatWordExplain = (explain, linksPrefix = '/search', linksEnable = true, getLocalUrlByLanguage) => {
    let newExplain = explain || '';
    const formatParams = [];

    let match = newExplain.match(/{COLOR:([A-Z]+):{([A-Z]+)}:([^{}]+)}\^(\d+)\^/i);
    while(match) {
        newExplain = newExplain.split(match[0]).join(`{${formatParams.length}}`);
        let className = styles[match[2]];
        if (className === styles.UNDERLINE) {
            className = '';
        }

        let color = match[1];
        if (color === 'BLUE') {
            color = '';
            className += ` ${styles.BLUE}`
        }

        if (linksEnable) {
            const href = getLocalUrlByLanguage(`${linksPrefix}/${encodeURIComponent(match[3])}/${encodeURIComponent(match[3])}/${match[4]}`);
            formatParams.push(`<a href="${href}" class="${styles.link} ${className}" ${color ? `style="color: ${color};"` : ''}>${match[3]}</a>`);
        }
        else {
            formatParams.push(`<span class="${className}" ${color ? `style="color: ${color};"` : ''}>${match[3]}</span>`);
        }
        match = newExplain.match(/{COLOR:([A-Z]+):{([A-Z]+)}:([^{}]+)}\^(\d+)\^/i);
    }

    match = newExplain.match(/{COLOR:([A-Z]+):([^{}]+)}\^(\d+)\^/i);
    while(match) {
        newExplain = newExplain.split(match[0]).join(`{${formatParams.length}}`);
        let className = '';
        let color = match[1];
        if (color === 'BLUE') {
            color = '';
            className += ` ${styles.BLUE}`
        }

        if (linksEnable) {
            const href = getLocalUrlByLanguage(`${linksPrefix}/${encodeURIComponent(match[2])}/${encodeURIComponent(match[2])}/${match[3]}`);
            formatParams.push(`<a href="${href}" class="${styles.link} ${className}" ${color ? `style="color: ${color};"` : ''}>${match[2]}</a>`);
        }
        else {
            formatParams.push(`<span class="${className}" ${color ? `style="color: ${color};"` : ''}>${match[2]}</span>`);
        }
        match = newExplain.match(/{COLOR:([A-Z]+):([^{}]+)}\^(\d+)\^/i);
    }

    match = newExplain.match(/{([^{}]+)}\^(\d+)\^/i);
    while(match) {
        newExplain = newExplain.split(match[0]).join(`{${formatParams.length}}`);
        if (linksEnable) {
            const href = getLocalUrlByLanguage(`${linksPrefix}/${encodeURIComponent(match[2])}/${encodeURIComponent(match[2])}/${match[3]}`);
            formatParams.push(`<a href="${href}" class="${styles.link}" style="color: ${match[1]};">${match[2]}</a>`);
        }
        else {
            formatParams.push(`<span style="color: ${match[1]};">${match[2]}</span>`);
        }
        match = newExplain.match(/{([^{}]+)}\^(\d+)\^/i);
    }

    match = newExplain.match(/{COLOR:([A-Z]+):{([A-Z]+)}:([^{}]+)}/i);
    while(match) {
        newExplain = newExplain.split(match[0]).join(`{${formatParams.length}}`);
        let className = styles[match[2]];
        let color = match[1];
        if (color === 'BLUE') {
            color = '';
            className += ` ${styles.BLUE}`
        }

        formatParams.push(`<span class="${className}" ${color ? `style="color: ${color};"` : ''}>${match[3]}</span>`);
        match = newExplain.match(/{COLOR:([A-Z]+):{([A-Z]+)}:([^{}]+)}/i);
    }

    match = newExplain.match(/{COLOR:([A-Z]+):([^{}]+)}/i);
    while(match) {
        newExplain = newExplain.split(match[0]).join(`{${formatParams.length}}`);
        let className = '';
        let color = match[1];
        if (color === 'BLUE') {
            color = '';
            className += ` ${styles.BLUE}`
        }

        formatParams.push(`<span class="${className}" ${color ? `style="color: ${color};"` : ''}>${match[2]}</span>`);
        match = newExplain.match(/{COLOR:([A-Z]+):([^{}]+)}/i);
    }

    return formatString(newExplain, ...formatParams);
};

export default function WordExplain({ 
    children, 
    explain,
    linksPrefix = '/search', 
    linksEnable = true, 
    className, 
    onClick, 
    ...props 
}) {
    const [ explainHtml, setExplainHtml ] = useState('');
    const navigate = useNavigate();
    const { getLocalUrlByLanguage } = useLabels();

    useEffect(() => {
        setExplainHtml(formatWordExplain(explain, linksPrefix, linksEnable, getLocalUrlByLanguage));
    }, [explain, linksPrefix, linksEnable, getLocalUrlByLanguage]);

    const handleOnClick = useCallback((event) => {
        const targetLink = event.target.closest('a');

        if(targetLink) {
            event.preventDefault();
            navigate(targetLink.getAttribute('href'));
        };

        if(onClick){
            onClick(event);
        }
    }, [onClick, navigate]);
    
    return (
        <div className={`${styles.explain} ${className}`} onClick={handleOnClick} {...props} dangerouslySetInnerHTML={{__html: explainHtml}} />
    );
}
