import { Routes, Route } from 'react-router-dom';
import AppLayout from '../containers/AppLayout';
import { systemLanguages } from '../config';

function LanguageRouter() {
    return (
        <Routes>
            {systemLanguages.map(language => language !== "he" && <Route key={`language-router--${language}`} path={`/${language}/*`}  element={<AppLayout language={language} />} />)}
            <Route path="*" element={<AppLayout language="he" />} />
        </Routes>
    );
}
  
export default LanguageRouter;