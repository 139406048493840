import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ChangePasswordForm from '../../components/ChangePasswordForm';
import OuterLayer from '../../containers/OuterLayer';
import { useLabels } from '../../hooks';
import { PageContext, UserContext, UserType, OutersContainerContext } from '../../contexts';
import styles from './ChangePassword.module.scss';

const pageKey = "change_password";

const ChangePassword = () => {
  const { getLabel, getLocalUrlByLanguage } = useLabels();
  const { setPageKey } = useContext(PageContext);
  const { userType } = useContext(UserContext);
  const { appLayoutTitleContainer } = useContext(OutersContainerContext);
  const navigate = useNavigate();

  useEffect(() => {
    if(userType !== UserType.privet && userType !== UserType.external) {
      navigate(getLocalUrlByLanguage(`/user`));
    }
  }, [getLocalUrlByLanguage, navigate, userType]);

  useEffect(() => {
    setPageKey(pageKey);
  }, [setPageKey]);

  
  return <>
    <OuterLayer container={appLayoutTitleContainer} TagWrapper='span'>{getLabel("changepassword_page_title")}</OuterLayer>
    <div className={styles.container}>
      <ChangePasswordForm />
    </div>
  </>;
};

export default ChangePassword;
