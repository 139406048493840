import React, { useCallback, useContext, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import OuterLayer from '../../containers/OuterLayer';
import Icon from '../Icon';
import { OutersContainerContext } from '../../contexts';
import { useLabels } from '../../hooks';
import { alertComponent } from './alertType';
import styles from './Alert.module.scss';

const Alert = forwardRef(({className = '', onClose = () => {}, ...props}, ref) => {
    const [ content, setContent ] = useState();
    const [ show, setShow ] = useState(false);
    const [ open, setOpen ] = useState(false);
    const { outerLayersContainer } = useContext(OutersContainerContext);
    const { getLabel } = useLabels();

    useEffect(() => {
        let isActive = true;
        let timer = setTimeout(() => {
            if (isActive) {
                setOpen(show);
            }
        }, 300);

        return () => {
            isActive = false;
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [show]);

    const setCloseButtonRef = useCallback((ref) => {
        if(ref) {
            ref.focus();
        }
    }, []);

    const onCloseHandle = useCallback((event) => {
        setShow(false);
        onClose(event);
    }, [onClose]);

    useImperativeHandle(ref, () => {
        return {
            show: ({type, ...alertData}) => {
                const Tag = alertComponent[type] || alertComponent.message;
                setContent(<Tag {...alertData} onClose={onCloseHandle} />); 
                setShow(true);
            },
        };
    }, [onCloseHandle]);

    return (show || open) && content &&  <OuterLayer container={outerLayersContainer} className={`${styles.container} ${className} ${show && open ? styles.container_open: ''}`} {...props}>
        <div className={styles.mask} onClick={onCloseHandle}></div>
        <div className={styles.alert}>
            <div className={styles.tools}>
                <button className={styles.close} onClick={onCloseHandle} ref={setCloseButtonRef}><Icon className={styles.close_icon} icon="close" title={getLabel('popup_close_icon_title')} /></button>
            </div>
            <div className={styles.message}>{content}</div>
        </div>
    </OuterLayer>;
});
  
export default Alert;