import { useContext, Suspense, lazy } from "react";
import { Routes, Route } from 'react-router-dom';
import { LanguageContext, UserContext } from '../contexts';
import { pages } from '../config'

import Home from '../pages/Home';
import Purchase from '../pages/Purchase';
import NotFound from '../pages/NotFound';
import Dynamic from '../pages/Dynamic';
import User from '../pages/User';
import PersonalInformation  from '../pages/PersonalInformation';
import ChangePassword  from '../pages/ChangePassword';
import Search from '../pages/Search';
import Crossword from '../pages/Crossword';

/*
const NotFound = lazy(() => import('../pages/NotFound'));
const Search = lazy(() => import('../pages/Search'));
const Crossword = lazy(() => import('../pages/Crossword'));
*/

function AppRouter() {
    const { language } = useContext(LanguageContext);
    const { hasPermissions } = useContext(UserContext);

    return (
        <Suspense>
            <Routes>
                {
                    [
                        "about",
                        "numbers",
                        "help",
                        "contact_us",
                        "license",
                    ].map(pageKey => <Route key={`page_key--${pageKey}`} path={`/${pageKey}`} Component={() => <Dynamic pageKey={pageKey} {...pages[language][pageKey]} />} />)
                }
                <Route path="/crossword/:word?"  Component={Crossword} />
                <Route path="/search/:searchStr?/:wordEntry?/:wordId?"  Component={Search} />
                <Route path="/purchase"  Component={Purchase} />
                <Route path="/user"  Component={User} />
                <Route path="/personal_information"  Component={PersonalInformation} />
                <Route path="/change_password"  Component={ChangePassword} />
                <Route path="/login?"  Component={hasPermissions ? Search : Home} />
                <Route path="*" Component={NotFound} />
            </Routes>
        </Suspense>
    );
};

export default AppRouter;