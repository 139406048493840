import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FormInputField from '../FormInputField';
import Button, { buttonType } from '../../components/Button';
import Loader from '../Loader';
import { useLabels } from '../../hooks';
import { UserContext } from '../../contexts';
import styles from './LoginForm.module.scss';

const SetOrg = () => {
    const [ showLoader, setShowLoader ] = useState(false);
    const [ error, setError ] = useState();
    const { getLabel, getCognitoApiError, getLocalUrlByLanguage } = useLabels();
    const { setOrgData } = useContext(UserContext);
    const navigate = useNavigate();
    const isActive = useRef(false);
    const orgCodeRef = useRef();

    useEffect(() => {
        isActive.current = true;

        return () => {
            isActive.current = false;
        };
    }, []);

    const onGetOrgDataButtonClickHandle = useCallback((event) => {
        event.preventDefault();
        let focusField = null;

        if (!orgCodeRef.current.validation()) {
            focusField = focusField || orgCodeRef.current;
        }

        setError(null);
        if (focusField) {
            focusField.focus();
            return;
        }
        setShowLoader(true);

        const orgCode = orgCodeRef.current.getValue();
        setOrgData(orgCode).then((orgData) => {
            if (isActive.current) {
                if (!orgData) {
                    setError({
                        code: "CognitoOrgNotExistError"
                    });
                }
                else if (orgData.isActive) {
                    navigate(getLocalUrlByLanguage(`/purchase`));
                }
                setShowLoader(false);
            }
        });
    }, [getLocalUrlByLanguage, navigate, setOrgData]);

    const setOrgForm = useMemo(() => {
        return <>
            <div className={styles.login}>
                <div className={styles.content}>
                    <div>
                        <div className={styles.title}>{getLabel('login_form_orgcode_title')}</div>
                        <div className={styles.text}>{getLabel('login_form_orgcode_text')}</div>
                        <form className={styles.fields} onSubmit={onGetOrgDataButtonClickHandle}>
                            <FormInputField label={getLabel('login_form_orgcode_code_field')} placeholder={getLabel('login_form_orgcode_code_field')} ref={orgCodeRef} type="text" required={true} />
                            <button hidden={true}></button>
                        </form>
                    </div>
                    <div className={styles.buttons}>
                        <div>
                            <div className={styles.error} dangerouslySetInnerHTML={{ __html: getCognitoApiError(error)}} />
                            <Button type={buttonType.premium} className={styles.main_button} onClick={onGetOrgDataButtonClickHandle} >{getLabel('login_form_orgcode_get_org_data_button')}</Button>
                        </div>
                    </div>
                </div>
            </div>
            {showLoader && <Loader />}
        </>;
    }, [error, getCognitoApiError, getLabel, onGetOrgDataButtonClickHandle, showLoader]);


    return setOrgForm;
};

export default SetOrg;