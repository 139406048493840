import Message from './Message';
import Alert from './Alert';
import SignUp from './SignUp';
import SignOut from './SignOut';

export const alertType = {
    message: 'link',
    alert: 'alert',
    sign_up: 'sign_up',
    sign_out: 'sign_out',
};

export const alertComponent = {
    message: Message,
    alert: Alert,
    sign_up: SignUp,
    sign_out: SignOut,
};