import { createContext, useState, useEffect } from 'react';
import { getRootDom } from '../utils/helpers';
import { labels } from '../config'

export const LanguageContext = createContext();

export const LanguageProvider = (props) => {
    const [ language, setLanguage ] = useState('he');

    useEffect(() => {
        const langLabels = labels[language] || labels.he;
        document.querySelector('html').setAttribute('lang', langLabels.lang);
        getRootDom().dataset.direction = langLabels.direction;

        try {
            if (window.interdeal) {
                window.interdeal.Menulang = language.toUpperCase();
                if (window.interdeal.SetLocale) {
                    window.interdeal.SetLocale(window.interdeal.Menulang, true);
                }
            }
        } catch (ex) {}
    }, [language]);

    return <LanguageContext.Provider value={{language, setLanguage}}>
        {props.children}
    </LanguageContext.Provider>;
};