import { createPortal } from 'react-dom';
import { useEffect, useState } from 'react';
import styles from './OuterLayer.module.scss';

function OuterLayer({ TagWrapper = 'div', container, children, ...props }) {
    const [islayerReady, setIslayerReady] = useState(false);
    const [layer, setLayer] = useState();

    useEffect(() => {
        if (!container) {
            return () => {};
        }

        const layer = document.createElement('div');
        layer.classList.add(styles.layer);
        container.append(layer);
        setLayer(layer);
        setIslayerReady(true);

        return () => {
            layer.remove();
        };
    }, [container]);

    return islayerReady ? createPortal(<TagWrapper {...props}>{children}</TagWrapper>, layer) : <></>;
}

export default OuterLayer;