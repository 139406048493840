/* eslint-disable import/no-anonymous-default-export */
import { navigationItemType, showForDeviceType, showForUserType, showForSiteType } from '../../hooks';
import { alertType } from '../../components/Alert/alertType';

export default [
    {
        label: "חיפוש מילה",
        path: "/",
        show_for_users: [showForUserType.has_permissions],
        type: navigationItemType.link,
    },    
    {
        label: "חיפוש מילה",
        show_for_users: [showForUserType.has_not_permissions],
        type: navigationItemType.alert,
        alert_data: {
            type: alertType.sign_up,
        },
    },  
    {
        label: "נקדן טקסט",
        path: "https://nakdan.morfix.co.il/",
        type: navigationItemType.link,
    },    
    {
        label: "אזור אישי",
        path: "/user",
        show_for_users: [showForUserType.external, showForUserType.privet],
        type: navigationItemType.link,
    },   
    {
        label: "אזור אישי",
        show_for_users: [showForUserType.guest],
        show_for_sites: [showForSiteType.main],
        type: navigationItemType.alert,
        alert_data: {
            type: alertType.sign_up,
        },
    },   
    {
        label:  "תנאי שימוש",
        path: "/license",
        type: navigationItemType.link,
    }, 
    {
        label:  "כתבו לנו",
        path: "/contact_us",
        type: navigationItemType.link,
    }, 
    {
        label: "מורפיקס",
        path: "https://www.morfix.co.il/",
        type: navigationItemType.link,
    },  
    {
        label: "תשבצון",
        path: "/crossword",
        show_for_users: [showForUserType.has_permissions],
        type: navigationItemType.link,
    }, 
    {
        label: "תשבצון",
        show_for_users: [showForUserType.has_not_permissions],
        type: navigationItemType.alert,
        alert_data: {
            type: alertType.sign_up,
        },
    }, 
    {
        label:  "מספרים במילים",
        path: "/numbers",
        show_for_users: [showForUserType.has_permissions],
        type: navigationItemType.link,
    },   
    {
        label:  "מספרים במילים",
        show_for_users: [showForUserType.has_not_permissions],
        type: navigationItemType.alert,
        alert_data: {
            type: alertType.sign_up,
        },
    },   
    {
        label: "על המילון",
        path: "/about",
        type: navigationItemType.link,
    },   
    {
        label:  "דבר העורכים",
        path: "https://www.ravmilim.co.il/adv/general/index.htm",
        type: navigationItemType.link,
    },   
    {
        label:  "בלוג",
        path: "https://blog.ravmilim.co.il/",
        type: navigationItemType.link,
    },    
    {
        label:  "עזרה",
        path: "/help",
        type: navigationItemType.link,
    },    
];