import { createContext, useCallback, useEffect, useRef, useState } from 'react';

export const KeyboardContext = createContext();

export const KeyboardProvider = (props) => {
    const [show, setShow] = useState(false);
    const [input, setInput] = useState();
    const [inputCount, setInputCount] = useState(0);
    const inputCounter = useRef(0);

    useEffect(() => {
        if (inputCount <= 0) {
            setShow(false);
        }
    }, [inputCount]);

    const updateInputCounter = useCallback((add) => {
        inputCounter.current += add;
        setInputCount(inputCounter.current);
    }, []);

    return <KeyboardContext.Provider value={{input, setInput, inputCount, updateInputCounter, show, setShow}}>
        {props.children}
    </KeyboardContext.Provider>;
};