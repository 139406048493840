import React, { useEffect, useRef, useState } from 'react';
import styles from './Icon.module.scss';
import sprites from './symbols.svg';

let imageIndex = 0;

const Icon = ({ icon, title, className, width, height, stroke, strokeWidth, fill, color }) => {
  const [ titleId, setTitleId ] = useState();
  const iconClass = [styles.icon, className].join(' ');
  const ref = useRef();

  useEffect(() => {
    const titleId = `${styles.icon}_title_${imageIndex}`;
    setTitleId(titleId);
    imageIndex++;
  }, []);

  return (
    titleId && <>
      <svg
        ref={ref}
        className={iconClass}
        style={{ width, height, stroke, strokeWidth, fill, color }}
        {
          ...(title ? {
            role: "img",
            "aria-labelledby": titleId
          } : {})
        }
      >
        {title && <title id={titleId}>{title}</title>}
        <use xlinkHref={`${sprites}#${icon}`} />
      </svg>
    </>
  );
};

export default Icon;
