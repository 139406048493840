import React, { useContext, useEffect, useState } from 'react';
import Signup from './signup';
import SignupConfirm from './signupConfirm';
import { UserContext } from '../../contexts';
import { getRootDom } from '../../utils/helpers';

const SignupForm = () => {
    const { codeDeliveryDetails } = useContext(UserContext);
    const [ view, setView ] = useState('Signup');

    useEffect(() => {
        getRootDom().dataset.useRegularMobileHeader = true;

        return () => {
            getRootDom().dataset.useRegularMobileHeader = false;
        };
    }, []);

    useEffect(() => {
        if (codeDeliveryDetails) {
            setView('SignupConfirm')
        }
    }, [codeDeliveryDetails])

    if (view === 'SignupConfirm') {
        return <SignupConfirm />;
    }

    return <Signup />;
};

export default SignupForm;