import { useContext, useCallback, forwardRef } from 'react';
import { DeviceContext } from '../../contexts';

const TouchController = forwardRef(({ 
    TagWrapper = 'div', 
    clickDistanceTrigger = 5,
    onClick = () => {},
    onTouchStart = () => {},
    onTouchMove = () => {},
    onTouchEnd = () => {},
    children, 
    ...props 
}, ref) => {
    const { isMobile } = useContext(DeviceContext);
    
    const onTouchStartHandler = useCallback((event) => {
        const touchStartX = isMobile ? event.changedTouches[0].screenX : event.screenX;
        const touchStartY = isMobile ? event.changedTouches[0].screenY : event.screenY;

        onTouchStart({
            controllerData: {touchStartX, touchStartY},
            event
        });

        const getDistance = (deltaX, deltaY) => Math.sqrt(deltaX*deltaX + deltaY*deltaY);

        const onTouchMoveHandler = (event) => {
            const touchX = isMobile ? event.changedTouches[0].screenX : event.screenX;
            const touchY = isMobile ? event.changedTouches[0].screenY : event.screenY;
            const deltaX = touchX - touchStartX;
            const deltaY = touchY - touchStartY;
            const distance = getDistance(deltaX, deltaY);

            onTouchMove({
                controllerData: {touchStartX, touchStartY, touchX, touchY, deltaX, deltaY, distance},
                event
            });
        };
        
        const onTouchEndHandler = (event) => {
            const touchX = isMobile ? event.changedTouches[0].screenX : event.screenX;
            const touchY = isMobile ? event.changedTouches[0].screenY : event.screenY;
            const deltaX = touchX - touchStartX;
            const deltaY = touchY - touchStartY;
            const distance = getDistance(deltaX, deltaY);

            const eventData = {
                controllerData: {touchStartX, touchStartY, touchX, touchY, deltaX, deltaY, distance},
                event
            };
    
            if (isMobile) {
                document.removeEventListener('touchmove', onTouchMoveHandler, false);
                document.removeEventListener('touchend', onTouchEndHandler, true);
            }
            else {
                document.removeEventListener('mousemove', onTouchMoveHandler, false);
                document.removeEventListener('mouseup', onTouchEndHandler, true);
            }

            onTouchEnd(eventData);
            if (distance <= clickDistanceTrigger) {
                onClick(eventData);
            }
        };

        if (isMobile) {
            document.addEventListener('touchmove', onTouchMoveHandler, false);
            document.addEventListener('touchend', onTouchEndHandler, true);
        }
        else {
            document.addEventListener('mousemove', onTouchMoveHandler, false);
            document.addEventListener('mouseup', onTouchEndHandler, true);
        }
    }, [onClick, onTouchStart, onTouchMove, onTouchEnd, clickDistanceTrigger, isMobile]);
    
    

    return <TagWrapper 
        {...(isMobile ? { onTouchStart: onTouchStartHandler } : { onMouseDown: onTouchStartHandler })}
        {...props}
        ref={ref}
    >
        {children}
    </TagWrapper>;
});

export default TouchController;