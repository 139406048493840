import React, { useCallback, useContext, useMemo } from 'react';
import Href from '../../components/Href';
import Icon from '../../components/Icon';
import WordExplain from '../../components/WordExplain';
import { useLabels } from '../../hooks';
import { DeviceContext } from '../../contexts';
import { formatString, getLinkTarget } from '../../utils/helpers';
import { analyzeTextResultTypes } from '../../api';
import styles from './Search.module.scss';

const SearchResults = ({resultType, query, correctionList, reviseWord, words, error, ...props}) => {
  const { isMobileView } = useContext(DeviceContext);
    const { getLabel, getLocalUrlByLanguage } = useLabels();

    const get_correction_item = useCallback((item) => {
      return item && <Href 
        to={getLocalUrlByLanguage(`/search/${encodeURIComponent(item)}`)}
        key={`correction_item--${item}`}
      >{item}</Href>;
    }, [getLocalUrlByLanguage]);

    const contact_us_link = useMemo(() => {
      return getLocalUrlByLanguage('/contact_us');
    }, [getLocalUrlByLanguage]);

    const display_words = useMemo(() => {
      return (words || []).filter(word => word.explain || word.id || word.inputWordDiacritics || word.inputWordEntry || word.partOfSpeech || word.translation);
    }, [words]);

    const is_unmber_search = useMemo(() => {
      return !!(query && query.match(/(\d)+/i));
    }, [query]);

    const error_label = useMemo(() => {
      return error === "ExceedingDailySearchesLimit" ? getLabel('search_results_exceeding_daily_searches_limit') : error;
    }, [error, getLabel]);

    return <>
        {resultType === analyzeTextResultTypes.match && display_words.length > 0 && <>
          {isMobileView && <div className={styles.number_of_search_results}>{formatString(getLabel(display_words.length === 1 ? 'number_of_search_1_results' : 'number_of_search_results'), display_words.length)}</div>}
          <div className={styles.search_results_words}>
            {display_words.map((word, wordIndex) => {
              const Tag = word.id ? Href : 'div';
              const key = word.id ? `search_results_word--${word.id}` : `search_results_word--${wordIndex}`;
              const resultType = word.id ? 'word' : word.inputWordEntry ? 'en' : 'num';
              const className =  `${styles.search_results_word} ${styles[`search_results_word_${resultType}`]}`
              const to =  word.id ? getLocalUrlByLanguage(`/search/${encodeURIComponent(query)}/${encodeURIComponent(word.inputWordEntry)}/${word.id}`) : undefined;

              return  <Tag 
                className={className} 
                key={key}
                to={to}
              >
                <div className={styles.search_results_word_title}>
                  {
                    resultType === 'num' ? <>
                      <WordExplain explain={word.explain} linksEnable={false} />
                    </> : <>
                      <span>{word.inputWordEntry}</span> 
                      <span className={styles.search_results_word_partOfSpeech}>{word.partOfSpeech}</span>
                      {word.inputWordDiacritics && word.inputWordDiacritics !== word.inputWordEntry ? <span className={styles.search_results_word_diacritics}>{`(${word.inputWordDiacritics})`}</span> : ''}
                    </>
                  }
                </div>
                <div className={styles.search_results_word_explain}>
                  {resultType !== 'num' && <WordExplain explain={word.explain} linksEnable={false} />}
                </div>
                <div className={styles.search_results_word_translation}>{word.translation}</div>
              </Tag>;
            })}
          </div>
        </>}
        {!(resultType === analyzeTextResultTypes.match && display_words.length > 0) && <div className={styles.search_results_correction}>
            <div>
              <div className={styles.search_results_correction_icon}><Icon icon="correction" /></div>
              <div className={styles.search_results_correction_title}>{getLabel(error ? error_label : is_unmber_search ? 'search_results_number_correction_title' : 'search_results_correction_title')}</div>
              {((correctionList && correctionList.length > 0) || (reviseWord && reviseWord.length > 0)) && <>
                <div className={styles.search_results_correction_sub_title}>{getLabel('search_results_correction_sub_title')}</div>
                <div className={styles.search_results_correction_list}>
                  {correctionList && correctionList.map(item => {
                    return item.word && get_correction_item(item.word);
                  })}
                  {reviseWord && reviseWord.map(item => {
                    return item.value &&  get_correction_item(item.value);
                  })}
                </div>
              </>}
            </div>
            <div>
              {!error && !is_unmber_search && <><span className={styles.search_results_correction_contact_us}>{getLabel('search_results_correction_contact_us_1')} <Href target={getLinkTarget(contact_us_link)} to={contact_us_link}>{getLabel('search_results_correction_contact_us_2')}</Href></span></>}
            </div>
        </div>}
    </>;
};
  
export default SearchResults;