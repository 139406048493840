import React, { useCallback, useRef } from 'react';
import ItemLink from '../ItemLink';
import Alert from '../../Alert';

const ItemAlert = ({className, path, label, alert_data, onClick, ...props}) => {
    const alertRef = useRef();

    const onClickHandle = useCallback((event) => {
        if (alertRef.current) {
            alertRef.current.show(alert_data);
        }

        if (onClick) {
            onClick(event);
        }
    }, [alert_data, onClick]);

    return <>
        {
            path ?  
            <ItemLink className={className} path={path} label={label} onClick={onClickHandle} {...props} /> : 
            <button className={className} onClick={onClickHandle} data-text={label} {...props}><span>{label}</span></button>
        }
        <Alert ref={alertRef} />
    </>
};

export default ItemAlert;