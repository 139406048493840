import { useEffect, useContext } from "react";
import Preloader from '../../components/Preloader';
import DefaultLayout from '../../layouts/DefaultLayout';
import { LanguageContext, UserContext, UserType } from '../../contexts';
import AppRouter from '../../routes/AppRouter';

function AppLayout({language = 'he'}) {
    const { setLanguage } = useContext(LanguageContext);
    const { userType } = useContext(UserContext);

    useEffect(() => {
        setLanguage(language);
    }, [language, setLanguage]);

    if (!userType) {
        return <Preloader />;
    }

    if (userType === UserType.guest) {
        //return <>GUEST</>;
    }
  
    return (
        <DefaultLayout>
            <AppRouter />
        </DefaultLayout>
    );
};

export default AppLayout;