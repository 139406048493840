import { useCallback, useEffect, useRef } from 'react';
import styles from './ResizeController.module.scss';

function ResizeController({ 
    children, 
    className = '',
    onResize = () => {},
    ...props 
}) {
    const iframeRef = useRef();
    const divRef = useRef();
    const observerRef = useRef();

    useEffect(() => {
        if ('ResizeObserver' in window) {
            observerRef.current =  new window.ResizeObserver((entries) => {
                if(entries && entries.length > 0) {
                    onResize({
                        width: entries[0].contentRect.width, 
                        height: entries[0].contentRect.height
                    });
                }
            });

            if (divRef.current) {
                observerRef.current.observe(divRef.current);
            }

            return () => {
                if (observerRef.current && divRef.current) {
                    observerRef.current.unobserve(divRef.current);
                }
            };
        }
        else {
            const messageHandler = ({source, data}) => {
                if (iframeRef.current && iframeRef.current.contentWindow === source) {
                    if (data && data.type === "resize") {
                        onResize(data.size);
                    }
                }
            };
        
            window.addEventListener('message', messageHandler);
        
            return () => {
                window.removeEventListener('message', messageHandler);
            };
        }
    }, [onResize]);

    const initDivRef = useCallback((ref) => {
        if (observerRef.current && divRef.current) {
            observerRef.current.unobserve(divRef.current);
        }

        divRef.current = ref;
        if (observerRef.current && divRef.current) {
            observerRef.current.observe(divRef.current);
        }
    }, []);
    
    return <>
        {
            'ResizeObserver' in window ? 
            <div title='resize_controller' ref={initDivRef} className={`${styles.controller} ${className}`} {...props}></div> : 
            <iframe title='resize_controller' ref={iframeRef} className={`${styles.controller} ${className}`} {...props} srcDoc={`
                <style>
                    * {
                        pointer-events: none;
                        user-select: none;
                        opacity: 0;
                    }
                </style>
                <script>
                    const sendMessage = () => {
                        window.parent.postMessage(
                            {
                                type: 'resize',
                                size: {
                                    width: window.innerWidth, 
                                    height: window.innerHeight
                                }
                            },
                            '*'
                        );
                    };
                    setTimeout(sendMessage, 1);
                    window.addEventListener('resize', sendMessage);
                </script>
            `}></iframe>
        }
        {children}
    </>;
}

export default ResizeController;