import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import FormInputField from '../FormInputField';
import Button, { buttonType } from '../../components/Button';
import Loader from '../Loader';
import { useLabels } from '../../hooks';
import { UserContext } from '../../contexts';
import styles from './LoginForm.module.scss';

const ConfirmPassword = ({
    confirmPasswordText,
    confirmPasswordUsername,
    setViewState = () => {},
    setNewPasswordRequiredData = () => {},
}) => {
    const [ showLoader, setShowLoader ] = useState(false);
    const [ error, setError ] = useState();
    const { getLabel, getCognitoApiError } = useLabels();
    const { confirmPassword, signIn } = useContext(UserContext);
    const isActive = useRef(false);
    const confirmPasswordCodeRef = useRef();
    const confirmPasswordNewPasswordRef = useRef();
    const confirmPasswordAgainNewPasswordRef = useRef();

    useEffect(() => {
        isActive.current = true;

        return () => {
            isActive.current = false;
        };
    }, []);

    const onChangePasswordButtonClickHandle = useCallback((event) => {
        event.preventDefault();
        let focusField = null;

        if (!confirmPasswordCodeRef.current.validation()) {
            focusField = focusField || confirmPasswordCodeRef.current;
        }

        if (!confirmPasswordNewPasswordRef.current.validation()) {
            focusField = focusField || confirmPasswordNewPasswordRef.current;
        }

        if (!confirmPasswordAgainNewPasswordRef.current.validation()) {
            focusField = focusField || confirmPasswordAgainNewPasswordRef.current;
        }

        if (focusField) {
            focusField.focus();
            return;
        }

        setError(null);
        setShowLoader(true);

        const code = confirmPasswordCodeRef.current.getValue();
        const newPassword = confirmPasswordNewPasswordRef.current.getValue();
        confirmPassword(confirmPasswordUsername, code, newPassword).then(() => {
            if (isActive.current) {
                setShowLoader(false);
                signIn(confirmPasswordUsername, newPassword).catch(error => {
                    if (isActive.current) {
                        switch(error.code) {
                            case 'HandleNewPasswordRequired':
                                setNewPasswordRequiredData(error);
                                setViewState('new_password_required');
                                break;
                            case 'PasswordResetRequiredException':
                                setViewState('reset_password');
                                break;
                            default:
                                setError(error);
                                break;
                        }
                        setShowLoader(false);
                    }
                });
            }
        }).catch(error => {
            if (isActive.current) {
                setError(error);
                setShowLoader(false);
            }
        });
    }, [confirmPassword, confirmPasswordUsername, signIn, setNewPasswordRequiredData, setViewState]);

    const confirmPasswordAgainNewPasswordValidation = useCallback((password) => {
        return password === confirmPasswordNewPasswordRef.current.getValue() ? '' : getLabel('login_form_confirm_new_password_match_error');
    }, [getLabel]);

    const confirmPasswordForm = useMemo(() => {
        return <>
            <div className={styles.login}>
                <div className={styles.content}>
                    <div>
                        <div className={styles.title}>{getLabel('login_form_confirm_password_title')}</div>
                        <div className={styles.text} dangerouslySetInnerHTML={{ __html: confirmPasswordText}} />
                        <form className={styles.fields} onSubmit={onChangePasswordButtonClickHandle}>
                            <FormInputField label={getLabel('login_form_confirm_code_field')} placeholder={getLabel('login_form_confirm_code_field')} ref={confirmPasswordCodeRef} required={true} />
                            <FormInputField label={getLabel('login_form_confirm_new_password_field')} placeholder={getLabel('login_form_confirm_new_password_field_placeholder')} ref={confirmPasswordNewPasswordRef} passwordValidation={true} type="password" />
                            <FormInputField label={getLabel('login_form_confirm_again_new_password_field')} placeholder={getLabel('login_form_confirm_again_new_password_field_placeholder')} ref={confirmPasswordAgainNewPasswordRef} validationPattern={confirmPasswordAgainNewPasswordValidation} type="password" />
                            <button hidden={true}></button>
                        </form>
                    </div>
                    <div className={styles.buttons}>
                        <div>
                            <div className={styles.error} dangerouslySetInnerHTML={{ __html: getCognitoApiError(error)}} />
                            <Button type={buttonType.premium} className={styles.main_button} onClick={onChangePasswordButtonClickHandle} >{getLabel('login_form_confirm_change_password_button')}</Button>
                        </div>
                    </div>
                </div>
            </div>
            {showLoader && <Loader />}
        </>;
    }, [confirmPasswordAgainNewPasswordValidation, confirmPasswordText, error, getCognitoApiError, getLabel, onChangePasswordButtonClickHandle, showLoader]);


    return confirmPasswordForm;
};

export default ConfirmPassword;