import { createContext, useState, useEffect, useContext } from 'react';
import { LanguageContext } from './language';
import { UserContext } from './user';
import { getRootDom } from '../utils/helpers';
import { pages } from '../config'

export const PageContext = createContext();

export const PageProvider = (props) => {
    const [ pageData, setPageData ] = useState({});
    const [ pageKey, setPageKey ] = useState('');
    const { language } = useContext(LanguageContext);
    const { setOrgData, setCodeDeliveryDetails } = useContext(UserContext);

    useEffect(() => {
      if(pageKey !== 'purchase') {
        setOrgData(null);
        setCodeDeliveryDetails(null);
      }
    }, [pageKey, setCodeDeliveryDetails, setOrgData]);

    useEffect(() => {
        const lang = (language && pages[language]) ? language : "he";
        const newPageData = {
          ...pages[lang].default,
          ...(pages[lang][pageKey] || {})
        };
        getRootDom().setAttribute('data-page', pageKey);
        setPageData(newPageData);
    }, [language, pageKey]);

    useEffect(() => {
        if (pageData) {
          const canonical = window.location.toString();
    
          window.document.title = pageData.seo_title;
    
          let canonicalTag = window.document.head.querySelector('link[rel="canonical"]');
          if (canonicalTag) {
            canonicalTag.href = canonical;
          } else {
            canonicalTag = document.createElement('link');
            canonicalTag.rel = 'canonical';
            canonicalTag.href = canonical;
            document.head.append(canonicalTag);
          }
    
          [
            { selector: ['name', 'description'], content: pageData.seo_description },
            { selector: ['name', 'keywords'], content: pageData.seo_keywords },
            { selector: ['name', 'twitter:card'], content: 'summary' },
            { selector: ['name', 'twitter:title'], content: pageData.seo_title },
            { selector: ['name', 'twitter:description'], content: pageData.seo_description },
            { selector: ['name', 'twitter:image'], content: pageData.seo_image },
            { selector: ['property', 'og:title'], content: pageData.seo_title },
            { selector: ['property', 'og:description'], content: pageData.seo_description },
            { selector: ['property', 'og:image'], content: pageData.seo_image },
            { selector: ['property', 'og:url'], content: canonical },
          ].forEach((tagData) => {
            let metaTag = window.document.head.querySelector(`meta[${tagData.selector[0]}="${tagData.selector[1]}"]`);
            if (metaTag) {
              metaTag.content = tagData.content;
            } else {
              metaTag = document.createElement('meta');
              metaTag.setAttribute(tagData.selector[0], tagData.selector[1]);
              metaTag.content = tagData.content;
              document.head.append(metaTag);
            }
          });
        }
    }, [pageData]);

    return <PageContext.Provider value={{pageData, pageKey, setPageKey}}>
        {props.children}
    </PageContext.Provider>;
};