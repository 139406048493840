import React, { useMemo } from 'react';
import Href from '../../../components/Href';
import { useDataPerDevice } from '../../../hooks';
import styles from './ItemsGrid.module.scss';

const ItemsGrid = ({
  items, items_mobile, items_pc, 
  columns, columns_mobile, columns_pc, 
  item_templet, item_templet_mobile, item_templet_pc,
  style, style_mobile, style_pc,
}) => {
  const { getDataPerDeviceView } = useDataPerDevice();

  const itemsList = useMemo(() => {
    return getDataPerDeviceView(items_mobile, items_pc, items) || [];
  }, [getDataPerDeviceView, items_mobile, items_pc, items]);

  const gridTemplateColumns = useMemo(() => {
    const columnsNumber = getDataPerDeviceView(columns_mobile, columns_pc, columns) || 2;
    let columnsList = [];
    for (let i = 0; i < columnsNumber; i++) {
      columnsList.push('1fr');
    }
    return columnsList.join(' ');
  }, [getDataPerDeviceView, columns_mobile, columns_pc, columns]);

  const itemTemplet = useMemo(() => {
    return getDataPerDeviceView(item_templet_mobile, item_templet_pc, item_templet) || [{}];
  }, [getDataPerDeviceView, item_templet_mobile, item_templet_pc, item_templet]);

  const gridStyle = useMemo(() => {
    return getDataPerDeviceView(style_mobile, style_pc, style) || {};
  }, [getDataPerDeviceView, style_mobile, style_pc, style]);

  return <div className={styles.items} style={{...gridStyle, gridTemplateColumns}}>
    {itemsList.map((item, index) => {
      const rows = getDataPerDeviceView(item.rows_mobile, item.rows_pc, item.rows) || [""];
      const item_templet = getDataPerDeviceView(item.item_templet_mobile, item.item_templet_pc, item.item_templet) || itemTemplet;
      const link = getDataPerDeviceView(item.link_mobile, item.link_pc, item.link) || '';

      const Tag = link ? Href : 'div';

      return <Tag 
        className={styles.item} 
        key={`items_grid_item--${index}`}
        to={link}
        style={{gridTemplateRows: `span ${rows.length}`}}
      >
        {rows.map((row, row_index) => {
          const row_templet = item_templet.length > row_index ? item_templet[row_index] : {};

          return <div 
            key={`items_grid_item--${index}_row--${row_index}`} 
            className={styles.row}
            style={row_templet}
            dangerouslySetInnerHTML={{ __html: row}}
          />
        })}
      </Tag>;
    })}
  </div>;
};

export default ItemsGrid;
