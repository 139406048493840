import React, { useRef, useCallback, useState, useContext, useEffect } from 'react';
import Icon from '../Icon';
import TouchController from '../../containers/TouchController';
import ResizeController from '../../containers/ResizeController';
import { DeviceContext } from '../../contexts';
import { useLabels } from '../../hooks';
import styles from './Slider.module.scss';

const JumpTypes = {
    next: 'next',
    prev: 'prev',
    none: 'none',
}

const Slider = ({
    className = '', 
    items = [], 
    itemsInView = 3, 
    offset = 0,
    itemBuilder = item => item, 
    ...props
}) => {
    const [ showItems, setShowItems ] = useState([]);
    const [ itemsPosition, setItemsPosition ] = useState(0);
    const [ itemsWidth, setItemsWidth ] = useState(0);
    const [ itemsGap, setItemsGap ] = useState(0);
    const [ isScrolling, setIsScrolling ] = useState(false);
    const [ isAnimated, setIsAnimated ] = useState(false);
    const scrollerData = useRef({
        scroll: 0,
        scrollDelta: 0,
        scrollerWidth: 0,
        items: [],
        itemsInView: 3,
        offset: 0,
        rootFontSize: 16,
        isScrolling: false,
        movementTracking: [0, 0],
        isAnimated: false,
        animatedScroll: 0,
    });
    const { rootFontSize } = useContext(DeviceContext);
    const { getDataByDirection } = useLabels();

    const updateViewItems = useCallback(({jump = JumpTypes.none, jumpStep = 0}) => {
        const data = scrollerData.current;

        if (data.isAnimated) {
            return;
        }

        if (data.items.length === 0) {
            setItemsPosition(`0px`);
            setShowItems([]);
            setItemsWidth(`0px`);
            setItemsGap(`0px`);
            setIsScrolling(false);
            return;
        }

        const itemsGap = data.rootFontSize * 40 / 16;
        const gapCount = Math.ceil(data.itemsInView) - 1;
        const itemsWidth = (data.scrollerWidth - gapCount * itemsGap) / data.itemsInView;
        const fullWidth = data.items.length * (itemsWidth + itemsGap);

        const getFirstViewItemPosition = (scroll, firstViewItemIndex) => (scroll % (itemsWidth + itemsGap)) + 
                                                                        fullWidth + 
                                                                        (firstViewItemIndex * (itemsWidth + itemsGap)) + 
                                                                        (data.offset * (itemsWidth + itemsGap)) + 
                                                                        data.animatedScroll;

        const updateView = () => {
            let scroll = (data.scroll + data.scrollDelta) % fullWidth;
            if (scroll > 0) {
                scroll = scroll - fullWidth;
            }
            scroll = Math.abs(scroll);
    
            const firstViewItemIndex = Math.floor(scroll / (itemsWidth + itemsGap)) ;
            const firstViewItemPosition = getFirstViewItemPosition(scroll, firstViewItemIndex);
        
            setItemsPosition(`-${firstViewItemPosition}px`);
            setShowItems(data.showItems);
            setItemsWidth(`${itemsWidth}px`);
            setItemsGap(`${itemsGap}px`);
            setIsScrolling(data.isScrolling);
        };

        const jumper = () => {
            let extraScroll = 0;
            let scroll = (data.scroll + data.scrollDelta) % fullWidth;
            if (scroll > 0) {
                scroll = scroll - fullWidth;
            }
            scroll = Math.abs(scroll);

            switch (jump) {
                case JumpTypes.next:
                    if (jumpStep) {
                        extraScroll += (itemsWidth + itemsGap) * jumpStep;
                    }
                    else {
                        const firstItemPosition = (scroll % (itemsWidth + itemsGap));
                        if (firstItemPosition > 0) {
                            extraScroll += (itemsWidth + itemsGap) - firstItemPosition;
                        }
                    }
                    break;
                case JumpTypes.prev:
                    if (jumpStep) {
                        extraScroll -= (itemsWidth + itemsGap) * jumpStep;
                    }
                    else {
                        const lastItemPosition = (scroll + data.scrollerWidth) % (itemsWidth + itemsGap);
                        if (lastItemPosition < itemsWidth) {
                            extraScroll -= lastItemPosition + itemsGap;
                        }
                        if (lastItemPosition > itemsWidth) {
                            extraScroll -= lastItemPosition - itemsWidth;
                        }
                    }
                    break;
                default:
                    data.isAnimated = false;
                    break;
            }
            data.animatedScroll = extraScroll;

            if (data.isAnimated) {
                updateView();
                setIsAnimated(true);
                setTimeout(() => {
                    data.isAnimated = false;
                    data.scroll  -= extraScroll;
                    data.animatedScroll = 0;
                    setIsAnimated(false);
                    updateView();
                }, 501);
            }
        };


        updateView();

        if (jump !== JumpTypes.none) {
            data.isAnimated = true;
            setTimeout(jumper, 1);
        }
    }, []);

    useEffect(() => {
        scrollerData.current.scroll = 0;
        scrollerData.current.rootFontSize = rootFontSize;
        scrollerData.current.items = items;
        scrollerData.current.showItems = (items || []).map((item, index) => index);
        scrollerData.current.showItems = [
            ...scrollerData.current.showItems, 
            ...scrollerData.current.showItems, 
            ...scrollerData.current.showItems, 
            ...scrollerData.current.showItems
        ];
        scrollerData.current.itemsInView = itemsInView;
        scrollerData.current.offset = offset;
        updateViewItems({});
    }, [updateViewItems, rootFontSize, items, itemsInView, offset]);

    const onTouchMoveHandler = useCallback(({controllerData}) => {
        if (scrollerData.current.isAnimated) {
            return;
        }

        scrollerData.current.scrollDelta = getDataByDirection(-1, 1) * controllerData.deltaX;
        scrollerData.current.movementTracking[0] = scrollerData.current.movementTracking[1];
        scrollerData.current.movementTracking[1] = scrollerData.current.scrollDelta;
        if (controllerData.distance > 5) {
            scrollerData.current.isScrolling = true;
        }
        updateViewItems({});
    }, [getDataByDirection, updateViewItems]);

    const onTouchEndHandler = useCallback(({controllerData}) => {
        if (scrollerData.current.isAnimated) {
            return;
        }

        let jumpType = JumpTypes.none;
        scrollerData.current.scrollDelta = 0;
        if (scrollerData.current.isScrolling) {
            scrollerData.current.scroll += getDataByDirection(-1, 1) * controllerData.deltaX;
            jumpType = (scrollerData.current.movementTracking[1] - scrollerData.current.movementTracking[0]) < 0 ? JumpTypes.next : JumpTypes.prev;
        }
        scrollerData.current.movementTracking = [0, 0];
        scrollerData.current.isScrolling = false;
        updateViewItems({
            jump: jumpType,
            jumpStep: 0
        });
    }, [getDataByDirection, updateViewItems]);

    const onResizeHandler = useCallback(({width}) => {
        scrollerData.current.scroll = 0;
        scrollerData.current.scrollerWidth = width;
        updateViewItems({});
        
    }, [updateViewItems]);

    const goToNext = useCallback(() => {
        updateViewItems({
            jump: JumpTypes.next,
            jumpStep: Math.floor(scrollerData.current.itemsInView - Math.abs(scrollerData.current.offset))
        });
    }, [updateViewItems]);

    const goToPrev = useCallback(() => {
        updateViewItems({
            jump: JumpTypes.prev,
            jumpStep: Math.floor(scrollerData.current.itemsInView - Math.abs(scrollerData.current.offset))
        });
    }, [updateViewItems]);

    if (itemsInView >= items.length) {
        return <div 
            className={`${styles.slider} ${styles.slider_static} ${className}`} 
            {...props}
            style={{
                "--items-in-view": itemsInView
            }}
        >
            <div className={`${styles.container} ${styles.container_static}`}>
                <div className={styles.items}>
                    {items.map((item, index) => <div key={`slider-item--${index}`} className={styles.item}>
                        {itemBuilder(item)}
                    </div>)}
                </div>
            </div>
        </div>;
    }
    
    return <div 
        className={`${styles.slider} ${styles.slider_dynamic} ${className}`} 
        {...props}
        style={{
            "--items-gap": itemsGap,
            "--items-width": itemsWidth,
            "--items-position": itemsPosition,
            "--items-in-view": itemsInView
        }}
    >
        <button className={styles.controller} onClick={goToPrev}>
            <Icon className={styles.controller_icon} icon={getDataByDirection("arrow_right", "arrow_left")} />
        </button>
        <TouchController 
            className={`${styles.container} ${styles.container_dynamic}`}
            TagWrapper='div'
            onTouchMove={onTouchMoveHandler}
            onTouchEnd={onTouchEndHandler}
        >
            <ResizeController onResize={onResizeHandler}>
                <div 
                    data-is_scrolling={isScrolling} 
                    data-is_animated={isAnimated} 
                    className={styles.items}
                >
                    {showItems.map((itemIndex, index) => <div key={`slider-item--${index}`} className={styles.item}>
                        {itemBuilder(items[itemIndex])}
                    </div>)}
                </div>
            </ResizeController>
        </TouchController>
        <button className={styles.controller} onClick={goToNext}>
            <Icon className={styles.controller_icon} icon={getDataByDirection("arrow_left", "arrow_right")} />
        </button>
    </div>;
};
  
export default Slider;