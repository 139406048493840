import React, { forwardRef, useImperativeHandle, useRef, useCallback, useContext, useEffect } from 'react';
import { KeyboardContext } from '../../contexts';

const KeyboardInput = forwardRef(({
    className = '', 
    type='text', 
    onFocus = () => {}, 
    onKeyboardPress = () => {}, 
    ...props
}, ref) => {
    const { setInput, updateInputCounter } = useContext(KeyboardContext);
    const inputRef = useRef(null);

    useEffect(() => {
        updateInputCounter(1);

        return () => {
            updateInputCounter(-1);
        };
    }, [updateInputCounter]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.onKeyboardPress = onKeyboardPress;
        }
    }, [onKeyboardPress]);

    useImperativeHandle(ref, () => {
        return {
            getInput: () => {
                return inputRef.current || null;
            },
            getValue: () => {
                return inputRef.current ? inputRef.current.value : '';
            },
            setValue: (value) => {
                if (inputRef.current) {
                    inputRef.current.value = value;
                }
            },
            focus: () => {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            },
            scrollIntoView: (...props) => {
                if (inputRef.current) {
                    inputRef.current.scrollIntoView(...props);
                }
            },
        };
    }, []);

    const onFocusHandle = useCallback((event) => {
        event.target.onKeyboardPress = onKeyboardPress;
        setInput(event.target);
        onFocus(event);
    }, [setInput, onFocus, onKeyboardPress]);
  
    return <input onFocus={onFocusHandle} className={`keyboard_input ${className}`} type={type} ref={inputRef} {...props} />;
});
  
export default KeyboardInput;