import { fetch } from '../utils/helpers';
import { env } from '../config'

const API_URL = () => {return `${env.REACT_APP_BASS_RAVMILIM_API_URL()}/User`};

export const userApi =(() => {
    const GetUserData = (jwtToken) => {
        return fetch(`${API_URL()}/GetUserData`, {
            method: 'GET',
        }, jwtToken);
    };

    const GetOrgData = (orgCode) => {
        return fetch(`${API_URL()}/GetOrgData?orgCode=${orgCode}`, {
            method: 'GET',
        });
    };

    const UpdateUserData = (userData, jwtToken) => {
        return fetch(`${API_URL()}/UpdateUserData`, {
            method: 'POST',
            body: JSON.stringify(userData || {}),
        }, jwtToken);
    }

    return {
        GetUserData,
        GetOrgData,
        UpdateUserData,
    };
})();