import React, { useContext, useEffect, useMemo, useRef } from 'react';
import NavigationBar from '../../components/NavigationBar';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import Title from '../../components/Title';
import Landscape from '../../components/Landscape';
import Alert from '../../components/Alert';
import { alertType } from '../../components/Alert/alertType';
import { DeviceContext, OutersContainerContext, UserContext, UserType } from '../../contexts';
import styles from './DefaultLayout.module.scss'; 

function DefaultLayout({
  children, 
  className = '', 
}) {
  const { isMobileView, isMobile, isLandscape } = useContext(DeviceContext);
  const { hasPermissions, userType, orgData } = useContext(UserContext);
  const { 
    setAppLayoutTitleContainer,
    setAppLayoutSubTitleContainer,
    setAppLayoutHeaderSectionContainer,
    setAppLayoutStickySectionContainer
  } = useContext(OutersContainerContext);
  const signUpAlertRef = useRef();

  useEffect(() => {
    const showSignUpAlert = signUpAlertRef.current && (
      (
        orgData && !orgData.isActive
      ) ||
      (
        !hasPermissions && (
          userType === UserType.org || 
          userType === UserType.external || 
          userType === UserType.privet
        )
      )
    );

    if (showSignUpAlert) {
        signUpAlertRef.current.show({
            type: alertType.sign_up
        });
    }
}, [hasPermissions, userType, orgData]);

  const header = useMemo(() => {
    return <>
      <NavigationBar />
      <Title ref={setAppLayoutTitleContainer} TagWrapper='div' showPrefix={!isMobileView} className={`${styles.outer_section} ${styles.page_title}`}></Title>
      <div ref={setAppLayoutSubTitleContainer} className={`${styles.outer_section} ${styles.page_sub_title}`}></div>
      <div ref={setAppLayoutHeaderSectionContainer} className={styles.outer_section}></div>
    </>;
  }, [isMobileView, setAppLayoutTitleContainer, setAppLayoutSubTitleContainer, setAppLayoutHeaderSectionContainer]);


  return (
    <>
      <div className={`${styles.layout} ${className}`}>
        {!isMobileView && <>
          <div className={styles.sticky_content}>
            <NavigationBar />
            <div ref={setAppLayoutStickySectionContainer} className={`${styles.outer_section} ${styles.outer_sticky_section}`}></div>
            <div className={styles.sticky_section_separator} id="sticky_section_separator"></div>
          </div>
          <Title ref={setAppLayoutTitleContainer} TagWrapper='div' showPrefix={true} className={`${styles.outer_section} ${styles.page_title}`}></Title>
          <div ref={setAppLayoutSubTitleContainer} className={`${styles.outer_section} ${styles.page_sub_title}`}></div>
          <div ref={setAppLayoutHeaderSectionContainer} className={styles.outer_section}></div>
        </>}
        {isMobileView && <>
          <div className={styles.sticky_content}>
            <PageHeader>{header}</PageHeader>
            <div ref={setAppLayoutStickySectionContainer} className={`${styles.outer_section} ${styles.outer_sticky_section}`}></div>
            <div className={styles.sticky_section_separator} id="sticky_section_separator"></div>
          </div>
        </>}
        <div className={styles.page_content} id="page_content">
          {children}
        </div>
      </div>
      <Footer id="footer"/>
      <Alert ref={signUpAlertRef} />
      {isMobile && isLandscape && <Landscape />}
    </>
  );
}

export default DefaultLayout;
