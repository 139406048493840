import React from 'react';
import Href from '../../Href';
import { getLinkTarget } from '../../../utils/helpers';

const ItemLink = ({className, path, label, onClick, ...props}) => {
    return <Href 
        className={className} 
        to={path} 
        data-text={label} 
        onClick={onClick} 
        target={getLinkTarget(path)}
        {...props} 
    >
        <span>{label}</span>
    </Href>;
};

export default ItemLink;