/* eslint-disable import/no-anonymous-default-export */
import { navigationItemType, showForDeviceType, showForUserType } from '../../hooks';
import { alertType } from '../../components/Alert/alertType';

export default [
    {
        label: "Word Search",
        path: "/en/",
        show_for_users: [showForUserType.has_permissions],
        type: navigationItemType.link,
        pages: ["search", "search_results"],
    },     
    {
        label: "Word Search",
        show_for_users: [showForUserType.has_not_permissions],
        type: navigationItemType.alert,
        alert_data: {
            type: alertType.sign_up,
        },
    },  
    {
        label: "Crosswords Puzzle",
        path: "/en/crossword",
        show_for_users: [showForUserType.has_permissions],
        type: navigationItemType.link,
        pages: ["crossword"],
    },   
    {
        label: "Crosswords Puzzle",
        show_for_users: [showForUserType.has_not_permissions],
        type: navigationItemType.alert,
        alert_data: {
            type: alertType.sign_up,
        },
    },  
    {
        label: "Nakdan Morfix",
        path: "https://nakdan.morfix.co.il/",
        type: navigationItemType.link,
    },    
    {
        label: "About",
        path: "/en/about",
        type: navigationItemType.link,
        pages: ["about"],
    },    
    {
        label: "Personal Zone",
        show_for_users: [showForUserType.privet, showForUserType.edu, showForUserType.external],
        show_for_devises: [showForDeviceType.mobile],
        type: navigationItemType.link,
        path: "/en/user",
        pages: ["user", "personal_information", "change_password"],
    },    
    {
        label: "Personal Zone",
        show_for_users: [showForUserType.privet, showForUserType.edu, showForUserType.external],
        show_for_devises: [showForDeviceType.pc],
        path: "/en/user",
        pages: ["user", "personal_information", "change_password"],
        type: navigationItemType.dropdown,
        items: [
            {
                label: "Personal Details",
                type: navigationItemType.link,
                show_for_users: [showForUserType.privet, showForUserType.external],
                path: "/en/personal_information",
                pages: ["personal_information"],
            },
            {
                label: "Change Password",
                type: navigationItemType.link,
                show_for_users: [showForUserType.privet, showForUserType.external],
                path: "/en/change_password",
                pages: ["change_password"],
            },
            {
                label: "Contact us",
                type: navigationItemType.link,
                path: "/en/contact_us",
                pages: ["contact_us"],
            },
            {
                label: "Sign out",
                type: navigationItemType.alert,
                alert_data: {
                    type: alertType.sign_out,
                },
            }
        ]
    },       
    {
        label: "Contact Us",
        show_for_users: [showForUserType.guest, showForUserType.org],
        type: navigationItemType.link,
        path: "/en/contact_us",
        pages: ["contact_us"],
    },  
];