import React from 'react';
import NavigationItem from '../';
import ItemLink from '../ItemLink';
import { useFilterNavigationItem } from '../../../hooks';
import styles from './ItemDropdown.module.scss';

const ItemDropdown = ({className, label, path, items = [], ...props}) => {
    const { filterNavigationItem } = useFilterNavigationItem();

    return <>
        <div className={`${styles.contaner} ${className}`}>
            {
                path ? 
                <ItemLink className={className} path={path} label={label} {...props} /> : 
                <div className={className} data-text={label} {...props}><span>{label}</span></div>
            }
            <div className={styles.dropdown}>
                <div className={styles.items}>
                    {filterNavigationItem(items).map((item, index) => {
                        return <NavigationItem 
                            key={`dropdown_item-${index}`} 
                            className={styles.item}
                            {...item}
                        />;
                    })}
                </div>
            </div>
        </div>
    </>
};

export default ItemDropdown;