import React, { useMemo } from 'react';
import { useDataPerDevice } from '../../../hooks';
import styles from './Iframe.module.scss';

const Iframe = ({
  src, src_mobile, src_pc, 
  title, title_mobile, title_pc, 
  width, width_mobile, width_pc, 
  height, height_mobile, height_pc, 
}) => {
  const { getDataPerDeviceView } = useDataPerDevice();

  const iframeSrc = useMemo(() => {
    return getDataPerDeviceView(src_mobile, src_pc, src) || '';
  }, [getDataPerDeviceView, src_mobile, src_pc, src]);

  const iframeTitle = useMemo(() => {
    return getDataPerDeviceView(title_mobile, title_pc, title) || '';
  }, [getDataPerDeviceView, title_mobile, title_pc, title]);

  const iframeWidth = useMemo(() => {
    return getDataPerDeviceView(width_mobile, width_pc, width) || '';
  }, [getDataPerDeviceView, width_mobile, width_pc, width]);

  const iframeHeight = useMemo(() => {
    return getDataPerDeviceView(height_mobile, height_pc, height) || '';
  }, [getDataPerDeviceView, height_mobile, height_pc, height]);

  return iframeSrc && <iframe 
    src={iframeSrc} 
    title={iframeTitle} 
    style={{width: iframeWidth, height: iframeHeight}}
    className={styles.iframe}
  ></iframe>;
};

export default Iframe;
