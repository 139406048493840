import React, { useCallback, useEffect, useRef, useState, useContext } from 'react';
import FormInputField from '../FormInputField';
import Button, { buttonType } from '../../components/Button';
import Loader from '../Loader';
import { useLabels } from '../../hooks';
import { UserContext } from '../../contexts';
import { formatString, getTextWithoutHtml } from '../../utils/helpers';
import styles from './SignupForm.module.scss';

const SignupConfirm = () => {
    const [ showLoader, setShowLoader ] = useState(false);
    const [ error, setError ] = useState();
    const { getLabel, getCognitoApiError} = useLabels();
    const { codeDeliveryDetails, confirmAccount, resendConfirmationAccountCode, signIn } = useContext(UserContext);
    const isActive = useRef(false);
    const confirmAccountCodeRef = useRef();

    useEffect(() => {
        isActive.current = true;

        return () => {
            isActive.current = false;
        };
    }, []);

    const getConfirmText = useCallback(() => {
        if (codeDeliveryDetails) {
            return formatString(
                getLabel(
                    codeDeliveryDetails.DeliveryMedium === 'EMAIL' ? 
                    'signup_form_confirm_account_by_email' : 
                    'signup_form_confirm_account_by_phone'
                ), 
                `<span class="${styles.ltr_data}">${getTextWithoutHtml(codeDeliveryDetails.Destination)}</span>`
            );
        }
        return '';
    }, [codeDeliveryDetails, getLabel]);

    const onConfirmAccountButtonClickHandle = useCallback((event) => {
        event?.preventDefault();
        let focusField = null;

        if (!confirmAccountCodeRef.current.validation()) {
            focusField = focusField || confirmAccountCodeRef.current;
        }

        if (focusField) {
            focusField.focus();
            return;
        }

        setError(null);
        setShowLoader(true);

        const code = confirmAccountCodeRef.current.getValue();
        
        confirmAccount(code).then(() => {
            if (isActive.current) {
                setShowLoader(false);
                signIn(codeDeliveryDetails.username, codeDeliveryDetails.password).catch(error => {
                    if (isActive.current) {
                        setError(error);
                        setShowLoader(false);
                    }
                });
            }
        }).catch(error => {
            if (isActive.current) {
                setError(error);
                setShowLoader(false);
            }
        });
    }, [codeDeliveryDetails, confirmAccount, signIn]);

    const onResendConfirmationAccountCodeButtonClickHandle = useCallback((event) => {
        event?.preventDefault();

        setShowLoader(true);

        resendConfirmationAccountCode(codeDeliveryDetails.username, codeDeliveryDetails.password).then(() => {
            if (isActive.current) {
                setShowLoader(false);
            }
        }).catch(error => {
            if (isActive.current) {
                setError(error);
                setShowLoader(false);
            }
        });
    }, [codeDeliveryDetails, resendConfirmationAccountCode]);

    return <>
        <div className={`${styles.signup} ${styles.signup_confirm}`}>
            <div>
                <form className={styles.form} onSubmit={onConfirmAccountButtonClickHandle}>
                    <div className={styles.confirm_text} dangerouslySetInnerHTML={{ __html: getConfirmText()}} />
                    <FormInputField ref={confirmAccountCodeRef} label={getLabel('signup_form_confirm_account_code_field')} placeholder={getLabel('signup_form_confirm_account_code_field')} required={true} />
                    <button hidden={true}></button>
                </form>
                <div className={styles.resend_code}>
                    <Button type={buttonType.link} className={styles.resend_code_button} onClick={onResendConfirmationAccountCodeButtonClickHandle} >{getLabel('signup_form_confirm_account_resend_code_button')}</Button>
                </div>
            </div>
            <div className={styles.buttons}>
                <div className={styles.error} dangerouslySetInnerHTML={{ __html: getCognitoApiError(error)}} />
                <Button type={buttonType.premium} className={styles.button} onClick={onConfirmAccountButtonClickHandle} >{getLabel('signup_form_confirm_account_confirm_button')}</Button>
            </div>
        </div>
        {showLoader && <Loader />}
    </>;
};

export default SignupConfirm;