import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button, { buttonType } from '../../components/Button';
import Hubspot from '../Dynamic/Hubspot';
import OuterLayer from '../../containers/OuterLayer';
import { useLabels } from '../../hooks';
import { DeviceContext, OutersContainerContext, LanguageContext } from '../../contexts';
import { getRootDom, formatString } from '../../utils/helpers';
import styles from './Purchase.module.scss';

const formData = {
    he: {
        region: "eu1",
        portalId: "26939907",
        formId: "b348eccd-c8bf-4181-a504-0aff429c704b"
    },
    en: {
        region: "eu1",
        portalId: "26939907",
        formId: "23e70e0a-61b7-4bfd-968a-4fd310302fc2"
    }
};

const OrgForm = () => {
    const [ submittedData, setSubmittedData ] = useState();
    const { getLabel, getLocalUrlByLanguage } = useLabels();
    const { language } = useContext(LanguageContext);
    const { isMobileView } = useContext(DeviceContext);
    const { appLayoutTitleContainer } = useContext(OutersContainerContext);
    const navigate = useNavigate();

    useEffect(() => {
        console.log('language', language);
    }, [language]);

    useEffect(() => {
        getRootDom().dataset.useRegularMobileHeader = true;

        return () => {
            getRootDom().dataset.useRegularMobileHeader = false;
        };
    }, []);

    const onFormSubmittedHandle = useCallback((form, data) => {
        setSubmittedData(data);
    }, []);

    const onHomeButtonClickHandle = useCallback(() => {
        navigate(getLocalUrlByLanguage(`/`));
    }, [getLocalUrlByLanguage, navigate]);

    const hubspotFormHe = useMemo(() => {
        return <Hubspot 
            data={formData.he} 
            onFormSubmitted={onFormSubmittedHandle}
            className={styles.hubspo_form}
        />
    }, [onFormSubmittedHandle]);

    const hubspotFormEn = useMemo(() => {
        return <Hubspot 
            data={formData.en} 
            onFormSubmitted={onFormSubmittedHandle}
            className={styles.hubspo_form}
        />
    }, [onFormSubmittedHandle]);

    return <>
        {
            !submittedData && 
            <>
                <OuterLayer container={appLayoutTitleContainer} TagWrapper='span'>{getLabel('purchase_page_subscription_org_title')}</OuterLayer>
                <div className={`${styles.container} ${styles.container_corporate}`}>
                    {language === 'he' && hubspotFormHe}
                    {language === 'en' && hubspotFormEn}
                </div>
            </>
        }
        {
            submittedData && 
            <>
                <OuterLayer container={appLayoutTitleContainer} TagWrapper='span' dangerouslySetInnerHTML={{ __html: getLabel("purchase_page_subscription_org_submitted_title")}} />
                <div className={`${styles.container} ${styles.container_corporate}`}>
                    <div>
                        <div className={styles.subscription_org_submitted_title} dangerouslySetInnerHTML={{ __html: formatString(getLabel("purchase_page_subscription_org_submitted_sub_title"), (submittedData.submissionValues && submittedData.submissionValues.firstname) || "")}}/>
                        <div className={styles.subscription_org_submitted_text} dangerouslySetInnerHTML={{ __html: getLabel("purchase_page_subscription_org_submitted_text")}}/>
                    </div>
                    {isMobileView && <div>
                        <Button type={buttonType.premium} onClick={onHomeButtonClickHandle} >{getLabel('purchase_page_subscription_org_submitted_home_button')}</Button>
                    </div>}
                </div>
            </>
        }
    </>;
};

export default OrgForm;
