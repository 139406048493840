import React, {useMemo, useContext} from 'react';
import OuterLayer from '../../containers/OuterLayer'
import { OutersContainerContext } from '../../contexts';
import styles from './Loader.module.scss';

const Loader = ({
    className = '',
    fullScreen = true,
    useMask = true,
    ...props
}) => {
    const { outerLayersContainer } = useContext(OutersContainerContext);

    const loaderClassName = useMemo(() => {
        return `${styles.loader} ${className} ${useMask ? styles.use_mask : ''}`;
    }, [className, useMask]);

    return fullScreen ? 
        <OuterLayer container={outerLayersContainer} TagWrapper='div' className={loaderClassName} {...props} /> : 
        <div className={loaderClassName} {...props}></div>;
};
  
export default Loader;